import styled from "styled-components";
import UseBreakpoints from "../../../../../components/hooks/ui/UseBreakpoints";
const FTAOriginDeterTable = () => {
  const breakpoints = UseBreakpoints();
  return (
    <Table breakpoints={breakpoints}>
      <colgroup>
        <col />
        <col />
        <col />
        <col />
      </colgroup>
      <tbody>
        <tr>
          <td rowSpan={4}>일반적 기준</td>
          <td colSpan={5}>완전생산기준</td>
        </tr>
        <tr>
          <td rowSpan={3}>실질적변형기준</td>
          <td>세번 변경기준</td>
          <td>2단위(CC), 4단위(CTH), 6단위(CTSH)</td>
        </tr>
        <tr>
          <td>부가가치 기준</td>
          <td>부가가치율(RVC), 비원산지재료가치비율(MC)</td>
        </tr>
        <tr>
          <td>특정공정 기준</td>
          <td>재단, 봉제 , 날염, 염색 등</td>
        </tr>
        <tr>
          <td>보충적 기준</td>
          <td colSpan={3}>
            미소기준, 누적기준, 중간재, 간접재료, 재료가격, 공구, 부속품, 용기,
            포장 대체가능 물품, 불인정공정, 직접운송원칙, 역외가공
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
export default FTAOriginDeterTable;
const Table = styled.table`
  border-collapse: collapse;
  & > tbody {
    font-size: .9em;
    & * {
      padding: 1em;
      text-align: center;
    }
    & > tr {
      &:first-child {
        & > td {
          &:first-child {
            border-radius: 1rem 0 0 0;
            background-color: ${({ theme }) => theme.colors?.dark};
          }
          &:nth-child(2) {
            background-color: ${({ theme }) => theme.colors?.monotone?.extralight};
            border-radius: 0 1rem 0 0;
          }
        }
      }
      &:nth-child(3) {
        & > td {
          background-color: ${({ theme }) => theme.colors?.monotone?.extralight};
        }
      }
      &:last-child {
        & > td {
          &:first-child {
            border-radius: 0 0 0 1rem;
            background-color: ${({ theme }) => theme.colors?.medium};
          }
          &:nth-child(2) {
            background-color: ${({ theme }) => theme.colors?.monotone?.extralight};
            border-radius: 0 0 1rem 0;
          }
        }
      }
    }
    & > tr:first-child > td:first-child,
    & > tr:last-child > td:first-child {
      font-size: ${(props) =>
        props.breakpoints.xl
          ? "1.5em"
          : props.breakpoints.lg
          ? "1.2em"
          : "1em"};
      color: ${({ theme }) => theme.colors?.foreground?.inverted};
    }

    & > tr:first-child > td:last-child {
      padding: 1em;
    }
  }
`;
