import styled from "styled-components";
import BranchSimpleMapBus from "./BranchSimpleMapBus";
import BranchSimpleMapCar from "./BranchSimpleMapCar";
import BranchSimpleMapSubway from "./BranchSimpleMapSubway";
const BranchSimpleMap = ({ branch }) => {
  return (
    <Div>
      <BranchSimpleMapCar
        keyword={branch.addressKey}
        taxi={branch.direction?.taxi}
        parking={branch.direction?.parking}
      />
      <BranchSimpleMapSubway subway={branch.direction?.subway} />
      <BranchSimpleMapBus bus={branch.direction?.bus} />
    </Div>
  );
};
export default BranchSimpleMap;
const Div = styled.div`
  flex: 1;
`;
