import TitleTemplate from "../../components/ui/common/template/TitleTemplate";
import PageTemplate from "../../components/ui/common/template/PageTemplate";
import DevImage from "../../resources/images/dev.jpg";
import WorkScopes from "../../components/ui/specialty/WorkScopes";
import ContactPoints from "../../components/ui/members/ContactPoints";

const NewTechPage = () => {
  return (
    <PageTemplate large image={DevImage} title="신기술 도입">
      <p>
        에이원은 독자 IT팀 설립과 과감한 투자를 통해 기존 legacy 환경을 개선하여
        변화하는 웹, 모바일 환경에 대응하고 있습니다.
        <br /> 또한 AI, OCR, RPA등 새로운 기술 도입에 대한 끊임 없는 시도를
        바탕으로 관세 업계의 IT기술의 선도를 위해 노력하며, <br /> 이런 저희의
        노력은 고객과 직원의 업무 편의와 정확성, compliance를 크게 개선시켜 나갈
        것입니다.
      </p>

      <TitleTemplate
        title="업무범위"
        noImage
        subHeader="고객과 직원 needs에 부합하는 프로그램을 만들기 위해 노력합니다."
      />
      <WorkScopes
        contents={[
          { title: "OCR", icon: null },
          { title: "RPA", icon: null },
          { title: "Machine Learning", icon: null },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["cupark"]} />

    </PageTemplate>
  );
};
export default NewTechPage;
