import { Link } from "react-router-dom";
import styled from "styled-components";
import Hamburger from "../../skeleton/Hamburger";
import { hamburgerStore } from "../../../state/ui/hamburger_state";
import UseBreakpoints from "../../hooks/ui/UseBreakpoints";
const HeaderPart = () => {
  const hamburgerState = hamburgerStore();
  const breakpoints = UseBreakpoints();
  return (
    <>
      {
        !breakpoints.xl &&
        (<><HamburgerOpener>
          {hamburgerState.isOpened && (
            <Link
              to="#"
              onClick={() => {
                hamburgerState.close();
              }}
            >
              <span>×</span>
            </Link>
          )}
          {!hamburgerState.isOpened && (
            <Link
              to="#"
              onClick={() => {
                hamburgerState.open();
              }}
            >
              <span>≡</span>
            </Link>
          )}
        </HamburgerOpener>
          {hamburgerState.isOpened && (
            <Hamburger />
          )}</>
        )
      }
    </>
  );
};

export default HeaderPart;
const HamburgerOpener = styled.div`
  position: absolute;
  z-index: 5;
  right: 20px;
  top: 1.1rem;
  font-size: 40px;
`;
