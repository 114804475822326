import BranchDetails from "./branches/BranchDetails";
import BranchElement from "./branches/BranchElement";
import { branchcontext } from "../../../../context/intro/branch/branchcontext";
import UseBreakpoints from "../../../hooks/ui/UseBreakpoints";
import GlobalBranches from "../../../contents/intro/branch/GlobalBranches";
import styled from "styled-components";
const Branches = ({ selectedBranch, setSelectedBranch }) => {
  const breakpoints = UseBreakpoints();
  return (
    <Div breakpoints={breakpoints} name="branchSection">
      <BranchList breakpoints={breakpoints}>
        <div className="title">지점선택</div>
        <hr />
        <div className="list">
          {branchcontext?.map((x) => (
            <BranchElement
              key={x.location}
              location={x.location}
              setLocation={setSelectedBranch}
              selectedBranch={selectedBranch}
            />
          ))}
        </div>
      </BranchList>
      {selectedBranch === "GLOBAL" ? (
        <GlobalBranches />
      ) : (
        <BranchDetails
          detail={branchcontext?.find((x) => x.location === selectedBranch)}
        />
      )}
    </Div>
  );
};
export default Branches;
const Div = styled.div`
  display: flex;
  flex-direction: ${({ breakpoints }) => (breakpoints?.md ? "row" : "column")};
  gap: ${({ breakpoints }) => (breakpoints?.md ? "1rem" : "none")};
`;
const BranchList = styled.div`
  position: relative;
  min-width: ${({ breakpoints }) => (breakpoints?.md ? "12em" : "3em")};
  display: flex;
  flex-direction: ${({ breakpoints }) => (breakpoints?.md ? "column" : "row")};
  flex-wrap: ${({ breakpoints }) => (breakpoints?.md ? "nowrap" : "wrap")};
  padding: 1rem;
  gap: 1rem;
  & > div.title {
    z-index: 1;
    display: flex;
    justify-content: center;
    font-size: 1.5em;
    font-weight: bold;
  padding: ${({ breakpoints }) => (breakpoints?.md ? "0" : " 0 1.5rem")};
  }
  & > div.list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${({ breakpoints }) => (breakpoints?.md ? "column" : "row")};
    flex-wrap: wrap;
    padding: 1rem;
    box-shadow: 1rem 1rem 3rem #c7c7c7, -1rem -1rem 3rem #ffffff;
    border-radius: 2rem;
  }
  & > hr {
    width: 100%;
    margin: 0;
  }
`;
