import { useEffect } from "react";
import Header from "./components/skeleton/Header";
import Main from "./components/skeleton/Main";
import Router from "./pages/Router";
import { languageStore } from "./state/language";
import HeaderContent from "./components/ui/nav/HeaderContent";
import theme from "./style/styled-components/theme";
import ScrollToTop from "./components/router/ScrollToTop";
import styled, { ThemeProvider } from "styled-components";
function App() {
  const lang = languageStore();
  useEffect(() => {
    const langlocal = localStorage.getItem("language");
    lang.setLanguage(langlocal ?? "KR");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Div id="app">
      <ThemeProvider theme={theme}>
        <HeaderDiv>
          <Header>
            <HeaderContent />
          </Header>
        </HeaderDiv>
        <Main>
          <ScrollToTop />
          <Router />
        </Main>
      </ThemeProvider>
    </Div>
  );
}

export default App;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
const HeaderDiv = styled.div`
  position: relative;
  min-height: 6rem;
`;
