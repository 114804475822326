import styled from "styled-components";
import UseBreakpoints from "../../hooks/ui/UseBreakpoints";
import WorkScopeElement from "../common/WorkScopeElement";
const WorkScopes = ({ contents }) => {
  const breakpoints = UseBreakpoints();
  return (
    <Div breakpoints={breakpoints} length={contents?.length}>
      <div />
      {contents?.map((x, idx) => {
        return <WorkScopeElement noLine smallTitle key={idx} title={x.title} icon={x.icon} />;
      })}
    </Div>
  );
};
export default WorkScopes;
const Div = styled.div`
  padding: 2em 0;
  position: relative;
  display: grid;
  gap: 2rem 0;
  grid-template-columns: ${({ breakpoints, length }) =>
    breakpoints.xxl
      ? "1fr ".repeat(Math.ceil(length / Math.floor((length + 4) / 5))) :
      breakpoints.xl
      ? "1fr ".repeat(Math.ceil(length / Math.floor((length + 3) / 4))) :
      breakpoints.md
      ? "1fr ".repeat(Math.ceil(length / Math.floor((length + 2) / 3)))
      : "1fr 1fr"};
  & > div:first-child {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(#ace7 20%, transparent 20%),
      radial-gradient(#ace7 20%, transparent 20%);
    background-color: transparent;
    background-position: 0 0, 3px 3px;
    background-size: 6px 6px;
    opacity: 0.6;
    border-radius: 3em;
  }
`;
