import { size } from "../../responsive/resolution";
const minWidth = (res) => `(min-width: ${size[res]}px)`;
const maxWidth = (res) => `(max-width: ${size[res] - 1}px)`;
export const device = {
  mobileS: minWidth("mobileS"),
  mobileM: minWidth("mobileM"),
  mobileL: minWidth("mobileL"),
  tablet: minWidth("tablet"),
  laptop: minWidth("laptop"),
  laptopL: minWidth("laptopL"),
  desktop: minWidth("desktop"),
  desktopL: minWidth("desktopL"),
  sm: minWidth("sm"),
};
export const deviceMax = {
  mobileS: maxWidth("mobileS"),
  mobileM: maxWidth("mobileM"),
  mobileL: maxWidth("mobileL"),
  tablet: maxWidth("tablet"),
  laptop: maxWidth("laptop"),
  laptopL: maxWidth("laptopL"),
  desktop: maxWidth("desktop"),
  desktopL: maxWidth("desktopL"),
  sm: maxWidth("sm"),
};
