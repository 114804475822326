import PageTemplate from '../../../components/ui/common/template/PageTemplate';
import TitleTemplate from '../../../components/ui/common/template/TitleTemplate';
import ContactPoints from '../../../components/ui/members/ContactPoints';
import DiamondScope from '../../../components/ui/specialty/DiamondScope';
import WorkScopes from '../../../components/ui/specialty/WorkScopes';
import Image from '../../../resources/images/cosmetics.jpg';
import Icon1 from '../../../resources/images/icon/15. 화장품/1_컨설팅.svg'
import Icon2 from '../../../resources/images/icon/15. 화장품/2_성분패키지.svg'
import Icon3 from '../../../resources/images/icon/15. 화장품/3_표통.svg'
import Icon4 from '../../../resources/images/icon/15. 화장품/4_품질검사.svg'
import Icon5 from '../../../resources/images/icon/15. 화장품/5_한글라벨검토제작.svg'
const CosmeticPage = () => {
    return (<PageTemplate title="화장품" large phrase="화장품 수입 컨설팅부터 사후관리까지 원스텝 서비스를 제공합니다." image={Image}
        description="화장품 수입 노하우를 겸비한 구성 조직원들이 귀사와의 원활한 소통을 통하여 신속하고 정확한 업무 처리로 바람직한 비즈니스 파트너가 되어 드리겠습니다." >
        <TitleTemplate
            title="업무 범위"
            subHeader=""
            noImage
        />
        <WorkScopes
            contents={[
                { title: "수입컨설팅", icon: Icon1 },
                { title: "성분, 패키지 및 필수서류 검토", icon: Icon2 },
                { title: "표준통관 예정보고 대행", icon: Icon3 },
                { title: "품질검사 의뢰 대행", icon: Icon4 },
                { title: "화장품법에 의한 한글라벨 검토 제작", icon: Icon5 },
            ]}
        />
        <TitleTemplate
            title="Strength"
            noImage
            subHeader="A-One 만의 장점으로 고객을 힘껏 서포트 해 나가겠습니다."
        />
        <DiamondScope
            contents={[
                {
                    title: "전문성",
                    text: "축적된 노하우와 경험을 겸비",
                },
                {
                    title: "탄탄한 업무 조직",
                    text: "요건 부문의 탄탄한 인력 구성",
                },
                {
                    title: "분석력",
                    text: "관련 법령에 대한 높은 이해도와 실력",
                },
                {
                    title: "원스텝서비스",
                    text: "컨설팅부터 통관 사후관리에 이르는 넓은 업무 coverage",
                },
            ]}
        />
        <TitleTemplate
            title="Contact Point"
            noImage
            subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
        />
        <ContactPoints members={["tnt-2000", "josep62"]} />
    </PageTemplate>)
}
export default CosmeticPage;