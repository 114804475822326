import styled from "styled-components";
const Title = ({ title, subHeader }) => {
  return (
    <Div>
      <TitleSpan>{title}</TitleSpan>
      {subHeader && <SubHeaderDiv>{`"${subHeader}"`}</SubHeaderDiv>}
      <FlexDiv>
        <BorderOnRightEdge></BorderOnRightEdge>
        <NoBorder></NoBorder>
      </FlexDiv>
    </Div>
  );
};
export default Title;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2em;
`;
const TitleSpan = styled.span`
  font-size: 2.5em;
  font-weight: bolder;
  text-align: center;
`;
const SubHeaderDiv = styled.div`
  font-size: 1.2em;
  color: ${({theme}) => theme.colors.foreground.highlight};
  text-align: center;
  font-weight: 300;
  /* font-weight: bold; */
`;
const FlexDiv = styled.div`
  display: flex;
`;
const BorderOnRightEdge = styled.div`
  flex: 1;
  height: 2em;
  border-width: 0 3px 0 0;
  border-style: solid;
  border-color: black;
`;
const NoBorder = styled.div`
  flex: 1;
`;
