import styled from "styled-components";
import { getMemberbyId } from "../../../context/members/membercontext";
import CenterDiv from "../../../style/styled-components/components/CenterDiv";
import { device } from "../../../style/styled-components/helpers/device";
import NameCard from "./NameCard";
const ContactPoints = ({ members }) => {
  return (
    <CenterDiv>
      <Div length={members?.length}>
        {members?.map((x, idx) => {
          return <NameCard key={idx} member={getMemberbyId(x)} unmaskContact />;
        })}
      </Div>
    </CenterDiv>
  );
};
export default ContactPoints;
const Div = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  list-style: none;
  @media ${device.laptop} {
    display: grid;
    // 둘 이상일 때만 column 두개
    grid-template-columns: ${({ length }) =>
      length && length > 1 ? "1fr 1fr" : "1fr"};
  }
`;
