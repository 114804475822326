import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import WorkScopes from "../../../components/ui/specialty/WorkScopes";
import DiamondScope from "../../../components/ui/specialty/DiamondScope";
import QuarantineImage from "../../../../src/resources/images/quarantine.jpg";
import img1 from "../../../resources/images/icon/5. 검역/1. 수입식품 컨설팅.png";
import img2 from "../../../resources/images/icon/5. 검역/2.1 농림축산검역검사 본부 검역신고대행.png";
import img4 from "../../../resources/images/icon/5. 검역/3. 식약처 수입식품 등 신고대행.png";
import img5 from "../../../resources/images/icon/5. 검역/4. 사료협회 등 사료성분등록 대행.png";
import img6 from "../../../resources/images/icon/5. 검역/5. 수입수출 통관.png";
import ContactPoints from "../../../components/ui/members/ContactPoints";
const QuarantinePage = () => {
  return (
    <PageTemplate
      large
      image={QuarantineImage}
      title="검역/검사"
      phrase="국내 최대 규모의 검역/검사 부분의 조직을 갖추어 체계화된 전문서비스를 제공합니다."
      description="수입식품 컨설팅을 통한 수입사전안전성검토와 가공식품, 건강기능식품,
      농축수산물, 기구용기, 위생용품 등 세분화 되어있는 검역/검사 요건을
      충족시키기 위해 각 파트별 전문가로 구성된 기능별 조직 구성으로 차별화된
      전문적 서비스를 제공합니다. 부산총괄본부를 중심으로 인천공항 등 전국 9개
      지사의 네트워크를 통해 검역대상물품의 검사/검역 대행 및 수출입 통관까지
      One-Stop 서비스를 약속드립니다."
    >
      <TitleTemplate
        title="업무 범위"
        subHeader="풍부한 경험을 기반으로 대상 물품에 대한 신속 정확한 검역절차를 이행합니다."
        noImage
      />
      <WorkScopes
        contents={[
          { title: "수입식품 컨설팅", icon: img1 },
          { title: "농림축산검역검사 본부 검역신고 대행", icon: img2 },
          { title: "식약처 수입식품 등 신고 대행", icon: img4 },
          { title: "사료협회 등 사료성분등록 대행", icon: img5 },
          { title: "수입/수출 통관", icon: img6 },
        ]}
      />
      <TitleTemplate
        title="Strength"
        noImage
        subHeader="A-One 만의 장점으로 고객을 힘껏 서포트 해 나가겠습니다."
      />
      <DiamondScope
        contents={[
          {
            title: "MAN POWER",
            text: "오랜 경험이 축적된 전문가로 구성된 국내최대의 검역조직",
          },
          {
            title: "TASK-FORCE",
            text: "기능별 조직 구성으로 차별화된 서비스 제공",
          },
          {
            title: "Management",
            text: "검사/검역과 관련된 법령 상시 CHECK 및 안내",
          },
          {
            title: "Consulting",
            text: "수입식품 컨설팅을 통한 수입사전안전성 검토",
          },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["tnt-2000", "josep62", "pass911"]} />
    </PageTemplate>
  );
};
export default QuarantinePage;
