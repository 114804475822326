import styled from "styled-components";
const ForexPunishmentTable = () => {
  return (
    <Table>
      <colgroup>
        <col />
        <col />
      </colgroup>
      <thead>
        <tr>
          <th>위반사항</th>
          <th>과태료</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>외국환은행장 신고사항</td>
          <td>MAX (100만원, 위반금액의 2%)</td>
        </tr>
        <tr>
          <td>기획재정부장관, 한국은행총재 등 신고사항</td>
          <td>MAX (200만원, 위반금액의 4%)</td>
        </tr>
        <tr>
          <td>위반금액 산정이 곤란한 자본거래 신고의무</td>
          <td>200만원</td>
        </tr>
      </tbody>
    </Table>
  );
};
export default ForexPunishmentTable;
const Table = styled.table`
  border-collapse: collapse;
  text-align: center;
  padding: 1rem 0;
  & > thead {
    font-size: 1.2em;
    background-color: ${({ theme }) => theme.colors?.dark};
    color: ${({ theme }) => theme.colors?.foreground?.inverted};
    & > tr {
      &:first-child {
        & > th {
          font-weight: normal;
          padding: 1em;
          &:first-child {
            border-radius: 1rem 0 0 0;
            background-color: ${({ theme }) => theme.colors?.dark};
          }
          &:last-child {
            border-radius: 0 1rem 0 0;
          }
        }
      }
    }
  }
  & > tbody {
    color: ${({ theme }) => theme.colors?.foreground?.normal};
    & > tr {
      &:nth-child(even) {
        background-color: ${({ theme }) => theme.colors?.monotone?.extralight};
      }
      & > td {
        padding: 0.5em 1em;
        &:first-child {
          text-align: center;
        }
      }
      &:last-child {
        & > td:first-child {
          border-radius: 0 0 0 1rem;
        }
        & > td:last-child {
          border-radius: 0 0 1rem 0;
        }
      }
    }
  }
`;
