import { Link } from 'react-router-dom';
import styled from 'styled-components';
const HomeShortcut = ({ url = "", title, icon }) => {
    return (
        <Div>
            <Link to={url}>
                <img src={icon} alt="icon" />
                <div>{title}</div>
            </Link>
        </Div>
    )
}
export default HomeShortcut;
const Div = styled.div`
        font-size: 1.2em;
        z-index: 2;
        flex: 1;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5));
        &:hover {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));

        }
        & > a {
            display: flex;
            flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        & > img {
            margin-top: .5rem;
            width: 30%;
        }
        & > div {
            padding: .5rem 0;
        }
        }

`;