import { Fragment, useState } from "react";
import { fadeIn } from "../../style/styled-components/keyframes/fade";
import { menucontext } from "../../context/nav/menucontext";
import { slideDown } from "../../style/styled-components/keyframes/slide";
import DropdownNav from "./DropdownNav";
import UseBreakpoints from "../hooks/ui/UseBreakpoints";
import { languageStore } from "../../state/language";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Nav = () => {
  const breakpoints = UseBreakpoints();
  const { language } = languageStore();
  const [currentHoverKey, setCurrentHoverKey] = useState("");
  // 2022/08/14 아래 filter부분에서 visible 어쩌고 할까 생각중
  return (
    <Div>
      {breakpoints.xl && (
        <ul>
          {menucontext.children.filter(x => x).map((x) => (
            <Fragment key={x.url + x.title}>
              <Link to={x.url} draggable={false} onMouseEnter={(prevState) => setCurrentHoverKey(x.key)} onMouseLeave={(prevState) => setCurrentHoverKey("")}
              >
                <MenuElement hover={x.key === currentHoverKey}>
                  <span>{language === "EN" && x.engtitle ? x.engtitle : language === "CN" && x.cntitle ? x.cntitle : language === "JP" && x.jptitle ? x.jptitle : x.title}</span>
                </MenuElement>
              </Link>
              {currentHoverKey &&
                <DropdownNav title={language === "EN" && x.engtitle ? x.engtitle : language === "CN" && x.cntitle ? x.cntitle : language === "JP" && x.jptitle ? x.jptitle : x.title} menuKey={x.key}
                  currentHoverKey={currentHoverKey} setCurrentHoverKey={setCurrentHoverKey}>
                  {/* 드롭다운 메뉴: 내용없으면 비워놓을것 */}
                  {/* ul-li */}
                  <ul>
                    {x.children &&
                      x.children.map(({ url, title, engtitle, cntitle, jptitle, children }, idx) => (
                        <Fragment key={idx}>
                          {children && (
                            <NavSub>
                              <NavSubTitle>{language === "EN" && engtitle ? engtitle : language === "CN" && cntitle ? cntitle : language === "JP" && jptitle ? jptitle : title}</NavSubTitle>
                              <ul>
                                {children.map((z, idx) => (
                                  <Link key={idx} to={z.url} draggable={false} onClick={(e) => setCurrentHoverKey("")}>
                                    <li>
                                      <span>{language === "EN" && z.engtitle ? z.engtitle : language === "CN" && z.cntitle ? z.cntitle : language === "JP" && z.jptitle ? z.jptitle : z.title}</span>
                                    </li>
                                  </Link>
                                ))}
                              </ul>
                            </NavSub>
                          )}
                          {!children && (
                            <Link to={url} draggable={false} onClick={(e) => setCurrentHoverKey("")}>
                              <li>{language === "EN" && engtitle ? engtitle : language === "CN" && cntitle ? cntitle : language === "JP" && jptitle ? jptitle : title}</li>
                            </Link>
                          )}
                        </Fragment>
                      ))}
                  </ul>
                </DropdownNav>
              }
            </Fragment>
          ))}
        </ul>
      )}
    </Div>
  );
};
export default Nav;
const Div = styled.nav`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex: 1;
  height: 100%;
  text-decoration: none;
  & ul {
    margin: 0;
    list-style-type: none;
    font-weight: normal;
    & li {
      pointer-events: none;
    }
  }
  & > ul {
    align-items: stretch;
    display: flex;
    padding: 0;
    width: 50vw;
    & > a {
      flex: 1;
      display: flex;
      color: inherit;
      & > li {
        width: 100%;
        justify-content: center;
        flex: 1;
        display: flex;
        font-size: 1.2em;
        align-items: center;
      }
      & + div {
        /* 서브메뉴 */
        background-color: white;
        top: 6rem;
        left: 0;
        position: absolute;
        display: none;
        padding: auto;
        z-index: 1;
        width: 100vw;
        box-sizing: border-box;
        border-radius: 0px 0px 10px 10px;
        &:hover {
          display: flex;
        }
      }
    }
    & > a:hover {
      & + div:hover {
        display: flex;
      }
      & + div {
        display: flex;
        animation: ${fadeIn} 0.5s;
        /* animation: ${slideDown} 1s cubic-bezier(0.600, -0.280, 0.735, 0.045) reverse both; */
      }
      & + div:empty {
        display: none;
        margin: 0;
      }
    }
  }
`;
const NavSub = styled.div`
  position: relative;
  z-index: 2;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 9.5em;
  gap: 0.5em;
  padding: 0.5em 1em;
  overflow: hidden;
  & a:hover {
    color: ${({ theme }) => theme.colors.foreground.highlight};
    background-color: #eee;
    transition: background-color 0.5s;
  }
  & > ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    & li {
      padding: 0.5em 0.5em;
    }
  }
  & > span {
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1em;
      background-color: ${({ theme }) => theme.colors.medium};
      transform: translate3d(-130%, 0, 0);
      transition: transform 300ms;
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.monotone.extralight};
    transition: background-color 1s;
    &:hover > span:after {
      transform: translate3d(0, 0, 0);
    }
  }
`;
const NavSubTitle = styled.span`
  position: relative;
  z-index: 2;
  font-size: 1em;
  color: #789;
  font-weight: 300;
  padding: 0.5em;
`;

const MenuElement = styled.li`
  color : ${({ hover, theme }) => hover ? theme.colors.foreground.highlight : "none"};
  background : ${({ hover, theme }) => hover ? theme.colors.monotone.extralight : "none"};
/* background : ${({ hover, theme }) => hover ? "linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .2))" : "none"};
 & > span:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1em;
      background-color: ${({ theme }) => theme.colors.dark};
      transform: translate3d(-130%, 0, 0);
      transition: transform 300ms;
    }
    &:hover {
    background-color: ${({ theme }) => theme.colors.monotone.extralight};
    transition: background-color 1s;
    &:hover > span:after {
      transform: translate3d(0, 0, 0);
    }
  } */
`
  ;