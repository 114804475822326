export const companycontext = {
  kr: {
    name: "관세법인 에이원",
    address1: "서울특별시 강남구 언주로 703 3층",
    address2: "(LX한국국토정보공사, 논현동)",
  },
  en: {
    name: "Aone Customs and Trade Service",
    address1: "3F, Eonju-ro 703, Gangnam-gu, Seoul",
    address2: "",
  },
};
