import Page404 from "./Page404";
import Home from "./home/Home";
import Intro from "./intro/IntroPage";
import { Route, Routes } from "react-router-dom";
import HistoryPage from "./intro/history/HistoryPage";
import PrizesPage from "./intro/prizes/PrizesPage";
import BranchPage from "./intro/branch/BranchPage";
import ESGPage from "./intro/esg/ESGPage";
import ImpexpPage from "./specialty/clearance/ImpexpPage";
import DrawbackPage from "./specialty/clearance/DrawbackPage";
import RequirementPage from "./specialty/requirements/RequirementPage";
import QuarantinePage from "./specialty/requirements/QuarantinePage";
import ExpressPage from "./specialty/clearance/ExpressPage";
import ProfilePage from "./profile/ProfilePage";
import BoardMembersPage from "./profile/BoardMembersPage";
import ProfessionalsPage from "./profile/ProfessionalsPage";
import FTAPage from "./specialty/consulting/FTAPage";
import AEOPage from "./specialty/consulting/AEOPage";
import ACVAPage from "./specialty/consulting/ACVAPage";
import DiagnosisPage from "./specialty/consulting/DiagnosisPage";
import CustomsPage from "./specialty/legal/CustomsPage";
import ForexPage from "./specialty/legal/ForexPage";
import ContestationPage from "./specialty/legal/ContestationPage";
import EducationPage from "./specialty/EducationPage";
import ResearchPage from "./specialty/ResearchPage";
import DevPage from "./it/DevPage";
import OrganizationPage from "./profile/OrganizationPage";
import AdvisoryPage from "./profile/AdvisoryPage";
import NewTechPage from "./it/NewTechPage";
import InvestigationPage from "./specialty/legal/InvestigationPage";
import ClassificationPage from "./specialty/consulting/ClassificationPage";
import ChilePage from "./specialty/consulting/fta/ChilePage";
import SingaporePage from "./specialty/consulting/fta/SingaporePage";
import ASEANPage from "./specialty/consulting/fta/ASEANPage";
import CEPAPage from "./specialty/consulting/fta/CEPAPage";
import EUPage from "./specialty/consulting/fta/EUPage";
import PeruPage from "./specialty/consulting/fta/PeruPage";
import USPage from "./specialty/consulting/fta/USPage";
import EFTAPage from "./specialty/consulting/fta/EFTAPage";
import TurkiyePage from "./specialty/consulting/fta/TurkiyePage";
import AustraliaPage from "./specialty/consulting/fta/AustraliaPage";
import CanadaPage from "./specialty/consulting/fta/CanadaPage";
import ChinaPage from "./specialty/consulting/fta/ChinaPage";
import VietnamPage from "./specialty/consulting/fta/VietnamPage";
import NewZealandPage from "./specialty/consulting/fta/NewZealandPage";
import ColombiaPage from "./specialty/consulting/fta/ColombiaPage";
import MiddleAmericaPage from "./specialty/consulting/fta/MiddleAmericaPage";
import UKPage from "./specialty/consulting/fta/UKPage";
import RCEPPage from "./specialty/consulting/fta/RCEPPage";
import AoneNewsPage from "./board/AoneNewsPage";
import CustomsNewsPage from "./board/CustomsNewsPage";
import CEOColumnPage from "./board/CEOColumnPage";
import ArticlePage from "./board/ArticlePage";
import ceocolumnarticlelist from '../context/boards/ceocolumn/articlelist.json';
import aonenewsarticlelist from '../context/boards/aonenews/articlelist.json';
import customsnewsarticlelist from '../context/boards/customsnews/articlelist.json';
import CareersPage from "./careers/CareersPage";
import CosmeticPage from "./specialty/requirements/CosmeticPage";
import QNLPage from "./specialty/logistics/QNLPage";
const Router = () => {
  return (
    <Routes>
      <Route exact path="/intro" element={<Intro />} />
      <Route exact path="/careers" element={<CareersPage />} />
      <Route exact path="/history" element={<HistoryPage />} />
      <Route exact path="/prizes" element={<PrizesPage />} />
      <Route exact path="/branch" element={<BranchPage />} />
      <Route exact path="/esg" element={<ESGPage />} />
      <Route exact path="/impexp" element={<ImpexpPage />} />
      <Route exact path="/drawback" element={<DrawbackPage />} />
      <Route exact path="/requirement" element={<RequirementPage />} />
      <Route exact path="/cosmetic" element={<CosmeticPage />} />
      <Route exact path="/quarantine" element={<QuarantinePage />} />
      <Route exact path="/express" element={<ExpressPage />} />
      <Route exact path="/consulting/fta" element={<FTAPage />} />
      <Route exact path="/consulting/fta/canada" element={<CanadaPage />} />
      <Route exact path="/consulting/fta/chile" element={<ChilePage />} />
      <Route exact path="/consulting/fta/china" element={<ChinaPage />} />
      <Route exact path="/consulting/fta/colombia" element={<ColombiaPage />} />
      <Route
        exact
        path="/consulting/fta/singapore"
        element={<SingaporePage />}
      />
      <Route exact path="/consulting/fta/asean" element={<ASEANPage />} />
      <Route exact path="/consulting/fta/cepa" element={<CEPAPage />} />
      <Route exact path="/consulting/fta/efta" element={<EFTAPage />} />
      <Route exact path="/consulting/fta/eu" element={<EUPage />} />
      <Route exact path="/consulting/fta/peru" element={<PeruPage />} />
      <Route exact path="/consulting/fta/rcep" element={<RCEPPage />} />
      <Route exact path="/consulting/fta/turkiye" element={<TurkiyePage />} />
      <Route exact path="/consulting/fta/vietnam" element={<VietnamPage />} />
      <Route
        exact
        path="/consulting/fta/australia"
        element={<AustraliaPage />}
      />
      <Route
        exact
        path="/consulting/fta/newzealand"
        element={<NewZealandPage />}
      />
      <Route
        exact
        path="/consulting/fta/middleamerica"
        element={<MiddleAmericaPage />}
      />
      <Route exact path="/consulting/fta/uk" element={<UKPage />} />
      <Route exact path="/consulting/fta/us" element={<USPage />} />
      <Route exact path="/consulting/aeo" element={<AEOPage />} />
      <Route exact path="/consulting/acva" element={<ACVAPage />} />
      <Route exact path="/consulting/diagnosis" element={<DiagnosisPage />} />
      <Route
        exact
        path="/consulting/classification"
        element={<ClassificationPage />}
      />
      <Route exact path="/legal/customs" element={<CustomsPage />} />
      <Route
        exact
        path="/legal/investigation"
        element={<InvestigationPage />}
      />
      <Route exact path="/legal/forex" element={<ForexPage />} />
      <Route exact path="/legal/contestation" element={<ContestationPage />} />
      <Route exact path="/logistics/qnl" element={<QNLPage />} />
      <Route exact path="/education" element={<EducationPage />} />
      <Route exact path="/research" element={<ResearchPage />} />
      <Route exact path="/it/dev" element={<DevPage />} />
      <Route exact path="/it/newtech" element={<NewTechPage />} />
      <Route exact path="/profile/:id" element={<ProfilePage />} />
      <Route exact path="/boardmembers" element={<BoardMembersPage />} />
      <Route exact path="/organization" element={<OrganizationPage />} />
      <Route exact path="/professionals" element={<ProfessionalsPage />} />
      <Route exact path="/advisories" element={<AdvisoryPage />} />
      <Route exact path="/boards/aonenews" element={<AoneNewsPage />} />
      <Route exact path="/boards/aonenews/:id" element={<ArticlePage category="aonenews" title="에이원 소식" list={aonenewsarticlelist} />} />
      <Route exact path="/boards/customsnews/:id" element={<ArticlePage category="customsnews" title="관세무역 뉴스" list={customsnewsarticlelist} />} />
      <Route exact path="/boards/board2" element={<CustomsNewsPage />} />
      <Route exact path="/boards/ceocolumn" element={<CEOColumnPage />} />
      <Route exact path="/boards/ceocolumn/:id" element={<ArticlePage category="ceocolumn" title="정운기칼럼" list={ceocolumnarticlelist} />} />
      <Route exact path="/" element={<Home />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};
export default Router;
