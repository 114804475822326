import styled from 'styled-components';
const MapMarker = () => {
    return (
        <Div>
            <div className="pin2"></div>
        </Div>
    );
}
export default MapMarker;
const Div = styled.div`
/* body {
  background: #e6e6e6;
} */
position: relative;
.pin1 {
  position: absolute;
  top: 20%;
  left: -50%;
  /* margin-left: -100%; */
  border-radius: 50% 50% 50% 0;
  border: 4px solid ${({ theme }) => theme.colors.dark};
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
}

.pin1::after {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  background-color: ${({ theme }) => theme.colors.dark};
}



.pin2 {
  position: relative;
  /* top: 40%;
  left: 50%; */
  
  border-radius: 50%;
  border: 8px solid ${({ theme }) => theme.colors.dark};;
  width: 8px;
  height: 8px;
}

.pin2::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  bottom: -30px;
  left: -6px;
  border: 10px solid transparent;
  border-top: 17px solid ${({ theme }) => theme.colors.dark};;
}

`;