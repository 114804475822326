import styled from "styled-components";
import UseBreakpoints from "../../../../components/hooks/ui/UseBreakpoints";
import TitleTemplate from "./TitleTemplate";
import Breadcrumbs from "../Breadcrumbs";
import UseScroll from "../../../hooks/ui/UseScroll";
import { Link } from "react-scroll";
import { Helmet } from "react-helmet";
import { languageStore } from "../../../../state/language";
const PageTemplate = ({
  children,
  title,
  image,
  phrase,
  subHeader,
  noImage,
  large,
  aone,
  description,
}) => {
  const useScroll = UseScroll();
  const breakpoints = UseBreakpoints();
  const { language } = languageStore();
  return (
    <Div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${language === "EN" ? "AONE Customs and Trade Service" : `${title} - 관세법인 에이원`}`} </title>
        <link
          rel="icon"
          type="image/x-icon"
          href="./resources/images/logo/aone_logo_only.ico"
        />
      </Helmet>
      <div className="breadcrumbs">
        <Breadcrumbs />
      </div>
      <Page breakpoints={breakpoints}>
        {title && (
          <TitleTemplate
            title={title}
            image={image}
            phrase={phrase}
            subHeader={subHeader}
            noImage={noImage}
            large={large}
            aone={aone}
          />
        )}
        <div className="description" >
          <p>
            {description}
          </p>
        </div>
        {children}
      </Page>
      <ToTop show={useScroll.y >= 300}>
        <Link to="app" spy smooth>
          <div>↑</div>
          <div>TOP</div>
        </Link>
      </ToTop>
    </Div>
  );
};
export default PageTemplate;
const Div = styled.div`
  & > div.breadcrumbs {
    position: relative;
    width: 100%;
    display: flex;
    & > div {
      flex: 1;
    }
  }
`;
const Page = styled.div`
  width: ${(props) =>
    props.breakpoints.xl ? "75vw" : props.breakpoints.lg ? "85vw" : "95vw"};
    max-width: 100rem;
  padding: 0 0 5rem 0;
  & div.description > p ,
  & > p {
      font-size: 1.3em;
      line-height: 1.7em;
      text-align: center;
      /* width: ${(props) =>
    props.breakpoints.xl
      ? "60rem"
      : props.breakpoints.lg
        ? "70rem"
        : "50rem"}; */
      padding: ${(props) =>
    props.breakpoints.xl
      ? "0 10rem"
      : props.breakpoints.lg
        ? "0 10vw"
        : "0 2vw"};
    }
  & div.description 
  {
display: flex;
justify-content: center;
    
  }
`;
const ToTop = styled.div`
  width: 100%;
  position: sticky;
  display: flex;
  justify-content: flex-end;
  bottom: -2.5rem;
  right: -2.5em;
  margin: 0 0 1rem 0;
  & > a {
    z-index: 5;
    opacity: ${({ show }) => (show ? 0.4 : 0.2)};
    transform: scale(${({ show }) => (show ? 1 : 0)});
    transition: all 0.2s ease-in;
    display: flex;
    flex-direction: column;
    position: sticky;
    align-items: center;
    justify-content: center;
    bottom: -2.5rem;
    right: -2.5em;
    content: "";
    width: 5rem;
    height: 5rem;
    border-radius: 2.5rem;
    line-height: 1.3em;
    background-color: ${({ theme }) => theme.colors.medium};
    color: ${({ theme }) => theme.colors.foreground.inverted};
    font-weight: bold;
    gap: 0.4em;
    & > div {
      z-index: 6;
      display: flex;
      &:first-child {
        font-size: 1.3em;
      }
    }
    &:hover {
    opacity: 1;
      transform: translate(0, -0.2rem);
    }
  }
`;
