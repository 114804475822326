import ContactPoints from "../../../components/ui/members/ContactPoints";
import DiagnosisImage from "../../../resources/images/diagnosis.jpg";
import DiamondScope from "../../../components/ui/specialty/DiamondScope";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import WorkScopes from "../../../components/ui/specialty/WorkScopes";
import img1 from "../../../resources/images/icon/10. 기업진단/1. 관세업무 현황진단.png";
import img2 from "../../../resources/images/icon/10. 기업진단/2. 품목분류 적정성  일관성 진단.png";
import img3 from "../../../resources/images/icon/10. 기업진단/3. 관세평가 적정성 진단.png";
import img4 from "../../../resources/images/icon/10. 기업진단/4. 외환거래 적법성 진단.png";
import img6 from "../../../resources/images/icon/10. 기업진단/5.2 개별환급 적정성진단.png";
import img7 from "../../../resources/images/icon/10. 기업진단/6. FTA 적용 적정성 진단.png";

const DiagnosisPage = () => {
  return (
    <PageTemplate
      large
      title="기업진단"
      image={DiagnosisImage}
      phrase="관세/외환 관련 RISK 조기진단을 통하여 기업 RISK를 최소화 합니다."
    >
      <p>
        기업진단 컨설팅의 목적은 무역 환경에서 수출입기업이 보유하는 다양한
        Risk를 줄이고자 함입니다. <br /> 고객사의 수출입 현황에 대한 과세가격,
        품목분류, 관세환급, FTA, 관세감면, 수출입요건, 외국환거래 적법성 등
        전반적인 기업 Risk 진단 서비스를 제공합니다. 풍부한 세관심사 인력 및
        경험을 통하여 고객사의 산업분야별, 거래형태별 보유할 수 있는 관세/외환
        관련 Risk의 맞춤형 진단 서비스 제공이 가능합니다.
      </p>
      <TitleTemplate
        title="업무범위"
        noImage
        subHeader="각 기업별로 발생할 수 있는 법률적 Risk를 진단하고, 관리방안을 제시합니다."
      />
      <WorkScopes
        contents={[
          { title: "관세업무 현황진단", icon: img1 },
          { title: "품목분류 적정성/일관성 진단", icon: img2 },
          { title: "관세평가 적정성 진단", icon: img3 },
          { title: "외환거래 적법성 진단", icon: img4 },
          { title: "개별환급 적정성 진단", icon: img6 },
          { title: "FTA적용 적정성 진단", icon: img7 },
        ]}
      />
      <TitleTemplate
        title="Strength"
        noImage
        subHeader="진단부터 수출입통관까지 함께할 수 있는 에이원만의 특화된 서비스를 제공합니다."
      />
      <DiamondScope
        contents={[
          {
            title: "법률 자문 서비스",
            text: "수출입관련법령 관련 개정법령 안내 및 법률자문 상시 진행",
          },
          {
            title: "MAN-POWER",
            text: "세관에서 경력을 쌓은 전문가, 업계 최고 관세사로 구성된 심사 전문 컨설팅 그룹",
          },
          {
            title: "분야별 전문 서비스 제공",
            text: "과세가격, 품목분류, 관세환급, 외국환거래 등 사안에 맞추어 정확한 솔루션을 제시",
          },
          {
            title: "Integrated management",
            text: "수정신고, 경정청구 및 향후 수입통관까지 통합관리서비스 제공",
          },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["diagnosticsMain"]} />
    </PageTemplate>
  );
};
export default DiagnosisPage;
