import styled from "styled-components";
import PrizeTemplate from "./prize-category/PrizeTemplate";
import { useMediaQuery } from "react-responsive";
const PrizeCategory = ({ title, prizes }) => {
  const res = useMediaQuery({ minWidth: 1300 });
  return (
    <Div res={res}>
      <Category res={res}>
        <div>
          <CategorySpan>{title}</CategorySpan>
        </div>
      </Category>
      <Prizes res={res}>
        {prizes &&
          prizes.map((x) => (
            <PrizeTemplate
              key={x.presenter + x.content + x.year + x.awardee}
              presenter={x.presenter}
              content={x.content}
              year={x.year}
              image={x.image}
            />
          ))}
      </Prizes>
    </Div>
  );
};
export default PrizeCategory;
const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.res ? "row" : "column")};
  gap: 3vw;
`;
const Category = styled.div`
  flex: 1;
  text-align: ${(props) => (props.res ? "right" : "center")};
  font-weight: bold;
  font-size: 2em;
  padding-top: 3em;
  & > div {
    position: sticky;
    top: 10rem;
  }
`;
const Prizes = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 5;
  gap: 2em;
  justify-content: ${(props) => (props.res ? "flex-start" : "center")};
`;
const CategorySpan = styled.span``;
