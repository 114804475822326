import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { memo, useCallback, useState } from "react";
const containerStyle = {
  width: "100%",
  minHeight: "20rem",
};
function BranchMap({ branch }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDwUmWfNbd1PMOZAE2AlKJtvIHadb4tuiM",
  });
  const [, setMap] = useState(null);
  // const [map, setMap] = useState(null);
  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(branch?.coordinate);
    // map.fitBounds(bounds);
    setMap(map);
  }, []);
  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={branch?.coordinate}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <>
        <Marker
          position={branch?.coordinate}
          label={branch?.name}
          title={branch?.name}
        />
      </>
    </GoogleMap>
  ) : (
    <></>
  );
}
export default memo(BranchMap);
