import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { fadeIn } from "../../../../../style/styled-components/keyframes/fade";
import { fromBottom } from "../../../../../style/styled-components/keyframes/transform";
import UseBreakpoints from "../../../../hooks/ui/UseBreakpoints";
const PhraseWithImage = ({ image, phrase, large, aone }) => {
  const res = useMediaQuery({ minWidth: 1200 });
  const breakpoints = UseBreakpoints();
  return (
    <Div res={res} image={image} large={large} breakpoints={breakpoints}>
      {large && <WhiteBoxDiv />}
      <TitleDiv>
        {aone && <TitleSpan>A-ONE,</TitleSpan>}
        <TitleSpan>{phrase}</TitleSpan>
      </TitleDiv>
    </Div>
  );
};

export default PhraseWithImage;

const Div = styled.div`
  z-index: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({res, large}) => (res ? (large ? "20rem" : "14rem") : "50vh")};
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url(${({image}) => image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  /* width: ${({ breakpoints }) =>
    breakpoints.xxl
      ? "75rem"
      : breakpoints.xl
      ? "60vw"
      : breakpoints.md
      ? "85vw"
      : "95vw"}; */
      width: 100%;
  opacity: 0;
  animation: ${fadeIn} 1.5s forwards;
`;
const WhiteBoxDiv = styled.div`
  position: absolute;
  width: calc(100% - 2em);
  height: calc(100% - 2em);
  border: solid 3px white;
  margin: 1em;
`;
const TitleDiv = styled.div`
  padding: 0 2em;
  display: flex;
  flex-direction: column;
  width: auto;
`;

const TitleSpan = styled.span`
  color: white;
  text-align: center;
  font-size: clamp(1em, 7vw, 3em);
  font-weight: bold;
  opacity: 0;
  animation: 2s 0.3s ${fromBottom} forwards;
`;
