import TitleTemplate from "../../components/ui/common/template/TitleTemplate";
import PageTemplate from "../../components/ui/common/template/PageTemplate";
import WorkScopes from "../../components/ui/specialty/WorkScopes";
import ResearchImage from "../../resources/images/research.jpg";
import img1 from "../../resources/images/icon/13. 관세무역연구/1. 관세법령 제개정사항_rev.png";
import img2 from "../../resources/images/icon/13. 관세무역연구/2. 입법배경해석_rev.png";
import img3 from "../../resources/images/icon/13. 관세무역연구/3. 최신 관세행정 Trend 분석.png";
import img4 from "../../resources/images/icon/13. 관세무역연구/4. 관세청 및 세관 주관 세미나 참여 및 법제 교류.png";
import img5 from "../../resources/images/icon/13. 관세무역연구/5. 글로벌 경영 trend 조사 및 전망 분석.png";
import img6 from "../../resources/images/icon/13. 관세무역연구/6. 국내외 무역환경 둘러싼 최신 이슈 조사.png";
import ContactPoints from "../../components/ui/members/ContactPoints";
const ResearchPage = () => {
  return (
    <PageTemplate
      title="관세무역 연구"
      phrase="끊임없이 성장하고 발전하기 위하여 끊임없이 연구하고 노력합니다."
      large
      image={ResearchImage}
    >
      <p>
        에이원은 관세 무역 Trend를 분석을 통해 고객사와 관세 업계의 지속적인
        성장과 발정 방향을 모색하기 위하여 현대관세무역연구원을 설립하였으며,
        최고의 구성원들과 함께 연구활동을 지속하고 있습니다. <br /> 국내·외
        관세무역 분야에 관한 전문적인 조사 · 연구를 통해 법령의 입법 취지 및
        배경 등에 대한 해설 및 분석, 체계적인 관세 무역 통계자료의 DB 구축 등을
        도모하고 있습니다. 또한 국내 관세무역 연구 플랫폼으로 기능하며, 관세
        무역 업계의 혁신을 궁극적인 목표로 연구 활동을 수행하고 있습니다.
      </p>
      <TitleTemplate
        title="연구 범위"
        noImage
        subHeader="빠르게 변화하는 무역환경에 발맞추어 최신 정보를 수집, 분석합니다."
      />
      <WorkScopes
        contents={[
          { title: "관세법령 제 · 개정사항", icon: img1 },
          { title: "입법 배경 해석", icon: img2 },
          { title: "최신 관세행정 Trend 분석", icon: img3 },
          { title: "관세청 및 세관 주관 세미나 참여 및 법제 교류", icon: img4 },
          { title: "글로벌 경영 Trend 조사 및 전망 분석", icon: img5 },
          { title: "국내외 무역환경 둘러싼 최신 이슈 조사", icon: img6 },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["y2kykim"]} />
    </PageTemplate>
  );
};
export default ResearchPage;
