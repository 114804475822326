import styled from "styled-components";
import {
  device,
} from "../../../style/styled-components/helpers/device";
import UseBreakpoints from "../../hooks/ui/UseBreakpoints";
const ProcessElement = ({ icon, title, details, noLine, smallTitle }) => {
  const breakpoints = UseBreakpoints();
  return (
    <Div>
      <div className="icon">
        <Circle />
        {icon && <Image src={icon} />}
      </div>
      {!noLine && breakpoints.laptop && (
        <>
          <HorizontalLine />
          <RelativeDiv>
            <Point />
          </RelativeDiv>
        </>
      )}
      <div className="text">
        <TitleSpan smallTitle={smallTitle}>{title}</TitleSpan>
        <List>
          {details?.map((x) => {
            return <li key={x}>{x}</li>;
          })}
        </List>
      </div>
    </Div>
  );
};
export default ProcessElement;
const Div = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 2em;
  @media ${device.laptop} {
    flex-direction: column;
    width: min(18vw, 18rem);
  }
  & > div {
    &.icon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 10em;
      height: 10em;
    }
    &.text {
      display: flex;
      flex-direction: column;
    }
  }
`;
const RelativeDiv = styled.div`
  position: relative;
  display: flex;
`;
const Point = styled.div`
  position: absolute;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background-color: #115;
  top: -3.1em;
  left: -0.5em;
`;
const HorizontalLine = styled.hr`
  width: 100%;
`;
const TitleSpan = styled.span`
  position: relative;
  font-size: ${(props) => (props.smallTitle ? "1.3em" : "2em")};
  font-weight: bold;
`;
const List = styled.ul`
  padding: 0;
  margin: 0;
`;
const Circle = styled.div`
  position: absolute;
  width: 10em;
  height: 10em;
  border-radius: 5em;
  background-color: white;
  box-shadow: 1rem 1rem 3rem #c7c7c7, -1rem -1rem 3rem #ffffff;
`;
const Image = styled.img`
  position: relative;
  width: 60%;
`;
