import Nav from "./Nav";
import styled from "styled-components";
import { Link } from "react-router-dom";
import AoneLogo from "../../resources/images/logo/aone_logo.png";
import { languageStore } from "../../state/language";
import LanguageSelection from "../contents/LanguageSelection";
import UseBreakpoints from "../hooks/ui/UseBreakpoints";
const Header = ({ children }) => {
  const lang = languageStore();
  const breakpoints = UseBreakpoints();
  return (
    <Div>
      <Link to="/" draggable={false}>
        <LogoDiv>
          <LogoImage src={AoneLogo} />
          <SubLogoDiv>
            <SublogoSpan1>관세법인</SublogoSpan1>
            <SublogoSpan2>에이원</SublogoSpan2>
          </SubLogoDiv>
        </LogoDiv>
      </Link>
      <TopRightDiv>
        {
          breakpoints.xl &&
          <LanguageSelection />
        }
      </TopRightDiv>
      <NavigationDiv>
        {breakpoints.xl &&
          <ul>
            <Link to="/careers">
              <li>{lang.language === "EN" ? "Careers" : lang.language === "CN" ? "就业" : lang.language === "JP" ? "採用" : "채용"}</li>
            </Link>
            {/* <a href="https://aone-tcms.com" target="_blank" rel="noreferrer">
              <li>TCMS</li>
            </a> */}
          </ul>}
      </NavigationDiv>
      <Nav />
      {children}
    </Div>
  );
};
export default Header;
const Div = styled.header`
  z-index: 10;
  height: 6rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  transition: background-color 0.5s;
  background-color: #fffe;
  justify-content: center;
  align-items: stretch;
  & a {
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    background-color: #fff;
    transition: background-color 0.5s;
  }
`;
const LogoDiv = styled.div`
  position: absolute;
  z-index: 5;
  display: flex;
  gap: 0.5vw;
  height: 100%;
  left: 3em;
  align-items: center;
`;
const SubLogoDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 1.3rem;
  right: 1.5em;
  gap: 0.2em;
  font-size: 0.6em;
  color: #14237988;
  letter-spacing: -0.05em;
`;
const LogoImage = styled.img`
  height: 4vh;
`;
const TopRightDiv = styled.div`
cursor: pointer;
  z-index: 6;
  position: absolute;
  display: flex;
  right: 2em;
  top: .5em;
`;
const NavigationDiv = styled.div`
  z-index: 6;
  position: absolute;
  display: flex;
  align-items: center;
  height: 1em;
  top: 3rem;
  right: 3em;
  width: 8vw;
  font-size: 0.85em;
  & > ul {
    flex: 1;
    display: flex;
    padding: 0;
    list-style: none;
    & > a {
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }
`;

const SublogoSpan1 = styled.span``;
const SublogoSpan2 = styled.span`
  font-weight: bold;
`;
