export const menucontext = {
  key: "root",
  children: [
    {
      key: "0000",
      url: "#",
      title: "법인소개",
      engtitle: "About us",
      jptitle: "法人紹介",
      children: [
        {
          key: "0001",
          url: "/intro",
          title: "인사말",
          jptitle: "挨拶",
          engtitle: "About us",
          cntitle: "问候"
        },
        {
          key: "0002",
          url: "/history",
          title: "연혁",
          jptitle: "沿革",
          engtitle: "History",
          cntitle: "历史",
        },
        {
          key: "0003",
          url: "/prizes",
          title: "수상",
          jptitle: "受賞",
          engtitle: "Prizes",
          cntitle: '奖项',

        },
        {
          key: "0004",
          url: "/branch",
          title: "Branches & Global",
        },
        {
          key: "0005",
          url: "/esg",
          title: "지속가능한 경영",
          engtitle: "Sustainability",
          jptitle: "持続可能な経営",
          cntitle: '可持续管理',
        },
      ],
    },
    {
      key: "1000",
      url: "#",
      title: "업무분야",
      engtitle: "Services",
      children: [
        {
          key: "1100",
          url: "#",
          title: "통관 및 환급 업무",
          engtitle: "Clearance Service",
          children: [
            {
              key: "1101",
              url: "/impexp",
              title: "수출입통관",
              engtitle: "Import/Export",
            },
            {
              key: "1102",
              url: "/express",
              title: "특송통관",
              engtitle: "Express",
            },
            {
              key: "1103",
              url: "/drawback",
              title: "환급",
              engtitle: "Drawback",
            },
          ],
        },
        {
          key: "1500",
          url: '3',
          title: "요건 및 검역 업무",
          engtitle: "Requirements",
          children: [
            {
              key: "1501",
              url: "/requirement",
              title: "요건",
              engtitle: "Requirements",
            },
            {
              key: "1502",
              url: "/quarantine",
              title: "검역/검사",
              engtitle: "Quarantine",
            },
            {
              key: "1503",
              url: "/cosmetic",
              title: "화장품",
              engtitle: "Cosmetics",
            },
          ],
        },
        {
          key: "1200",
          url: "#",
          title: "컨설팅 업무",
          engtitle: "Consulting",
          children: [
            {
              key: "1201",
              url: "/consulting/fta",
              title: "FTA",
            },
            {
              key: "1202",
              url: "/consulting/aeo",
              title: "AEO",
            },
            {
              key: "1203",
              url: "/consulting/acva",
              title: "ACVA",
            },
            {
              key: "1204",
              url: "/consulting/classification",
              title: "품목분류",
              engtitle: "HS Classification",
            },
            {
              key: "1205",
              url: "/consulting/diagnosis",
              title: "기업진단",
              engtitle: "Diagnosis",
            },
          ],
        },
        {
          key: "1300",
          url: "#",
          title: "법적 조력 업무",
          engtitle: "Legal Supporting",
          children: [
            {
              key: "1301",
              url: "/legal/customs",
              title: "관세심사",
            },
            {
              key: "1302",
              url: "/legal/investigation",
              title: "범칙조사",
            },
            {
              key: "1303",
              url: "/legal/forex",
              title: "외환조사 · 검사 ",
            },
            {
              key: "1304",
              url: "/legal/contestation",
              title: "행정쟁송",
            },
          ],
        },
        // {
        //   key: "1600",
        //   url: "#",
        //   title: "물류",
        //   engtitle: "Logistics",
        //   children: [
        //     {
        //       key: "1601",
        //       url: "/logistics/qnl",
        //       title: "큐앤엘솔루션",
        //     },
        //   ],
        // },
        {
          key: "1400",
          url: "#",
          title: "교육 및 연구활동",
          engtitle: "Education & Research",
          children: [
            {
              key: "1401",
              url: "/education",
              title: "기업 교육",
            },
            {
              key: "1402",
              url: "/research",
              title: "관세무역 연구",
            },
          ],
        },
      ],
    },
    {
      key: "2000",
      url: "#",
      title: "IT시스템",
      engtitle: "IT System",
      children: [
        {
          key: "2001",
          url: "/it/dev",
          title: "IT시스템",
        },
        // {
        //   key: "2002",
        //   url: "/it/newtech",
        //   title: "신기술 도입",
        // },
      ],
    },
    {
      key: "3000",
      url: "#",
      title: "구성원",
      engtitle: "Experts",
      children: [
        {
          key: "3001",
          url: "/organization",
          title: "조직도",
          engtitle: "Organization",
        },
        {
          key: "3002",
          url: "/boardmembers",
          title: "경영진",
        },
        {
          key: "3003",
          url: "/professionals",
          title: "분야별 전문가",
        },
        {
          key: "3004",
          url: "/advisories",
          title: "ADVISORY GROUP",
        },
      ],
    },
    {
      key: "4000",
      url: "#",
      title: "소식자료",
      engtitle: "News",
      children: [
        {
          key: "4001",
          url: "/boards/aonenews",
          title: "에이원 소식",
        },
        {
          key: "4002",
          url: "/boards/board2",
          title: "관세무역 뉴스",
        },
        {
          key: "4003",
          url: "/boards/ceocolumn",
          title: "정운기칼럼",
          engtitle: "CEO Column",
        },
      ],
    },
  ],
};
// export const
