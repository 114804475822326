import UseBreakpoints from "../../components/hooks/ui/UseBreakpoints";
import { fadeIn } from "../../style/styled-components/keyframes/fade";
import { zoomIn } from "../../style/styled-components/keyframes/zoom";
import HomeImage from "../../resources/images/background/main-bg.jpg";
import HomeTab from "../../components/contents/HomeTab";
import styled from "styled-components";
import HomeShortcuts from "../../components/contents/HomeShortcuts";
import { device } from "../../style/styled-components/helpers/device";
import { languageStore } from "../../state/language";
import { Helmet } from "react-helmet";
import HomeOmbudsman from "../../components/contents/HomeOmbudsman";
import { useState } from "react";
import Modal from "../../components/ui/intro/Modal";

const Home = () => {
  const breakpoints = UseBreakpoints();
  const { language } = languageStore();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Div breakpoints={breakpoints}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="관세법인 에이원 홈페이지 메인화면" />
        <meta
          name="keywords"
          content="관세법인, 관세법인에이원, 에이원, 관세, 무역, 수입, 수출, 특송, 전자상거래, 쟁송, 행정쟁송, 환급, 외환, FTA, 협정, 자유무역, 자유무역협정, 수출입, 관세컨설팅, 외환컨설팅, 인보이스"
        />
        <meta name="author" content="관세법인 에이원" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          {`${
            language === "EN"
              ? "AONE Customs and Trade Service"
              : "관세법인 에이원"
          }`}{" "}
        </title>
        <link
          rel="icon"
          type="image/x-icon"
          href="./resources/images/logo/aone_logo_only.ico"
        />
      </Helmet>
      <div>
        <div>
          <div>
            <div>
              <div>
                {language === "EN"
                  ? "AONE Cus. & Trade Service"
                  : language === "JP"
                  ? "関税法人 A-ONE"
                  : "관세법인 에이원"}
              </div>
              <hr />
              <div>
                {language === "EN" ? "CUSTOMS FIRM" : "글로벌 성공 무역 파트너"}
              </div>
            </div>
          </div>
        </div>
        <div>
          {language === "EN"
            ? "WITH EXPERTISE"
            : language === "CN"
            ? "拥有最好的报关行。"
            : language === "JP"
            ? "最高の関税会社と一緒に。"
            : "최고의 전문가들과 함께합니다."}
        </div>
        <div className="shortcuts">
          <HomeShortcuts />
        </div>
        <div /> {/*배경화면 자리*/}
      </div>
      <Modal
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        period={{
          from: new Date("2024-08-26T00:00:00"),
          to: new Date("2024-08-26T23:59:59"),
        }}
      />
      <HomeTab />
      <div className="ombudsman">
        <div>
          <div>
            <HomeOmbudsman />
          </div>
          <div></div>
        </div>
      </div>
    </Div>
  );
};

export default Home;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 50vh;
  width: 100vw;
  /* padding: 0 10rem; */
  & > div:first-child {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 50vh;
    @media ${device.tablet} {
      min-height: 60vh;
    }
    overflow: hidden;

    & > div:first-child {
      flex: 1;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: ${({ breakpoints }) =>
        breakpoints.xl
          ? "5rem 0 0 5rem"
          : breakpoints.lg
          ? "3rem 0 0 3rem"
          : "1.5rem 0 0 1.5rem"};
      & > div:first-child {
        display: flex;
        background-color: #0008;
        flex-direction: column;
        align-items: center;
        padding: 1em;
        opacity: 0;
        animation: ${fadeIn} 1s 1s forwards;
        z-index: 1;
        & > div:first-child {
          & > div:first-child {
            font-size: 4em;
            color: white;
            letter-spacing: -0.09em;
            word-spacing: 0.11em;
          }
          & > hr {
            width: 100%;
          }
          & > div:nth-child(3) {
            // 고객의 성공무역
            font-size: 1.44em;
            font-weight: lighter;
            word-spacing: 0.07em;
            color: ${({ theme }) => theme?.colors?.foreground?.inverted};
          }
          & > div:nth-child(4) {
            font-size: 3em;
            padding: 0 1.5em;
            color: white;
          }
        }
        & > div:nth-child(2) {
          color: ${({ theme }) => theme?.colors?.foreground?.inverted};
          font-size: 1.5em;
        }
      }
      & > div:nth-child(2) {
        font-size: 1.5em;
      }
    }
    & > div:nth-child(2) {
      // 최고의 전문가
      position: absolute;
      right: 0;
      bottom: ${({ breakpoints }) => (breakpoints.sm ? "8rem" : "1rem")};
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 1;
      font-weight: lighter;
      font-size: ${({ breakpoints }) =>
        breakpoints.xl
          ? "3em"
          : breakpoints.lg
          ? "2.5em"
          : breakpoints.sm
          ? "2em"
          : "1.5em"};
      padding: ${({ breakpoints }) => (breakpoints.sm ? "1rem" : "1rem 0rem")};
      color: white;
      text-align: ${({ breakpoints }) => (breakpoints.sm ? "right" : "center")};
      width: 100%;
      z-index: 1;
      opacity: 0;
      animation: ${fadeIn} 1s 1.5s forwards;
    }
    & > div.shortcuts {
      z-index: 2;
      position: absolute;
      opacity: 0;
      animation: ${fadeIn} 1s 2s forwards;
      display: none;
      @media ${device.tablet} {
        display: flex;
      }
      left: calc(50vw - 40%);
      width: 80%;
      @media ${device.laptopL} {
        width: 60%;
        left: calc(50vw - 30%);
      }
      @media ${device.tablet} {
        height: 7rem;
      }
      @media ${device.laptop} {
        height: 8rem;
      }

      bottom: 0;
    }
    & > div:last-child {
      // 배경화면
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
        url(${HomeImage});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center top 42%;
      animation: ${zoomIn} 1.5s 0.3s forwards;
    }
  }
  .ombudsman {
    & > div {
      display: flex;
      flex-direction: ${({ breakpoints }) =>
        breakpoints.lg ? "row" : "column"};
      & > div {
        flex: 1 1 0;
      }
    }
  }
`;
