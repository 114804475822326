import { useState } from "react";
import { useEffect } from "react";
const UseScroll = () => {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });
  const handleScroll = () => {
    const positionX = window.pageXOffset;
    const positionY = window.pageYOffset;
    setScrollPosition({ x: positionX, y: positionY });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return scrollPosition;
};
export default UseScroll;
