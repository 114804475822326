import styled from "styled-components";
import UseBreakpoints from "../../../../hooks/ui/UseBreakpoints";
const BranchSimpleMapCar = ({ keyword, taxi, parking }) => {
  const breakpoints = UseBreakpoints();
  return (
    <Div breakpoints={breakpoints}>
      <div className="title">
        <div>차량 이용 시</div>
        <hr />
      </div>
      <div className="content">
        {keyword && (
          <div className="navi">
            <div className="icon">🚩</div>
            <div className="content">{keyword}</div>
          </div>
        )}
        {taxi && (
          <div>
            <div className="icon">🚕</div>
            <div className="content">{taxi}</div>
          </div>
        )}
        {parking && (
          <div>
            <div className="icon">🅿</div>
            <div className="content">{parking}</div>
          </div>
        )}
      </div>
    </Div>
  );
};
export default BranchSimpleMapCar;

const Div = styled.div`
  padding: 1em;
  & > div {
    &.title {
      font-size: 1.5em;
      font-weight: bold;
      & > div {
        padding: 0 1em;
      }
    }
    &.content {
      display: flex;
      flex-direction: column;
      gap: 1em;
      list-style: none;
      padding: 1rem 2rem;
      & > div {
        display: flex;
        gap: 0.5em;
        & > div.icon {
          min-width: ${({ breakpoints }) => (breakpoints.sm ? "5em" : "3.5em")};
        }
        &.navi {
          & > div.content {
            font-weight: bold;
          }
        }
      }
    }
  }
`;
