import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { device } from "../../../../style/styled-components/helpers/device";
const HistoryChapter = ({ context }) => {
  const res = useMediaQuery({minWidth: 1300});
  const mob = useMediaQuery({minWidth: 780});
  return (
    <Div res={res}>
      <CategoryTitle>
        <TitleDiv>{context.title}</TitleDiv>
        <RangeDiv>{context.range}</RangeDiv>
      </CategoryTitle>
      <ContentDiv>
        {context.years?.map((x) => (
          <YearDiv key={x.year} mob={mob}>
            <YearSpan>{x.year}</YearSpan>
            <ActionDiv>
              {x.actions?.map((y) => (
                <span key={y}>{y}</span>
              ))}
            </ActionDiv>
          </YearDiv>
        ))}
      </ContentDiv>
    </Div>
  );
};
export default HistoryChapter;
const Div = styled.div`
  display: flex;
  flex-direction: ${props => props.res ? "row" : "column"};
  background-color: #eee;
  padding: 1em;
  @media ${device.tablet} {
  padding: 3em;
  }
  border-radius: 1em;
`;
const CategoryTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 20em;
  padding: 2em 0;
`;
const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;
const TitleDiv = styled.div`
  font-size: 1.5em;
`;
const RangeDiv = styled.div`
  font-size: 1.5em;
  color: #888;
`;
const YearDiv = styled.div`
  display: flex;
  flex-direction: ${props => props.mob ? "row" : "column"};
  gap:  ${props => props.mob ? "3em" : "1em"};
`;
const ActionDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const YearSpan = styled.span`
  color: #66a;
  font-weight: 600;
  letter-spacing: -0.03em;
`;
