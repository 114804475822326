const AoneOrganizationPC = () => {
  return (
    <>
      <div className="level-1">
        <div className="org org-1">대표</div>
      </div>
      <div className="direct-container">
        <div className="direct">
          <div className="direct-l-container">
            <div className="direct-l">
              <div>
                <div className="org org-l direct-org-2">전문위원실</div>
              </div>
              <div>
                <div className="org org-l direct-org-2">현대관세무역연구원</div>
              </div>
            </div>
          </div>
          <div className="direct-r-container">
            <div className="direct-r">
              <div>
                <div className="org org-r direct-org-2">경영기획실</div>
              </div>
              <div>
                <div className="org org-r direct-org-2">
                  IT운영센터(Tech Lab)
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="level-2">
          <div className="org org-2">수도권총괄본부</div>
          <div className="org org-3">통관1팀</div>
          <div className="org org-3">통관2팀</div>
          <div className="org org-3">혁신전략팀</div>
          <div className="org org-3">인증요건팀</div>
          <div className="org org-3">업무지원팀</div>
        </div>
        <div className="level-2">
          <div className="org org-2">부산지점</div>
          <div className="org org-3">통관1팀</div>
          <div className="org org-3">통관2팀</div>
          <div className="org org-3">검역팀</div>
          <div className="org org-3">전략검역팀</div>
          <div className="org org-3">검역컨설팅팀</div>
          <div className="org org-3">검역통관팀</div>
          <div className="org org-3">CS팀</div>
          <div className="org org-3">업무지원팀</div>
        </div>
        <div className="level-2">
          <div className="org org-2">인천공항지점</div>
          <div className="org org-3">일반통관팀</div>
          <div className="org org-3">검역통관팀</div>
          <div className="org org-3">업무지원팀</div>
        </div>
        <div className="level-2">
          <div className="org org-2">특송총괄본부</div>
          <div className="org org-3">특송통관1팀</div>
          <div className="org org-3">특송통관2팀</div>
          <div className="org org-3">특송업무지원팀</div>
        </div>
        <div className="level-2">
          <div className="org org-2">컨설팅총괄본부</div>
          <div className="org org-3">컨설팅1팀</div>
          <div className="org org-3">컨설팅2팀</div>
        </div>
        <div className="level-2">
          <div className="org org-2">평택지점</div>
          <div className="org org-2">울산지점</div>
        </div>
      </div>
    </>
  );
};
export default AoneOrganizationPC;
