import styled from "styled-components";
const ValueImprovementSub = ({ number, title, text }) => {
  return (
    <Div>
      <div>
        <div>{number}</div>
        <div>{title}</div>
      </div>
      <hr />
      <div>{text}</div>
    </Div>
  );
};
export default ValueImprovementSub;
const Div = styled.div`
  box-shadow: 1rem 1rem 3rem #c7c7c7, -1rem -1rem 3rem #ffffff;
  border-radius: 3rem;
  padding: 2rem;
  & > div:first-child {
    display: flex;
    gap: 0.6em;
    align-items: flex-start;
    line-height: 3em;
    & > div:first-child {
      font-size: 1.8em;
      font-weight: bold;
    }
    & > div:nth-child(2) {
      font-size: 1.58em;
      font-weight: bold;
    }
  }
  & > div:nth-child(3) {
    font-size: 1.3em;
    line-height: 2.5em;
  }
`;
