import { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import UseBreakpoints from "../../hooks/ui/UseBreakpoints";
import GroupSelectOption from "./GroupSelectOption";
import NameCard from "./NameCard";
import { getMemberbyId } from "../../../context/members/membercontext";
const MemberListTemplate = ({ groups }) => {
  // const cardVariants = {
  //   offscreen: {
  //     y: 40,
  //     opacity: 0,
  //   },
  //   onscreen: {
  //     y: 0,
  //     opacity: 1,
  //     transition: {
  //       type: "spring",
  //       bounce: 0.6,
  //       duration: 1,
  //       damping: 20,
  //     },
  //   },
  // };
  const [selectedmembers, setSelectedMembers] = useState(groups[0].members);
  const [selected, setSelected] = useState(1);
  const breakpoints = UseBreakpoints();
  return (
    <ContentsDiv breakpoints={breakpoints}>
      <GroupSelect breakpoints={breakpoints}>
        {groups?.map(({ category, members }, idx) => (
          <GroupSelectOption
            title={category}
            contents={members}
            setContents={setSelectedMembers}
            setSelected={setSelected}
            key={idx + 1}
            value={idx + 1}
            selected={selected}
          />
        ))}
      </GroupSelect>
      <NameCardsDiv breakpoints={breakpoints}>
        {/* <motion.ul
            className="container"
            variants={container}
            initial="hidden"
            animate="visible"
          > */}
        {selectedmembers?.map((x, idx) => (
          <motion.div
            key={idx}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
            // variants={cardVariants}
          >
            <NameCard key={x} member={getMemberbyId(x)} />
          </motion.div>
        ))}
        {/* </motion.ul> */}
      </NameCardsDiv>
    </ContentsDiv>
  );
};
export default MemberListTemplate;
const ContentsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const GroupSelect = styled.div`
  position: sticky;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #eee;
  padding: 1rem;
  border-radius: 2rem;
  margin: 2rem 0 6rem 0;
  & > * {
    cursor: pointer;
  }
`;

const NameCardsDiv = styled.div`
  flex: 1;
  display: ${({ breakpoints }) => (breakpoints?.lg ? "grid" : "flex")};
  grid-template-columns: ${({ breakpoints }) =>
    breakpoints?.lg ? "1fr 1fr" : "null"};
  flex-direction: ${({ breakpoints }) => (breakpoints?.lg ? "null" : "column")};
  justify-content: ${({ breakpoints }) =>
    breakpoints?.lg ? "center" : "null"};
  gap: ${({ breakpoints }) => (breakpoints?.sm ? "3rem" : "1rem")};
  min-height: 60vh;
  list-style: none;
`;
