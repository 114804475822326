import styled from "styled-components";
import UseBreakpoints from "../../hooks/ui/UseBreakpoints";
const TriangleScope = ({ contents }) => {
  const breakpoints = UseBreakpoints();
  return (
    <StrengthDiv>
      {contents && (
        <>
          <div>
            <StrengthTextDiv breakpoints={breakpoints}>
              <span>{contents[0].title}</span>
              <span>{contents[0].text}</span>
            </StrengthTextDiv>
          </div>
          <StrengthTriangleDiv breakpoints={breakpoints}>
            <div />
            <div />
            <div />
          </StrengthTriangleDiv>
          <StrengthHorizontalDiv>
            <StrengthTextDiv breakpoints={breakpoints}>
              <div>
                <span>{contents[1].title}</span>
                <span>{contents[1].text}</span>
              </div>
            </StrengthTextDiv>
            <StrengthTextDiv breakpoints={breakpoints}>
              <div>
                <span>{contents[2].title}</span>
                <span>{contents[2].text}</span>
              </div>
            </StrengthTextDiv>
          </StrengthHorizontalDiv>
        </>
      )}
    </StrengthDiv>
  );
};
export default TriangleScope;
const StrengthDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme?.colors?.monotone?.extralight};
  padding: 2em;
  border-radius: 1em;
  & > div:first-child {
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;
const StrengthHorizontalDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  & > div:first-child,
  & > div:last-child {
    flex: 1;
    flex-direction: row;
    display: flex;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  & > div:first-child,
  & > div:last-child {
    position: relative;
  }
  & > div:first-child {
    justify-content: right;
  }
  & > div:last-child {
    justify-content: left;
  }
`;
const StrengthTriangleDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  top: ${({ breakpoints }) =>
    breakpoints.xl ? "7em" : breakpoints.md ? "4.2em" : "2.8em"};
  height: ${({ breakpoints }) =>
    breakpoints.xl ? "18em" : breakpoints.md ? "10.8em" : "7.2em"};
  & > div {
    border-top: ${({ breakpoints }) =>
        breakpoints.xl ? "5em" : breakpoints.md ? "3em" : "2em"}
      solid transparent;
    border-left: ${({ breakpoints }) =>
        breakpoints.xl ? "8.66em" : breakpoints.md ? "5.196em" : "3.464em"}
      solid transparent;
    border-right: ${({ breakpoints }) =>
        breakpoints.xl ? "8.66em" : breakpoints.md ? "5.196em" : "3.464em"}
      solid transparent;
    border-bottom: ${({ breakpoints }) =>
        breakpoints.xl ? "5em" : breakpoints.md ? "3em" : "2em"}
      solid #123;
    &:first-child {
      position: absolute;
      border-bottom: ${({ breakpoints }) =>
          breakpoints.xl ? "5em" : breakpoints.md ? "3em" : "2em"}
        solid #123;
    }
    &:nth-child(2) {
      position: absolute;
      border-bottom: ${({ breakpoints }) =>
          breakpoints.xl ? "5em" : breakpoints.md ? "3em" : "2em"}
        solid #345;
      transform: rotate(120deg);
    }
    &:last-child {
      position: absolute;
      border-bottom: ${({ breakpoints }) =>
          breakpoints.xl ? "5em" : breakpoints.md ? "3em" : "2em"}
        solid #567;
      transform: rotate(240deg);
    }
  }
`;
const StrengthTextDiv = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  text-align: center;
  & > div {
    flex: 1;
  }
  /* max-width: 12em; */
  & span:first-child {
    font-size: ${({ breakpoints }) => (breakpoints.xl ? "1.7em" : "1.2em")};
    font-weight: bold;
  }
  & span:nth-child(2) {
    font-size: ${({ breakpoints }) => (breakpoints.xl ? ".9em" : "0.8em")};
    color: ${({ theme }) => theme.colors.foreground.dimmed};
    max-width: 11em;
  }
`;
