import { Link } from "react-router-dom";
import styled from "styled-components";
import { hamburgerStore } from "../../state/ui/hamburger_state";
import { fadeIn } from "../../style/styled-components/keyframes/fade";
import { menucontext } from "../../context/nav/menucontext";
import { useState } from "react";
import { languageStore } from "../../state/language";
import LanguageSelection from "../contents/LanguageSelection";
const Hamburger = () => {
  const [selectedKey, setSelectedKey] = useState("");
  const [selectedSubKey, setSelectedSubKey] = useState("");
  // const navigate = useNavigate();
  const hamburger = hamburgerStore();
  const { language } = languageStore();
  return (
    <Div>
      <TopBar>
        <TopBarHeader>
          <div>
            <LanguageSelection />
          </div>
          <div>
            <ul>
              <Link to="/careers">
                <li>{language === "EN" ? "Careers" : "채용"}</li>
              </Link>
              <a href="https://aone-tcms.com" target="_blank" rel="noreferrer">
                <li>TCMS</li>
              </a>
            </ul>
          </div>
        </TopBarHeader>
      </TopBar>
      <ul>
        {menucontext?.children?.map(({ url, title, engtitle, cntitle, jptitle, children, key }, idx) => (
          <HamburgerSub key={idx} selected={selectedKey === key}>
            <div
              onClick={() => {
                setSelectedKey((prevState) =>
                  prevState && prevState === key ? "" : key
                );
                setSelectedSubKey((prevState) => "");
              }
              }
            >
              <div className="titleDiv">
                <span className="title">{language === "EN" && engtitle ? engtitle : language === "CN" && cntitle ? cntitle : language === "JP" && jptitle ? jptitle : title}</span>{" "}
                <span>{key === selectedKey ? "▴" : "▾"}</span>
              </div>
            </div>
            <ul>
              {children
                ?.filter(() => key === selectedKey)
                .map((y, idx2) => {
                  return y.children
                    ? selectedKey &&

                    (<li key={idx2} onClick={() => {
                      setSelectedSubKey((prevState) => prevState && prevState === y.key ? "" : y.key);
                    }} selected={selectedSubKey === y.key}><div className="titleDiv">
                        <span className="title">{y.title}</span>{" "}
                        <span>{y.key === selectedSubKey ? "▴" : "▾"}</span>
                      </div><ul>
                        {
                          selectedSubKey === y.key && y.children?.map((z, idx3) => {
                            return (
                              <li key={idx3}><Link onClick={() => hamburger.close()}
                                to={z.url}
                                key={idx3}>{z.title}</Link></li>
                            )
                          })
                        }
                      </ul></li>)

                    : selectedKey && (
                      <Link
                        onClick={() => hamburger.close()}
                        to={y.url}
                        key={idx2}
                      >
                        {y.title}
                      </Link>
                    );
                })}
            </ul>
            <hr />
          </HamburgerSub>
        ))}
      </ul>
    </Div>
  );
};
export default Hamburger;
const Div = styled.div`
  position: absolute;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: white;
  animation: ${fadeIn} 0.7s;
  & ul {
    padding: 0;
    margin: 0;
  }
  & li {
  list-style-type: none;

  }
`;

const TopBar = styled.div`
  display: flex;
  width: 100vw;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dark};
  height: 3rem;
`;
const TopBarHeader = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.foreground.inverted};
  & > div {
    padding: 1rem;
    display: flex;
     & > ul {
      display: flex;
      gap: 1rem;
      padding: 0;
      margin: 0;
      & > li {
        padding: 0;
        margin: 0;
      }
     }
  }
`;
const HamburgerSub = styled.li`
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  & > div {
    position: relative;
    display: flex;
    justify-content: center;
    /* justify-content: ${({ selected }) => selected ? "flex-start" : "center"}; */
    padding: 0.5rem;
    font-size: 1.5em;
    min-height: 1.5em;
    & > div.titleDiv {
      position: absolute;
      transform: translateX(${({ selected }) => selected ? "calc(-50vw + 3em)" : "null"});
      transition: all .2s ease-out;
      & > span {
        color: ${({ theme }) => theme?.colors?.monotone?.dark};
      }
    }
  }
  & a {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    font-size: 0.9em;
  }
  & > ul {
    padding: 0;
    overflow-y: scroll;
    & > li {
      & > div.titleDiv {
        padding: 0.5rem;
        font-size: .9em;
      /* position: absolute;
      transform: translateX(${({ selected }) => selected ? "calc(-50vw + 3em)" : "null"});
      transition: all .2s ease-out; */
      & > span {
        color: ${({ theme }) => theme?.colors?.monotone?.dark};
      }
    }
    }
  }
  & > hr {
    width: 100%;
  }
  & div:hover {
    background-color: ${({ selected, theme }) =>
    selected ? theme.colors.background.extralight : "inherit"};
  }
`;
