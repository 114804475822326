import styled from "styled-components";
import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import HeadedBox from "../../../components/ui/common/template/HeadedBox";
import CenterDiv from "../../../style/styled-components/components/CenterDiv";
import TitlePill from "../../../components/ui/common/template/TitlePill";
import Image from "../../../resources/images/investigation.jpg";
import ContactPoints from "../../../components/ui/members/ContactPoints";
import TriangleScope from "../../../components/ui/specialty/TriangleScope";
const InvestigationPage = () => {
  return (
    <PageTemplate
      large
      title="범칙조사"
      phrase="객관적인 사안의 분석과 합리적이고 전문적인 대응방안을 제시해드립니다."
      image={Image}
    >
      <p>
        관세포탈, 부정감면, 부정환급에 대한 범칙사건의 경우 관세전문지식을 갖춘
        관세사가 조사에 입회하거나 의견을 진술할 수 있습니다. 관련 법령, 실무에
        대한 심도있는 이해를 갖춘 관세사가 해당 사안을 분석하고 기업 리스크
        최소화를 위한 의견을 제공드립니다
      </p>
      <TitleTemplate title="범칙조사" noImage />
      <WhatIsDiv>
        <div>
          <div>범칙조사란?</div>
          <div>
            세관공무원이 범칙행위에 대하여 범인을 검거하고 범죄사실 및 증거를
            확인·확보·보전하기 위하여 수행하는 일련의 조사활동을 말하며, 법인
            심사에서 범칙행위에 관한 단서가 있는 경우 범칙조사로 전환될 수
            있습니다.
          </div>
        </div>
        <div></div>
      </WhatIsDiv>
      <CenterDiv>
        <TitlePill title="범칙조사 유형" />
        <div>
          <HeadedBox
            div="관세법 위반"
            text="금지품 수출입, 밀수입, 밀수출, 관세포탈, 부정감면, 부정환급, 부정수출입, 밀수품취득 등"
          />
          <HeadedBox
            div="대외무역법 위반"
            text="원산지표시 위반, 수출입가격 조작, 전략물자 불법수출입 등"
          />
          <HeadedBox
            div="외국환거래법 위반"
            text="상계, 제3자지급, 외국환은행 통하지 아니한 지급, 불법휴대반출, 수입대금 선지급, 기간초과지급, 관세탈루·포탈, 해외불법투자, 재산국외도피, 불법금전대차, 불법현지금융 등"
          />
          <HeadedBox
            div="지식재산권 위반"
            text="상표권 침해, 밀수입, 밀수출, 관세포탈, 부정감면 등"
          />
        </div>
      </CenterDiv>
      <TitleTemplate
        title="Strength"
        noImage
        subHeader="고객의 관세업무 리스크 통제를 위하여 관세 분야별 전문가 그룹을 통한 컨설팅을 수행합니다.."
      />
      <TriangleScope
        contents={[
          {
            title: "세관 대응능력",
            text: "세관 업무에 대한 높은 이해와 다년간 세관심사에 대응한 경험을 통한 안정적인 세관 대응 제공",
          },
          {
            title: "MAN-POWER",
            text: "세관에서 경력을 쌓은 전문가, 업계 최고 관세사로 구성된 심사 전문 컨설팅 그룹",
          },
          {
            title: "관세 자문단 보유",
            text: "관세청 관세평가분류원장, 중앙관세분석소장 등 역임한 국내 최고 관세분야별 전문인력 보유",
          },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["sykim0608"]} />
    </PageTemplate>
  );
};
export default InvestigationPage;
const WhatIsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  & > div {
    display: flex;
    & > div:first-child {
      font-size: 2.5em;
      font-weight: bold;
      color: #125;
      min-width: 7em;
    }
    & > div:nth-child(2) {
      flex: 1;
      font-size: 1.5em;
      line-height: 2em;

      & > ol {
        padding: 0;
        margin: 0;
        & > li {
          font-size: 1em;
          font-weight: bold;
          line-height: 3em;
        }
        & > li:not(:first-child) {
        }
        & > ul > li {
        }
      }
    }
  }
`;
