import { Fragment, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { menucontext } from "../../../context/nav/menucontext";
import { getBreadcrumbs } from "../../../helpers/navigation/breadcrumbs";
import { getSiblings } from "../../../helpers/object/depthof";
import Select from "react-select";
import { useEffect } from "react";
import UseBreakpoints from "../../hooks/ui/UseBreakpoints";
const Breadcrumbs = () => {
  const location = useLocation();
  const [selectedMenuKey, setSelectedMenuKey] = useState("");
  const navigate = useNavigate();
  const breakpoints = UseBreakpoints();
  const breadcrumbs = useMemo(
    () => getBreadcrumbs(location.pathname, "url", menucontext),
    [location.pathname]
  );
  const siblings = useMemo(
    () => breadcrumbs ?
      Array.from({ length: breadcrumbs.length }, (x, i) => i).map((x, idx) => {
        return getSiblings(menucontext, x, breadcrumbs);
      }) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [breadcrumbs]
  );
  const options = useMemo(
    () =>
      siblings?.map((x) =>
        x.map(({ key, title }) => {
          return { value: key, label: title };
        })
      ),
    [siblings]
  );
  const currentOption = useMemo(
    () =>
      breadcrumbs?.reverse().map(({ key, title }) => {
        return { value: key, label: title };
      }),
    [breadcrumbs]
  );
  useEffect(() => {
    if (selectedMenuKey) {
      navigate(
        siblings[breadcrumbs.length - 1]?.find((x) => x.key === selectedMenuKey)
          ?.url
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenuKey]);
  return (
    <Div breakpoints={breakpoints} length={siblings?.length}>
      <div>
        {siblings && Array.from({ length: breadcrumbs.length }, (x, i) => i).map(
          (x, idx) => {
            return (
              <Fragment key={idx}>
                {x === breadcrumbs.length - 1 ? (
                  <div>
                    <Select
                      options={options[x]}
                      defaultValue={currentOption[x]}
                      isDisabled={x !== breadcrumbs.length - 1}
                      onChange={(value) => setSelectedMenuKey(value.value)}
                    />
                  </div>
                ) : (
                  <>{breakpoints.sm && <div>{currentOption[x].label}</div>}</>
                )}
              </Fragment>
            );
          }
        )}
      </div>
    </Div>
  );
};

export default Breadcrumbs;
const Div = styled.div`
  position: relative;
  width: 100%;
  & > div {
    width: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.monotone.extralight};
    border-radius: 0 0 .5rem .5rem;
    border-left: 1px ${({ theme, length }) => length && length > 0 ? theme.colors.monotone.light : "inherit"} solid;
    border-right: 1px ${({ theme, length }) => length && length > 0 ? theme.colors.monotone.light : "inherit"} solid;
    border-bottom: 1px ${({ theme, length }) => length && length > 0 ? theme.colors.monotone.light : "inherit"} solid;
    color: ${({ theme }) => theme?.colors?.foreground.dimmed};
    & > div {
      display: flex;
      justify-content: space-between;
      padding: ${({ breakpoints }) => (breakpoints.sm ? "1rem" : "0.5rem")};
      background-color: ${({ theme }) => theme.colors.none};
    padding: 1rem 1rem;
      &:not(:last-child):after {
        content: "/";
        display: inline-block;
        position: relative;
        right: -1em;
      }
      &:last-child {
        display: flex;
        width: 10rem;
        & > div {
          flex: 1;
        }
      }
    }
  }
`;
