import styled from "styled-components";
const TASProcess = () => {
  return (
    <Div>
      <div>
        <div className="box colored">권리보호제도</div>
        <div />
      </div>
      <div>
        <div>
          <SideLine />
          <div className="box colored">사전구제제도</div>
          <SideLine />
        </div>
        <div>
          <SideLine />
          <div className="box colored">사후구제제도</div>
          <SideLine />
        </div>
      </div>
      <div>
        <div>
          <SideLine />
          <div className="box">과세전 적부심사</div>
        </div>
        <div>
          <SideLine />
          <div className="box">이의신청</div>
          <SideLine />
        </div>
        <div>원하는 경우에만 신청(생략가능)</div>
      </div>
      <div>
        <div className="box small">심사청구(관세청장)</div>
        <VerticalLine />
        <div>
          <SideLine />
          <div className="box small">심사청구(감사원장)</div>
          <SideLine />
        </div>
        <VerticalLine />
        <div className="box small">심사청구(조세심판원장)</div>
        <div>中 택1</div>
      </div>
      <div>
        <div>
          <SideLine />
          <div className="box">행정소송</div>
        </div>

        <div>
          행정심판 전치주의에 따라
          <br />
          심사청구, 심판청구를 거쳐야 함
        </div>
      </div>
    </Div>
  );
};
export default TASProcess;
const Div = styled.div`
  padding: 2em 2em;
  position: relative;
  display: flex;
  justify-content: center;
  background-image: radial-gradient(#9bd 10%, transparent 20%),
    radial-gradient(#9bd 10%, transparent 20%);
  background-color: transparent;
  background-position: 0 0, 3px 3px;
  background-size: 6px 6px;
  opacity: 1;
  & div.box {
    display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem .5rem;
  font-size: 1.2em;
  font-weight: bold;
  width: 10rem;
  background-color: white;
  height: 2rem;
 &.colored {
  background-color: ${({ theme }) => theme.colors?.dark};
  color: ${({ theme }) => theme.colors?.foreground?.inverted};
  height: 2rem;
 }
 &.small {
  font-size: 1.1em;
 }
  }
  & > div {
    &:first-child {
        position: relative;
        bottom: 2.54rem;
      display: flex;
      align-items: center;
      & > div:nth-child(2) {
        position: relative;
        width: 1.2em;
        border-bottom: solid 1px ${({ theme }) => theme.colors?.dark};
        &:after {
          position: absolute;
          display: block;
          content: "";
          height: 8.6rem;
          width: 1px;
          left: 1.2rem;
          top: -4.78rem;
          background-color: ${({ theme }) => theme.colors?.dark};
        }
      }
    }
    &:nth-child(2),
    &:nth-child(3) {
      display: flex;
      flex-direction: column;
      gap: 5em;
      & > div {
        display: flex;
        align-items: center;
        &:nth-child(3) {
          position: relative;
          font-size: 0.8em;
          bottom: 4rem;
          left: 3rem;
        }
      }
    }
    &:nth-child(4) {
      position: relative;
      top: 3.8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      & > div:nth-child(3) {
        display: flex;
        align-items: center;
      }
      & > div:nth-child(6) {
        position: relative;
        font-size: 0.8em;
        top: 1rem;
      }
    }
    &:last-child {
      position: relative;
      top: 8.6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      & > div:first-child {
        display: flex;
        align-items: center;
      }
      & > div:nth-child(2) {
        position: relative;
        font-size: 0.8em;
        top: 1rem;
      }
    }
  }
`;

const SideLine = styled.div`
  width: 1.2em;
  border-bottom: solid 1px ${({ theme }) => theme.colors?.dark};
`;
const VerticalLine = styled.div`
  height: 1.2em;
  border-right: solid 1px ${({ theme }) => theme.colors?.dark};
`;
