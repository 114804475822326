import { Fragment, useState } from "react";
import UseBreakpoints from "../../../components/hooks/ui/UseBreakpoints";
import PinOnMap from "../../../components/ui/common/PinOnMap";
import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import Branches from "../../../components/ui/intro/branch/Branches";
import { branchcontext } from "../../../context/intro/branch/branchcontext";
import branchImage from "../../../resources/images/background/intro/branch.png";
import Korea from "../../../resources/images/maps/domestic/southkorea_new2.png";
import styled from "styled-components";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import CenterDiv from "../../../style/styled-components/components/CenterDiv";
const BranchPage = () => {
  const breakpoints = UseBreakpoints();
  const [selectedBranch, setSelectedBranch] = useState("서울");
  return (
    <PageTemplate
      title="Branch &amp; Global Network"
      image={branchImage}
      phrase="관세법인의 새로운 역사를 쓰다."
      large aone
    >
      <CenterDiv>

      <Div>
        {breakpoints.lg && (
          <MapDiv>
            <LocalMapDiv breakpoints={breakpoints}>
              <TitleTemplate title="전국 지점현황" noImage />
              <div>
                <MapImage src={Korea} breakpoints={breakpoints} />
                {branchcontext
                  .filter((x) => x.pinOnMap)
                  .map((branch) => {
                    const { x = 0, y = 0, direction = "" } = branch?.pinOnMap;
                    return (
                      <Fragment key={branch.location}>
                        {branch.pinOnMap && (
                          <PinOnMap
                            title={branch.location}
                            x={x}
                            y={y}
                            direction={direction}
                            setSelectedBranch={setSelectedBranch}
                          />
                        )}
                      </Fragment>
                    );
                  })}
              </div>
            </LocalMapDiv>
          </MapDiv>
        )}
        <Branches
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
        />
      </Div>
      </CenterDiv>
    </PageTemplate>
  );
};
export default BranchPage;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
  gap: 5rem;
`;
const MapDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const LocalMapDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 5rem 0;
  width: ${({ breakpoints }) =>
    breakpoints.xxl ? "40%" : breakpoints.lg ? "65%" : "90%"};
  & > div {
    flex: 1;
    display: flex;
    position: relative;
  }
`;
const MapImage = styled.img`
  flex: 1;
  position: relative;
  border-radius: 8rem;
  box-shadow: 2rem 2rem 2rem 0.1rem gray;
  /* width: ${({ breakpoints }) =>
    breakpoints.xl ? "60%" : breakpoints.lg ? "80%" : "90%"}; */
    width: 100%;
`;
