import styled from "styled-components";
import UseBreakpoints from "../../../hooks/ui/UseBreakpoints";
import ValueImprovementSub from "./ValueImprovementSub";
const ValueImprovement = () => {
  const breakpoints = UseBreakpoints();
  return (
    <Div breakpoints={breakpoints}>
      <ValueImprovementSub
        number="01"
        title="일과 삶의 균형"
        text="재량근무제, 시차출퇴근제,
        선택근무제 등 유연근무제를 실시하여 임직원의 일과 삶의 균형을 통해
        창의적인 업무환경을 조성합니다."
      />
      <ValueImprovementSub
        number="02"
        title="공정한 평가와 보상"
        text="회사 매출
        기여도에 따라 인센티브 지급, 직무별 특성을 반영한 역량 평가체계 등을
        통해 성과에 따른 차등 연봉제를 실시하여 임직원에게 공정한 평가와 보상을
        제공합니다."
      />
      <ValueImprovementSub
        number="03"
        title="임직원 역량 강화"
        text="경력개발을 위한 직무전문가 양성
        프로그램 운영, 리더십 및 성과관리 역량 향상을 위한 기본역량교육 등을
        제공하여 임직원이 끊임없이 역량을 강화할 수 있도록 합니다."
      />
    </Div>
  );
};
export default ValueImprovement;
const Div = styled.div`
display: flex;
flex-direction: ${({breakpoints}) => breakpoints?.lg ? "row" : "column"};
justify-content: center;
gap: 5em;
margin: 0 0 10rem 0;
& > div {
    flex: 1;
}
`;
