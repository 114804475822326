import { useEffect } from "react";
import styled from "styled-components";
import { device } from "../../../style/styled-components/helpers/device";
import { Link } from "react-router-dom";

const Modal = ({ onClose, isOpen, period: { from, to } }) => {
  const now = new Date();
  const isInDateRange = now >= from && now <= to;

  useEffect(() => {
    if (isOpen && isInDateRange) {
      document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [isOpen, isInDateRange]);

  if (!isOpen || !isInDateRange) return null;

  return (
    <ModalContainer onClick={onClose}>
      <div>
        <button onClick={onClose}>✕</button>
        <Link to="/boards/aonenews/7">
          <div>
            <h1>자본감소공고</h1>
            <p className="description">
              {`당 법인은 2024년 7월 23일 사원총회에서 자본금의 총액 금201,500,000원을 금170,165,000원으로 감소할 것을 결의하였습니다. 이 자본감소에 이의가 있는 채권자는 2024년 8월 26일까지 이의를 제출하시기 바랍니다.`}
            </p>

            <p className="date">2024년 7월 25일</p>

            <p className="writer">
              {`관세법인 에이원\n서울특별시 강남구 언주로 703, 3층 (논현동)\n\n대표이사 정운기`}
            </p>
          </div>
        </Link>
      </div>
    </ModalContainer>
  );
};

export default Modal;

const ModalContainer = styled.div`
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000084;

  & > div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px 5px #0008;
    border: 1px solid black;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
    animation: fadeInUp 0.5s;
    width: 80%;

    @media ${device.mobileS} {
      width: 300px;
    }
    @media ${device.mobileM} {
      width: 350px;
    }

    @media ${device.mobileL} {
      width: 400px;
    }

    @media ${device.tablet} {
      width: 500px;
    }
  }

  & > div > button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }

  & > div > a {
    display: flex;
    justify-content: center;
  }

  white-space: pre-wrap;
  text-align: center;

  .description {
    font-size: 0.8rem;
    line-height: 2;
    text-align: justify;
    margin: 3rem 0;
    padding: 0 20px;

    @media ${device.mobileM} {
      font-size: 1em;
    }

    @media ${device.tablet} {
      font-size: 1.3em;
    }
  }

  h1 {
    font-size: 1.5rem;
    margin: 1rem 0;
    @media ${device.mobileM} {
      font-size: 1.8em;
    }

    @media ${device.tablet} {
      font-size: 2.2em;
    }
  }

  .date {
    font-size: 0.8rem;
    @media ${device.mobileM} {
      font-size: 0.9rem;
    }

    @media ${device.tablet} {
      font-size: 1.2em;
    }
  }

  .writer {
    font-size: 0.8rem;
    font-weight: 500;
    @media ${device.mobileM} {
      font-size: 0.9rem;
    }

    @media ${device.tablet} {
      font-size: 1.2em;
    }
  }
`;
