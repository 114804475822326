import ContactPoints from "../../../components/ui/members/ContactPoints";
import ContestationImage from "../../../resources/images/contestation.jpg";
import DiamondScope from "../../../components/ui/specialty/DiamondScope";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import TASProcess from "../../../components/contents/legal/contestation/TASProcess";
import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import UseBreakpoints from "../../../components/hooks/ui/UseBreakpoints";
import WorkScopes from "../../../components/ui/specialty/WorkScopes";
import img1 from "../../../resources/images/icon/12. 행정쟁송 대리/1. 과세전 적부심사대리.png";
import img2 from "../../../resources/images/icon/12. 행정쟁송 대리/2. 이의신청대리.png";
import img3 from "../../../resources/images/icon/12. 행정쟁송 대리/3. 심사청구대리, 심판청구대리.png";
import img4 from "../../../resources/images/icon/12. 행정쟁송 대리/4. 행정쟁송 자료 준비지원.png";
import img5 from "../../../resources/images/icon/12. 행정쟁송 대리/5. 행정소송 수행자문.png";
import img6 from "../../../resources/images/icon/12. 행정쟁송 대리/6. 행정쟁송 신청대리.png";
import styled from "styled-components";
const ContestationPage = () => {
  const breakpoints = UseBreakpoints();
  return (
    <PageTemplate
      large
      image={ContestationImage}
      title="행정쟁송 대리"
      phrase="최고의 전문가가 책임감 있는 심사 대리 서비스를 제공합니다."
    >
      <p>
        행정쟁송은 세관의 위법·부당한 행정처분이나 그 밖의 필요한 처분을 받지
        못하여 권리 또는 이익을 침해 당한 경우, 행정쟁송을 통해 정당한
        권리구제를 요구하는 제도에 해당하며, 세관에서 경력을 쌓은 최고 전문가,
        업계 최고의 전문관세사들로 구성된 심사 전문 그룹이 전문성을 바탕으로
        책임감 있는 행정 쟁송 서비스를 제공합니다.
      </p>
      <TitleTemplate
        title="납세자 권리보호 제도"
        noImage
        subHeader="권리보호의 시기, 단계에 따라 선택할 수 있는 여러 절차가 마련되어 있습니다."
      />
      <TASProcess />
      <TitleTemplate
        title="업무수행전략"
        noImage
        subHeader="체계화된 업무 수행 전략으로 납세자 권리수호를 위하여 최고의 결과를 도출합니다."
      />
      <ContestationStrategyChart breakpoints={breakpoints}>
        <div>
          <div>
            <div>사전준비/청구</div>
            <div />
          </div>
          <div>
            <div>논리의 구성</div>
            <div>논리의 문서화</div>
            <div>심판청구 접수</div>
            <div>사건 배정</div>
          </div>
        </div>
        <div>
          <div>
            <div>심의 및 결정</div>
            <div />
          </div>
          <div>
            <div>처분 논리 분석</div>
            <div>사건 조사 지원</div>
            <div>의견진술 준비</div>
            <div>심판관회의 참석</div>
          </div>
        </div>
        <div>
          <div>
            <div>사후관리</div>
            <div />
          </div>
          <div>
            <div>결정내용 확인</div>
            <div>결정내용 진행 조력</div>
            <div>기각결정시 항소</div>
          </div>
        </div>
      </ContestationStrategyChart>
      <TitleTemplate
        title="업무범위"
        noImage
        subHeader="각 기업별로 발생할 수 있는 법률적 Risk를 진단하고, 관리방안을 제시합니다."
      />
      <WorkScopes
        contents={[
          { title: "과세전 적부심사 대리", icon: img1 },
          { title: "이의신청 대리", icon: img2 },
          { title: "심사청구(관세청, 감사원) 대리", icon: img3 },
          { title: "심판청구 대리", icon: img3 },
          { title: "행정쟁송 자료 준비 지원", icon: img4 },
          { title: "행정소송 수행자문", icon: img5 },
          { title: "행정소송 신청대리", icon: img6 },
        ]}
      />
      <TitleTemplate
        title="Strength"
        noImage
        subHeader="고객의 관세업무 리스크 통제를 위하여 관세 분야별 전문가 그룹을 통한 컨설팅을 수행합니다."
      />
      <DiamondScope
        contents={[
          {
            title: "관세업무 수행 경력",
            text: "전기전자, 화학, 식품, 의류 등 전 산업을 대상으로 300여개 업체의 컨설팅 수행경력 보유",
          },
          {
            title: "MAN-POWER",
            text: "세관에서 경력을 쌓은 전문가, 업계 최고 관세사로 구성된 심사 전문 컨설팅 그룹",
          },
          {
            title: "분야별 전문 컨설팅",
            text: "관세 전문가 그룹을 통한 분야별 맞춤형 전문 컨설팅 수행",
          },
          {
            title: "관세 자문단 보유",
            text: "관세청 관세평가분류원장, 중앙관세분석소장 등 역임한 국내 최고 관세분야별 전문인력 보유",
          },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["contestationMain"]} />
    </PageTemplate>
  );
};

export default ContestationPage;
const ContestationStrategyChart = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ breakpoints }) => (breakpoints.lg ? "2rem" : "3rem")};
  flex-direction: ${({ breakpoints }) => (breakpoints.lg ? "row" : "column")};
  & > div {
    & > div:first-child {
      display: flex;
      justify-content: center;
      & > div:nth-child(odd) {
        padding: 1rem 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${({ theme }) => theme.colors?.dark};
        color: white;
        gap: 3rem;
        font-size: 1.5em;
        font-weight: bold;
        min-width: ${({ breakpoints }) => (breakpoints.lg ? "16rem" : "16rem")};
      }
      & > div:nth-child(even) {
        border-top: 2rem solid transparent;
        border-bottom: 2rem solid transparent;
        border-left: 2rem solid ${({ theme }) => theme.colors?.dark};
      }
    }
    & > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2rem 0rem 0 0;
      gap: 1rem;
      & > div {
        text-align: center;
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
        /* border: solid 1px #888; */
        box-shadow: 0.5rem 0.5rem 1.5rem #c7c7c7, -0.5rem -0.5rem 1.5rem #fff;
        font-size: 1.2em;
        width: 15rem;
      }
    }
  }
`;
