import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import ASK_CEO2 from '../../resources/images/ASK_CEO2.png'
import UseBreakpoints from "../hooks/ui/UseBreakpoints";
const HomeOmbudsman = () => {
    const [input, setInput] = useState({ receiver: "관세법인에이원 CEO", sender: "", title: "", content: "" });
    const breakpoints = UseBreakpoints();
    return (<Div breakpoints={breakpoints}>
        <div>

            <div className="title">
                <div className="engtitle">Ask CEO</div>
                <div className="kortitle">CEO에게 한마디</div>

            </div>
            <div className="content">
                <form onSubmit={async (e) => {
                    e.preventDefault();
                    if (!input?.sender.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
                        alert(`입력하신 이메일 주소를 확인해주세요.`);
                        return;
                    }
                    await axios.post("https://ssek84ekyc.execute-api.ap-northeast-2.amazonaws.com/nodemail", {
                        from: input?.sender,
                        subject: input?.title,
                        text: input?.content,
                    });
                    alert(`${input?.sender}님께서 남겨주신 "${input?.title}" 제목의 내용을 전달했습니다. 소중한 의견 감사 드립니다.`)
                }}>
                    <label>
                        받는이
                    </label>
                    <input id="receiverInput" type="text" placeholder="받는이" value={input?.receiver} onChange={(e) => {
                        setInput(prev => ({ ...prev, receiver: e.target.value }));
                    }} readOnly />
                    <label>
                        보내는 분 E-mail
                    </label>
                    <input type="text" placeholder="보내는 분의 E-mail 주소" value={input?.sender} onChange={(e) => {
                        setInput(prev => ({ ...prev, sender: e.target.value }));
                    }} />
                    <label>
                        제목
                    </label>
                    <input type="text" placeholder="제목" value={input?.title} onChange={(e) => {
                        setInput(prev => ({ ...prev, title: e.target.value }));
                    }} />
                    <label>
                        내용
                    </label>
                    <input type="text" placeholder="내용" value={input?.content} onChange={(e) => {
                        setInput(prev => ({ ...prev, content: e.target.value }));
                    }} />
                    <input type="submit" value="보내기" />
                </form>

            </div>
        </div>
        <div>
            <img src={ASK_CEO2} alt="ASK CEO" />

        </div>
    </Div>)
}
export default HomeOmbudsman;
const Div = styled.div`
display: flex;
flex-direction: ${({ breakpoints }) => (breakpoints.md ? "row" : "column")};
& > div {
    flex: 1 1 0;
    &:last-child {

        display: ${({ breakpoints }) => (breakpoints.md ? "flex" : "none")};
        align-items: center;
        justify-content: center;
& > img {
    width: 80%;
}
    }
}
background-color: #eee;
.title {
    padding: 2rem 3rem;
}
.engtitle {
    font-size: 1.3em;
    font-weight: lighter;
}    
.kortitle {
    font-size: 2.3em;
    font-weight: bolder;
}
    .content {
        padding: .5em 1em;

    }
    form {
        display: flex;
        flex-direction: column;
        gap: .25em;
        & #receiverInput {
            user-select: none;
            -moz-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -o-user-select: none;
            pointer-events: none;

        }
    }
    label {
        display: none;
    }
    
    input[type=text] {
        border-radius: .5em;
        border: 0px;
        padding: .5rem 1rem;
    }
    input[type=submit] {
        cursor: pointer;
        border: 0px;
        border-radius: .5em;
        background-color: #ddd;
        padding: 1em;
    }
`