import styled from "styled-components";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
const Main = ({ children }) => {
  const location = useLocation();
  return (
    <Div currentPathName={location.pathname}>
      <Body>{children}</Body>
      <Footer />
    </Div>
  );
};
export default Main;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* url에 따라 다른 배경색을 주고 싶을 때 */
  background-color: ${({ currentPathName, theme }) => {
    switch (currentPathName) {
      case "/history":
        return theme?.colors?.pages?.history?.background;
      case "/prizes":
        return theme?.colors?.pages?.prizes?.background;
      case "/branch":
        return theme?.colors?.pages?.branches?.background;
      case "/esg":
        return theme?.colors?.pages?.esg?.background;
      default:
        return "white";
    }
  }};
`;
const Body = styled.main`
  /* padding: 3vh 0; */
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
