import PageTemplate from "../../components/ui/common/template/PageTemplate";
import MemberListTemplate from "../../components/ui/members/MemberListTemplate";
import Image from "../../resources/images/advisory-1.jpg";
import Image2 from "../../resources/images/advisory-2.jpg";
const AdvisoryPage = () => {
  const groups = [
    {
      category: "관세정책",
      members: ["woonki21", "y2kykim"],
    },
    // {
    //   category: "관세심사",
    //   members: [],
    // },
    {
      category: "행정쟁송",
      members: ["parksw11", "solgae8"],
    },
    {
      category: "수출입통관",
      members: ["ezpass", "hd11234", "son0077"],
    },
    {
      category: "품목분류",
      members: ["ccam7413"],
    },
    // {
    //   category: "국제통상",
    //   members: [""],
    // },
    {
      category: "특송",
      members: ["sykim0608"],
    },
  ];
  return (
    <PageTemplate
      title="Advisory Group"
      phrase="대관 전문 인력"
      image={Image2}
      large
    >
      <MemberListTemplate groups={groups} />
    </PageTemplate>
  );
};
export default AdvisoryPage;
