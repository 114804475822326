export const chapter1 = {
  title: "창업과 성장",
  range: "2001~2010",
  years: [
    {
      year: 2001,
      actions: [
        "에이원 무역 관세사법인 창업(인천공항)",
        "부산, 서울 지사 설치",
      ],
    },
    {
      year: 2002,
      actions: ["인천지사 설립"],
    },
    {
      year: 2003,
      actions: ["정운기 대표관세사 취임", "에이원 관세사법인으로 명칭변경"],
    },
    {
      year: 2004,
      actions: ["컨설팅 및 쟁송 업무를 관세사업계 최초로 시작"],
    },
    {
      year: 2005,
      actions: ["관세사업계 매출 1위", "행정쟁송 수임 및 승소율 1위"],
    },
    {
      year: 2006,
      actions: ["컨설팅본부 설립", "정부지원 FTA 컨설팅업무 수행"],
    },
    {
      year: 2007,
      actions: [
        "정운기 대표관세사 한국관세사회 회장 취임",
        "독립채산제 청산, 직영체제 구축",
        "대기업 중심 고객관리체제 구축",
      ],
    },
    {
      year: 2008,
      actions: [
        "관세사법인을 관세법인으로 전환",
        "동탑산업훈장 수상",
        "평택, 구미, 창원, 김포지사 설치",
      ],
    },
    {
      year: 2009,
      actions: ["김해, 안산지사 설치", "웹진(Trade Leaders)발행"],
    },
    {
      year: 2010,
      actions: ["성남, 안양, 여수지사 설치", "AEO인증 A등급 획득"],
    },
  ],
};
