import UseBreakpoints from "../../../../components/hooks/ui/UseBreakpoints";
import PageTemplate from "../../../../components/ui/common/template/PageTemplate";
import TitleTemplate from "../../../../components/ui/common/template/TitleTemplate";
import AgreementContentStyle from "../../../../style/styled-components/components/consulting/fta/AgreementContentStyle";
import AgreementHeaderStyle from "../../../../style/styled-components/components/consulting/fta/AgreementHeaderStyle";
import AgreementTableStyle from "../../../../style/styled-components/components/consulting/fta/AgreementTableStyle";
import AgreementTemplateStyle from "../../../../style/styled-components/components/consulting/fta/AgreementTemplateStyle";
import PanamaFlag from "../../../../resources/images/flags/panama.webp";
import CoastRicaFlag from "../../../../resources/images/flags/costarica.webp";
import HondurasFlag from "../../../../resources/images/flags/honduras.webp";
import ElSalvadorFlag from "../../../../resources/images/flags/elsalvador.webp";
import NicaraguaFlag from "../../../../resources/images/flags/nicaragua.webp";
const MiddleAmericaPage = () => {
  const breakpoints = UseBreakpoints();
  return (
    <PageTemplate>
      <AgreementTableStyle breakpoints={breakpoints}>
        <TitleTemplate title="협정별 규정" noImage />
        <AgreementTemplateStyle breakpoints={breakpoints}>
          <div>
            <div>
              <div>
                <img src={PanamaFlag} alt="국기" />
                <img src={CoastRicaFlag} alt="국기" />
                <img src={HondurasFlag} alt="국기" />
                <img src={ElSalvadorFlag} alt="국기" />
                <img src={NicaraguaFlag} alt="국기" />
              </div>
            </div>
            <div>파나마, 코스타리카, 온두라스, 엘살바도르, 니카라과</div>
          </div>
          <div>
            <div>
              <span>한-중미 FTA 적용요건</span>
            </div>
            <div>
              <div>
                <AgreementHeaderStyle>원산지증명서</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>발급방식</div>
                  <div>자율</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>발급주체</div>
                  <div>수출자, 생산자</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>서식</div>
                  <div>통일서식</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>유효기간</div>
                  <div>1년</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>제출생략 가능</div>
                  <div>1,000달러 이하</div>
                </AgreementContentStyle>
                <AgreementHeaderStyle>원산지검증</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>검증방식</div>
                  <div>직접, 간접</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>검증주체</div>
                  <div>
                    직접-수입국세관
                    <br />
                    간접-수출국세관
                  </div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>회신기간</div>
                  <div>
                    직접-30일(서면요청)
                    <br />
                    간접-150일
                  </div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>회신주체</div>
                  <div>
                    직접-조사대상자
                    <br />
                    간접-수출국세관
                  </div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>미회신조치</div>
                  <div>협정관세 제한</div>
                </AgreementContentStyle>
                <AgreementHeaderStyle>부가가치계산</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>계산방법</div>
                  <div>공제법, 집적법</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>부가가치기준</div>
                  <div>RVC(20~50%)</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>기준가격</div>
                  <div>FOB</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>비고</div>
                  <div>-</div>
                </AgreementContentStyle>
                <AgreementHeaderStyle>최소허용기준</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>일반품목(가격기준)</div>
                  <div>FOB 10%이하</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>농수산물(가격기준)</div>
                  <div>
                    1~14류 적용 제외
                    <br />
                    15~24류 CTSH 충족시 10% 이하
                  </div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>섬유(중량기준)</div>
                  <div>10% 이하</div>
                </AgreementContentStyle>
              </div>
              <div>
                <AgreementHeaderStyle>중간재</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>인정여부</div>
                  <div>○</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>대상물품</div>
                  <div>역내생산품</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>지정의무</div>
                  <div>×</div>
                </AgreementContentStyle>
                <AgreementHeaderStyle>
                  부속품, 예비부분품, 공구
                </AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>세번변경기준</div>
                  <div>본체의 원산지 판정시 고려하지 않음</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>부가가치기준</div>
                  <div>원산지별 구분계상</div>
                </AgreementContentStyle>
                <AgreementHeaderStyle>소매포장</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>세번변경기준</div>
                  <div>본체의 원산지 판정시 고려하지 않음</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>부가가치기준</div>
                  <div>원산지별 구분계상</div>
                </AgreementContentStyle>
                <AgreementHeaderStyle>세트물품</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>인정여부</div>
                  <div>○</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>비원산지 허용한도</div>
                  <div>FOB가격의 15% 이하</div>
                </AgreementContentStyle>
                <AgreementHeaderStyle>운송</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>직접운송규정</div>
                  <div>○</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>운송요건</div>
                  <div>
                    하역, 재선적, 재포장, 상품보존
                    <br />
                    단일 운송서류
                    <br />
                    경유국 관세당국 발행증명서
                    <br />
                    세관통제
                  </div>
                </AgreementContentStyle>
              </div>
            </div>
          </div>
        </AgreementTemplateStyle>
      </AgreementTableStyle>
    </PageTemplate>
  );
};
export default MiddleAmericaPage;
