import styled from 'styled-components';
import { languageStore } from '../../state/language';
const LanguageSelection = () => {
    const languages = [
        { code: "KR", description: "한글", order: 1 },
        //  { code: "EN", description: "ENGLISH", order: 2 }, { code: "CN", description: "中文", order: 3 }, { code: "JP", description: "日本語", order: 4 }
    ];
    const lang = languageStore();
    return (<Div>
        {languages?.filter(({ code }) => lang.language !== code).sort((a, b) => (a.order >= b.order ? 1 : -1)).map(({ code, description }, idx) => {
            return (<div key={idx} onClick={() => { localStorage.setItem("language", code); lang.setLanguage(code); }}>{description}</div>)
        })}
    </Div>)
}
export default LanguageSelection;
const Div = styled.div`
display: flex;
position: relative;
align-items: center;
gap: .5rem;
& > div:not(:last-child):after {
content: "|";
display: inline-block;
position: relative;
right: -.3em;
color: red;
}
`;