import styled from "styled-components";
const COIssueType = ({ type, themeColor, inverted, annotation }) => {
  return (
    <Div themeColor={themeColor} inverted={inverted}>
      <div className="ftaType">{type}</div>
      <div className="until">{annotation}</div>
    </Div>
  );
};
export default COIssueType;
const Div = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  & > div {
    &.ftaType {
      border-radius: 0.6rem;
      background-color: ${({ theme, themeColor }) =>
        themeColor ? theme.colors[themeColor] : "#888"};
      color: ${({ theme, inverted }) =>
        inverted
          ? theme.colors.foreground.inverted
          : theme.colors.foreground.normal};
      font-size: 0.8em;
      padding: 0.4em 0.6em;
    }
    &.until {
      position: absolute;
      bottom: 1em;
      font-size: .8em;
      left: 2.9em;
    }
  }
`;
