import { Link } from "react-router-dom";
import styled from "styled-components";
const Page404 = () => {
  return (
    <Div>
      <div>없는 페이지거나 잘못된 접근입니다.</div>
      <div>
        <Link draggable={false} to="/">메인으로 돌아가기</Link>
      </div>
    </Div>
  );
};
export default Page404;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: 100vw;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
