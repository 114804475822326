import styled from 'styled-components';
import PageTemplate from '../../components/ui/common/template/PageTemplate';
import Image from '../../resources/images/background/careers/job-5382501_1280.jpg';
const CareersPage = () => {
    return (
        <PageTemplate title="채용" image={Image} large phrase="여러분의 지원을 기다립니다.">
            <Div>
                <div>
                    <div className="subTitle">
                        채용분야
                    </div>
                    <hr />
                    <ul className="positionList">
                        <li><div>관세사</div><ul>
                        </ul></li>
                        <li><div>요건/검사/검역 전문가</div><ul></ul><ul>
                        </ul></li>
                        <li><div>AEO 컨설턴트</div><ul>
                        </ul><ul></ul></li>
                        <li><div>통관/환급/요건/검사/검역 사무원</div><ul>
                        </ul><ul></ul></li>
                        <li><div>경영/기획/노무/인사/회계 전문가<ul>
                        </ul> </div><ul></ul></li>
                        <li><div>IT 개발/설계/UX 전문가</div><ul></ul></li>
                    </ul>
                </div>
                <div>
                    <div className="subTitle">
                        FAQ
                    </div>
                    <hr />
                    <ol className="faqList">
                        <li>
                            <div className="question">근무 환경은 어떤가요?</div>
                            <div className="answer">
                                <ul className="faqSubList">
                                    <li>
                                        복장에 대해서 자유롭습니다.
                                    </li>
                                    <li>
                                        대부분의 포지션이 야근을 지양합니다.
                                    </li>
                                    <li>
                                        시차출퇴근제, 유연근무제 등을 사용할 수 있습니다. (일부 업무 분야는 제외)
                                    </li>
                                    <li>
                                        직원의 워라밸을 중시합니다.
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div className="question">채용 절차는 어떻게 진행되나요?</div>
                            <div className="answer">
                                <ol className="faqSubList">
                                    <li>
                                        입사 지원
                                    </li>
                                    <li>
                                        서류 전형
                                    </li>
                                    <li>
                                        1차 면접(실무진, 관리자)
                                    </li>
                                    <li>
                                        2차 면접(임원)
                                    </li>
                                </ol>
                            </div>
                        </li>
                        <li>
                            <div className="question">근무지는 어디인가요?</div>
                            <div className="answer">각 전형마다 근무지가 다르며 서울본사 또는 각 지점에서 업무를 수행하게 됩니다.</div>
                        </li>
                    </ol>
                </div>
                <div>

                    <div className="subTitle">복리후생</div>
                    <hr />
                    <ul className="welfareList">
                        <li>
                            <div>지원금/보험</div>
                            <ul className="welfareSubList">
                                <li>건강검진</li>
                                <li>의료비지원(본인)</li>
                                <li>각종 경조사 지원</li>
                                <li>의료비지원(가족)</li>
                                <li>선택적복리후생</li>
                                <li>복지카드/포인트</li>
                            </ul>
                        </li>
                        <li>
                            <div>
                                급여제도
                            </div>
                            <ul className="welfareSubList">

                                <li>퇴직연금</li>
                                <li>장기근속자 포상</li>
                                <li>성과급</li>
                                <li>
                                    직책수당
                                </li>
                                <li>4대보험</li>
                            </ul>
                        </li>
                        <li>
                            <div>선물</div>
                            <ul className="welfareSubList">

                                <li>명절선물/귀향비</li>
                                <li>창립일선물지급</li>
                                <li>임신/출산 선물</li>
                                <li>장기근속 선물</li>
                            </ul>
                        </li>
                        <li>
                            <div>
                                교육/생활
                            </div>
                            <ul className="welfareSubList">
                                <li>창립일행사</li>
                                <li>워크샵</li>
                                <li>신입사원교육(OJT)</li>
                                <li>직무능력향상교육</li>
                                <li>음료제공(차, 커피)</li>
                            </ul>
                        </li>
                        <li><div>근무 환경</div>
                            <ul className="welfareSubList">
                                <li>회의실</li>
                                <li>공기청정기</li>
                                <li>카페테리아</li>
                                <li>사원증</li>
                                <li>콘도/리조트 이용권</li>
                                <li>사무용품 지급</li>
                            </ul>
                        </li>
                        <li>
                            <div>
                                조직문화
                            </div>
                            <ul className="welfareSubList">
                                <li>회식강요 안함</li>
                                <li>야근강요 안함</li>
                                <li>자유복장</li>
                                <li>자유로운 연차사용</li>
                                <li>출산 장려</li>
                            </ul>
                        </li>
                        <li>
                            <div>
                                출퇴근
                            </div>
                            <ul className="welfareSubList">
                                <li>야간교통비지급</li>
                                <li>회사차량 있음</li>
                                <li>주 52시간제 준수</li>
                            </ul>
                        </li>
                        <li>
                            <div>
                                리프레시
                            </div>
                            <ul className="welfareSubList">
                                <li>연차</li><li>경조휴가제</li><li>반차</li>
                                <li>근로자의 날 휴무</li>
                                <li>산전 후 휴가</li><li>육아휴직</li>
                                <li>남성출산휴가</li>

                            </ul>
                        </li>
                    </ul>
                </div>
                <div>
                    <div className="subTitle">채용공고</div>
                    <hr />
                    <div className="incruitDesc">
                        관세법인 에이원의 채용공고는 아래의 각 채용사이트에서 확인하실 수 있습니다.
                    </div>
                    <div>
                        <ul className="careerSiteLinkList">
                            <li>
                                <a href="https://www.saramin.co.kr/zf_user/search?search_area=main&search_done=y&search_optional_item=n&searchType=search&searchword=%EA%B4%80%EC%84%B8%EB%B2%95%EC%9D%B8%20%EC%97%90%EC%9D%B4%EC%9B%90" rel="noreferrer" target="_blank">사람인 바로가기</a>
                            </li>
                            <li>
                                <a href="https://www.jobkorea.co.kr/Search/?stext=%EA%B4%80%EC%84%B8%EB%B2%95%EC%9D%B8%20%EC%97%90%EC%9D%B4%EC%9B%90" rel="noreferrer" target="_blank">잡코리아 바로가기</a>

                            </li>
                        </ul>
                    </div>
                </div>
            </Div>
        </PageTemplate >
    )
}
export default CareersPage;
const Div = styled.div`
display: flex;
flex-direction: column;
gap: 3rem;
hr {
    margin: 1rem 0 2rem 0;
}
.positionList {
    padding: 0 4rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 1.1em;
    & > li:before {
        position: absolute;
        content: "";
        display: inline-block;
        transform: translate(-1rem, .5em);
width: 6px;
height: 6px;
border-radius: 100%;
background-color: ${({ theme }) => theme.colors.medium};
    }
}
.faqList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.question {
    font-size: 1.1em;
    font-style: italic;
    font-weight: 300;
}
.answer {
    padding: 1em 0;
}
.subTitle {
    font-size: 1.8em;
        font-weight: lighter;

}
.welfareList {
    list-style: none;
    & > li > div {
        // 복리후생 소제목
        font-size: 1.2em;
        /* text-decoration: underline;
        text-underline-offset: .5em; */
        padding: 1em;
        font-weight: 300;
    }
}
.faqSubList {
    display: flex;
    flex-direction: column;
    gap: .5em;
}
.welfareSubList {
    display: flex;
    list-style: none;
    gap: 1rem;
    flex-wrap: wrap;
    & li {
        padding: .5em .8em;
border-radius: 1rem;
background-color: ${({ theme }) => theme.colors.background.lightgreen}
    }
}
.incruitDesc {
    padding: 0 2rem;
    font-weight: 300;
}
.careerSiteLinkList {
    list-style: none;
    display: flex;
gap: 1rem;
text-decoration: underline;
text-underline-offset: .5rem;
}
`;