import styled from "styled-components";

export default styled.div`
  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    & > div:first-child {
    }

    & > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      & > div:first-child {
        display: flex;
        justify-content: center;
        font-weight: bold;
        font-size: 1.2em;
        padding: 1rem;
      }
      & > div:nth-child(2) {
        display: flex;
        flex-direction: ${({ breakpoints }) =>
          breakpoints?.lg ? "row" : "column"};
        & > div:not(:last-child) {
          border-right: 
          ${({ breakpoints, theme }) =>
          breakpoints?.lg ? `1px solid ${theme.colors.monotone.light}` : "none"};
          
        }
        & > div {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
`;
