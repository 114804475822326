import { keyframes } from "styled-components";
export const fromTop = keyframes`
  from {
    transform: translateY(-50%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;
export const fromBottom = keyframes`
  from {
    transform: translateY(50%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const fromLeft = keyframes`
  from {
    transform: translateX(-10%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;
export const fromRight = keyframes`
  from {
    transform: translateX(10%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;
