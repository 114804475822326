import { useEffect, useState, useMemo } from "react";
import PageTemplate from "../../components/ui/common/template/PageTemplate";
import CenterDiv from "../../style/styled-components/components/CenterDiv";
import ContentDiv from "../../style/styled-components/components/ContentDiv";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { errormessages } from "../../context/languages/kr/errormessages";
import remarkGfm from "remark-gfm";
const ArticlePage = ({ category = "", title = "게시글 읽기", list = null }) => {
  const { id } = useParams();
  const [content, setContent] = useState("");
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(`/articles/${category}/${id}.md`);
        setContent(data);
      } catch (error) {
        setContent("## 등록된 글이 없습니다.");
      }
    })();
  }, [category, id]);
  const getArticle = (array, id) => {
    return array?.find(x => x.no.toString() === id.toString());
  }
  const { title: currentTitle, subtitle: currentSubTitle = "", attached: currentAttacheds, date: currentDate } = useMemo(() => {
    return getArticle(list, id);
  }, [id, list]);
  const prev = useMemo(() => { return list ? Math.max(...(list.filter(x => x.no < id).map(x => x.no))) : null }, [id, list]);
  const next = useMemo(() => { return list ? Math.min(...(list.filter(x => x.no > id).map(x => x.no))) : null }, [id, list]);
  return (
    <PageTemplate title={title} noImage>
      <CenterDiv>
        <ContentDiv>
          <Div>
            <h1>{currentTitle}</h1>
            {currentSubTitle &&
              <h3>[{currentSubTitle}]</h3>
            }
            <div className="postDate">{currentDate}</div>
            <hr />
            <div className="body">
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
            </div>
            {
              currentAttacheds && (
                <>
                  <div className="attachedTitle">
                    첨부파일 다운로드
                  </div>
                  <div className="attached">
                    {
                      currentAttacheds && currentAttacheds?.map((x, idx) => <a key={idx} href={`https://aonecustoms-cdn.s3.ap-northeast-2.amazonaws.com/attached/${category}/${id}/${encodeURI(x.filename)}`} download target="_blank">{x.filename}</a>)
                    }
                  </div>
                </>
              )
            }
            <hr />
            <div className="prevnext">
              {
                <Link to={`/boards/${category}/${prev}`} disabled={!isFinite(prev)}><div className="prev"><div>← 이전</div><div>{isFinite(prev) ? getArticle(list, prev)?.title : errormessages.NO_PREVIOUS_ARTICLE}</div></div></Link>
              }
              {
                <Link to={`/boards/${category}/${next}`} disabled={!isFinite(next)}><div className="next"><div>다음 →</div><div>{isFinite(next) ? getArticle(list, next)?.title : errormessages.NO_NEXT_ARTICLE}</div></div></Link>
              }
            </div>
          </Div>
        </ContentDiv>
      </CenterDiv>
    </PageTemplate>
  );
};
export default ArticlePage;
const Div = styled.div`
& blockquote {
  background-color: #f8f8f8;
  padding: 1rem 2rem;
}
& img {
  max-width: 100%;
  margin-left: auto;
  &:first-child {
    transform: translateX(-1em);
  }
}
& .body {
  a {
    text-decoration: underline;
    text-underline-offset: .3em;
    background-color: #efefff;
  }
}
& p {
  text-align: left;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  text-indent: 1em;
}
& li {
  font-weight: 300;
  line-height: 1.7em;
  text-indent: 1em;
}
& h1 {
  margin: 1rem 0;
}
& table {
  font-weight: 300;
  max-width: 90vw;
  font-size: .9em;
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
    & th {
      background-color: #dfdfdf;
      font-weight: normal;
      padding: .5rem 1rem;

    }
    & tr:nth-child(odd) {
  background-color: #ffffff;
}

& tr:nth-child(even) {
  background-color: #efefef;
}
  }
  & > h3 {
color: ${({ theme }) => theme?.colors?.foreground.dimmed};
font-weight: normal;
  }
  & > div.prevnext {
    margin-top: 5rem;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    & > a 
    {
        flex: 1;
      &[disabled] {
        pointer-events: none;
        color: ${({ theme }) => theme.colors.foreground.dimmed};
      }
      & > div {
        display: flex;
        flex-direction: column;
        &.next {
         align-items: flex-end; 
        }
      }

    }
  }
  & hr {
    margin: 2rem;
    color: ${({ theme }) => theme.colors.dark};
  }
  & > div.attached {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 1rem;
  }
  & > div.attachedTitle {
    color: ${({ theme }) => theme.colors?.foreground?.highlight};
    flex-direction: column;
    gap: .5rem;
    padding: 1rem;
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors?.monotone?.extralight};
  }
  .postDate {
    text-align: right;
    color: ${({ theme }) => theme.colors.foreground.dimmed};
    padding: 0 3rem;
  }
`;
