import styled from "styled-components";
export default styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  & > div:first-child {
    float: left;
    border-right: ${({ width }) => 0.288 * width}em solid
      ${({ color }) => color ?? "#ddd"};
    border-top: ${({ width }) => 0.5 * width}em solid transparent;
    border-bottom: ${({ width }) => 0.5 * width}em solid transparent;
  }
  & > div:nth-child(2) {
    float: left;
    width: ${({ width }) => 0.576 * width}em;
    height: ${({ width }) => width}em;
    background-color: ${({ color }) => color ?? "#ddd"};
  }
  & > div:nth-child(3) {
    float: left;
    border-left: ${({ width }) => 0.288 * width}em solid
      ${({ color }) => color ?? "#ddd"};
    border-top: ${({ width }) => 0.5 * width}em solid transparent;
    border-bottom: ${({ width }) => 0.5 * width}em solid transparent;
  }
  & > div:nth-child(4) {
    position: absolute;
    top: ${({ width }) => 0.27 * width}em;
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
  }
  & > div:nth-child(5) {
    position: absolute;
    top: ${({ width }) => 0.2 * width}em;
    text-align: center;
    word-wrap: wrap;
    width: 3em;
    line-height: 1.5em;
    font-size: 1.5em;
    font-weight: bold;
  }
  & > div:nth-child(6) {
    position: absolute;
    width: 200%;
    & > div:first-child {
      position: relative;
      bottom: ${({ width }) => 0.1 * width}em;
      height: 1px;
      background-color: black;
      left: ${({ width }) => 2 * width}em;
    }
    & > div:nth-child(2) {
      position: relative;
      bottom: ${({ width }) => 0.1 * width}em;

      height: 1px;
      width: 20%;
      background-color: black;
      transform: rotate(-30deg);
      top: ${({ width }) => 0.01 * width}em;
      left: ${({ width }) => 1.58 * width}em;
    }
    & > div:nth-child(3) {
      position: absolute;
      left: ${({ width }) => 2 * width}em;
      width: 100%;
    }
  }
  & > div:nth-child(7) {
    position: absolute;
    width: 200%;
    & > div:first-child {
      position: relative;
      bottom: ${({ width }) => 0.1 * width}em;

      height: 1px;
      background-color: black;
      right: ${({ width }) => 2 * width}em;
    }
    & > div:nth-child(2) {
      position: relative;
      bottom: ${({ width }) => 0.1 * width}em;
      height: 1px;
      width: 20%;
      background-color: black;
      transform: rotate(30deg);
      top: 0.13em;
      left: ${({ width }) => 0.27 * width}em;
    }
    & > div:nth-child(3) {
      position: absolute;
      right: ${({ width }) => 2 * width}em;
      width: 100%;
    }
  }
`;
