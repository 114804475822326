import img0 from "../../../resources/images/prizes/표창/대통령_동탑산업훈장(2008).jpg";
// import img1 from "../../../resources/images/prizes/관세행정혁신/구미세관_고객패널위촉(2008).png";
// import img2 from "../../../resources/images/prizes/관세행정혁신/관세평가분류원_품목분류협의회 위원 위촉(2011).png";
// import img3 from "../../../resources/images/prizes/관세행정혁신/평택세관_통관심사위원 위촉(2014).png";
import img4 from "../../../resources/images/prizes/관세행정혁신/관세국경관리연수원_품목분류 겸임교수 위촉(2018).png";
// import img5 from "../../../resources/images/prizes/관세행정혁신/구미세관 관세심사위원 위촉(2019).png";
// import img6 from "../../../resources/images/prizes/관세행정혁신/관세청_관세심사위원 위촉(2020).png";
// import img7 from "../../../resources/images/prizes/관세행정혁신/관세청_FTA공익관세사 위촉(2020).png";
// import img8 from "../../../resources/images/prizes/관세행정혁신/서울본부세관_적극행정 자문위원 위촉(2020).png";
// import img9 from "../../../resources/images/prizes/관세행정혁신/인천본부세관_관세심사위원 위촉(2020).png";
// import img10 from "../../../resources/images/prizes/관세행정혁신/국경관리연수원_관세사 특별전형시험 출제위원 위촉(2020).png";
import img11 from "../../../resources/images/prizes/관세행정혁신/관세청_명예세관원 위촉(2020).png";
// import img12 from "../../../resources/images/prizes/관세행정혁신/서울본부세관_관세평가협의회 위원위촉(2019)2.png";
// import img13 from "../../../resources/images/prizes/관세행정혁신/서울본부세관_관세평가협의회위원위촉(2019)1.jpg";
// import img14 from "../../../resources/images/prizes/관세행정혁신/서울본부세관_관세심사위원 위촉(2020).png";
// import img15 from "../../../resources/images/prizes/관세행정혁신/창원세관_관세심사위원 위촉(2020).png";
// import img16 from "../../../resources/images/prizes/관세행정혁신/관세청_관세행정발전 심의위원회 위촉(2012).png";
import img17 from "../../../resources/images/prizes/관세행정혁신/한국관세사회_고문위촉(2017).png";
// import img18 from "../../../resources/images/prizes/관세행정혁신/한국관세사회_광주지부장위촉(2019).png";
// import img19 from "../../../resources/images/prizes/관세행정혁신/여수시장_민원조정위원 위촉(2020).png";
// import img20 from "../../../resources/images/prizes/관세행정혁신/광주세관_납세자보호 위원 위촉(2020).png";
import img21 from "../../../resources/images/prizes/표창/기획재정부_관세행정발전표창(2020).png";
import img22 from "../../../resources/images/prizes/표창/관세청_관세행정발전표창(2020).png";
import img23 from "../../../resources/images/prizes/표창/창원세관_관세행정공로(2022).png";

export const prizesCustoms = [
  {
    presenter: "대통령",
    content: "동탑산업훈장",
    year: 2008,
    image: img0,
  },
  // {
  //   presenter: "구미세관",
  //   content: "고객패널 위촉",
  //   year: 2008,
  //   image: img1,
  // },
  // {
  //   presenter: "관세평가분류원",
  //   content: "품목분류협의회 위원 위촉",
  //   year: 2011,
  //   image: img2,
  // },
  // {
  //   presenter: "평택세관",
  //   content: "통관심사위원 위촉",
  //   year: 2014,
  //   image: img3,
  // },

  // {
  //   presenter: "구미세관",
  //   content: "관세심사위원 위촉",
  //   year: 2019,
  //   image: img5,
  // },
  // {
  //   presenter: "관세청",
  //   content: "관세심사위원 위촉",
  //   year: 2020,
  //   image: img6,
  // },
  // {
  //   presenter: "관세청",
  //   content: "공익관세사 위촉",
  //   year: 2020,
  //   image: img7,
  // },
  // {
  //   presenter: "서울본부세관",
  //   content: "적극행정 자문위원 위촉",
  //   year: 2020,
  //   image: img8,
  // },
  // {
  //   presenter: "인천본부세관",
  //   content: "관세심사위원 위촉",
  //   year: 2020,
  //   image: img9,
  // },
  // {
  //   presenter: "관세국경관리연수원",
  //   content: "관세사 특별전형시험 출제위원 위촉",
  //   year: 2020,
  //   image: img10,
  // },
  {
    presenter: "기획재정부",
    content: "관세행정발전 표창",
    year: 2020,
    image: img21,
  },
  {
    presenter: "관세청",
    content: "관세행정발전 표창",
    year: 2020,
    image: img22,
  },
  {
    presenter: "관세국경관리연수원",
    content: "품목분류 겸임교수 위촉",
    year: 2018,
    image: img4,
  },
  {
    presenter: "창원세관",
    content: "관세행정공로",
    year: 2020,
    image: img23,
  },
  {
    presenter: "관세청",
    content: "명예세관원 위촉",
    year: 2020,
    image: img11,
  },
  // {
  //   presenter: "서울본부세관",
  //   content: "관세평가협의회 위원 위촉",
  //   awardee: "정철",
  //   year: 2020,
  //   image: img12,
  // },
  // {
  //   presenter: "서울본부세관",
  //   content: "관세평가협의회 위원 위촉",
  //   awardee: "오영록",
  //   year: 2020,
  //   image: img13,
  // },
  // {
  //   presenter: "서울본부세관",
  //   content: "관세심사위원 위촉",
  //   year: 2020,
  //   image: img14,
  // },
  // {
  //   presenter: "창원세관",
  //   content: "관세심사위원 위촉",
  //   year: 2020,
  //   image: img15,
  // },
  // {
  //   presenter: "관세청",
  //   content: "관세행정발전 심의위원회 위촉",
  //   year: 2012,
  //   image: img16,
  // },
  {
    presenter: "한국관세사회",
    content: "한국관세사회 고문 위촉",
    year: 2017,
    image: img17,
  },
  // {
  //   presenter: "한국관세사회",
  //   content: "광주지부장 위촉",
  //   year: 2019,
  //   image: img18,
  // },
  // {
  //   presenter: "여수시장",
  //   content: "민원조정위원 위촉",
  //   year: 2019,
  //   image: img19,
  // },
  // {
  //   presenter: "광주세관",
  //   content: "납세자보호 위원 위촉",
  //   year: 2020,
  //   image: img20,
  // },
];
