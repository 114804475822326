import styled from "styled-components";
const AuditExperienceTable = () => {
  return (
    <Table>
      <colgroup>
        <col width="10em" />
        <col />
        <col />
      </colgroup>
      <thead>
        <tr>
          <th>분야</th>
          <th>업종</th>
          <th>주요 컨설팅 내용</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>과세가격</td>
          <td>전기/전자</td>
          <td>
            <ul>
              <li>
                거래가격이 특수관계의 영향을 받았음을 이유로 거래가격 부인 및
                과세가격 재산정(제4방법) 이슈가 발생함. 과세전적부심 청구한 결과
                재조사 결정에 따라, 수출자가 실현하는 이윤이 적정한 것으로
                소명하는 한편 세관과 원만히 합의하여 추징 방어 성공
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>외환</td>
          <td>전기/전자</td>
          <td>
            <ul>
              <li>
                한국 본사-해외 지점간의 관계에서 밀수입 여부 및 해외지점과의
                외환 거래 시 자본거래 신고 의무 위반 이슈가 발생하였으나, 동
                사안이 외국환거래법 상 자본거래 신고 대상이 아님을 소명하여
                신고의무가 없음을 인정받았으며 밀수입죄가 아님을 주장하여
                처벌없이 일부 금액 추징으로 이슈 종결
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>품목분류</td>
          <td>기계</td>
          <td>
            <ul>
              <li>
                품목분류에 대한 이견으로 인하여 심사시 이슈가 발생한 건에 대하여
                유리한 세번으로 품목분류 회신을 이끌어내 추징 방어
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>환급</td>
          <td>화학</td>
          <td>
            <ul>
              <li>
                소요량 적정성 및 부산물 공제비율에 대한 이슈가 발생하였으나 관련
                자료 제출 등 적정하게 소명하여 추징 방어
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
export default AuditExperienceTable;
const Table = styled.table`
  border-collapse: collapse;
  max-width: 65rem;
  & > thead {
    & > tr {
      font-size: 1.2em;
      color: ${({ theme }) => theme.colors?.foreground?.inverted};
      text-align: center;
      font-weight: normal;
      background-color: ${({ theme }) => theme.colors?.dark};
      &:first-child {
        & > th {
          font-weight: normal;
          padding: 1em;
          &:first-child {
            border-radius: 1rem 0 0 0;
          }
          &:last-child {
            border-radius: 0 1rem 0 0;
          }
        }
      }
    }
  }
  & > tbody {
    & > tr {
      & > td {
        padding: 0 1em;
      }
      & > td:first-child,
      & > td:nth-child(2) {
        text-align: center;
      }
      &:nth-child(even) {
        background-color: ${({ theme }) => theme.colors?.monotone?.extralight};
      }
      &:last-child {
        & > td:first-child {
          border-radius: 0 0 0 1rem;
        }
        & > td:last-child {
          border-radius: 0 0 1rem 0;
        }
      }
    }
    & ul {
      margin: 0;
      padding: 0.5em 2em;
    }
  }
`;
