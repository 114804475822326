import styled from "styled-components";
const ExpressUnderstandingDiv = ({ amount }) => {
  return (
    <Div>
      <div>{amount}</div>
    </Div>
  );
};
export default ExpressUnderstandingDiv;
const Div = styled.div`
    font-size: 1.5em;
    background-color: #eee;
    font-weight: bold;
    width: 5rem;
    height: 5rem;
    border-radius: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: .5rem solid ${({theme}) => theme.colors?.dark};
`;
