import styled from "styled-components";
const GroupSelectOption = ({
  selected,
  setSelected,
  value,
  contents,
  setContents,
  title,
}) => {
  return (
    <Div
      onClick={() => {
        setContents(contents);
        setSelected(value);
      }}
      selected={selected}
      value={value}
    >
      {title}
    </Div>
  );
};
export default GroupSelectOption;
const Div = styled.div`
  display: flex;
  justify-content: center;
  width: 5em;
  padding: 0.7em 1em;
  font-size: 1.3em;
  font-weight: bold;
  color: ${({ selected, value, theme }) =>
    selected === value ? theme.colors?.foreground.inverted : "inherit"};
  background-color: ${({ selected, value, theme }) =>
    selected === value ? theme.colors?.dark : "inherit"};
  border-radius: 1em;
`;
