import styled from "styled-components";
import ArticleList from "../../components/contents/board/ArticleList";
import PageTemplate from "../../components/ui/common/template/PageTemplate";
import CenterDiv from "../../style/styled-components/components/CenterDiv";
import ContentDiv from "../../style/styled-components/components/ContentDiv";
import articlelist from '../../context/boards/ceocolumn/articlelist.json';
const CEOColumnPage = () => {
  return (
    <PageTemplate title="정운기칼럼" noImage>
      {/* <p>
      관세법인 에이원 정운기 회장께서 관세, 무역, FTA등 관세와 무역에 대하여 해박한 전문 지식을 바탕으로 외부 신문 또는 에이원 월간 발행물에 기고한 칼럼을 모아 놓은 공간입니다.
      </p> */}
      <CenterDiv>
        <ContentDiv>
          <Div>
            <ArticleList
              category="ceocolumn"
              contents={articlelist}
            />
          </Div>
        </ContentDiv>
      </CenterDiv>
    </PageTemplate>
  );
};
export default CEOColumnPage;
const Div = styled.div`
  display: flex;
  flex-direction: column;
`;
