import styled from "styled-components";
import PrizeCategory from "../../../components/ui/intro/prizes/PrizeCategory";
import PrizeImage from "../../../resources/images/background/intro/prizes.jpg";
import { prizesCustoms } from "../../../context/intro/prizes/prizes-customs";
import { prizesManagement } from "../../../context/intro/prizes/prizes-management";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
const PrizesPage = () => {
// 2008 03 03 납세자의날 세정 협조자 이명박 대통령
// 무게감
return (
    <PageTemplate
      title="수상"
      image={PrizeImage}
      phrase="관세법인의 새로운 역사를 쓰다."
      large aone
    >
      <PageDiv>
        <Horizontal />
        <PrizeCategory title="관세행정 협력" prizes={prizesCustoms} />
        <Horizontal />
        <PrizeCategory title="경영혁신" prizes={prizesManagement} />
      </PageDiv>
    </PageTemplate>
  );
};
export default PrizesPage;
const PageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 3em;
`;
const Horizontal = styled.hr`
  width: 100%;
`;
