import styled from "styled-components";
import EthicalManagementSub from "./EthicalManagementSub";
const EthicalManagement = () => {
  const ethical1 = [
    "우리는 투명경영과 정도경영의 사명 아래 정정당당한 경쟁과 우호적인 협력관계의 구축을 통해 공정한 거래문화를 구축한다.",
    "우리는 열린사고를 가지고 기본과 원칙에 충실하며, 명예와 품의를 지킨다.",
    '우리는 고객을 최우선으로 생각하는 사명감으로 "고객감동"의 경영방침을 실현한다.',
  ];
  const ethical2 = [
    "임직원 기본윤리 맡은 바 직무를 성실하게 수행하고, 단체생활의 필요한 질서와 규율을 엄수한다.",
    "회사의 명예를 훼손하는 언행이나 행동은 하지 않는다.",
    "직무상의 기밀은 재직중은 물론 퇴직 후라도 외부에 절대 누설하지 않는다. ",
    "회사의 허가 없이 회사 이외의 다른 직무를 겸하거나 영리 사업에 종사하지 않는다. ",
    "회사와 거래관계가 있는 자로부터 부당한 사례 또는 증여를 받지 않는다.",
  ];
  const ethical3 = [
    "임직원 윤리행동 지침 업무와 관련된 거래처 및 이해관계자로부터 금품이나 선물, 향응 등은 어떠한 경우라도 제공받지 않으며, 불가피하게 수수한 금품이나 선물 등은 즉시 신고한다. ",
    "통관업무를 처리함에 있어 법규에 어긋나는 신고행위를 해서는 안되며, 화주로부터 부당한 처리요구를 받을 경우 거절하고 상사에게 보고한다.",
    "거래처 및 협력업체로부터 제공하는 식사 및 향응은 정중하게 거절하되, 불가피하게 식사를 제공받을 경우에는 인당 2만원을 넘지않으며, 술자리의 경우 10만원을 넘지 않는다.",
    "본인 또는 동료의 경조사는 거래처 및 이해관계자에게 알리지 않는다.",
    " 회사 구성원 및 이해관계자의 경조사 시 경조금은 사회통념상 적정한 수준을 넘지 않는 범위에서 수수한다.",
    "구성원 및 이해관계자와는 금전대차, 보증, 임대차거래 등의 금전거래를 하지 않는다.",
  ];
  return (
    <Div>
      <EthicalManagementSub title="윤리헌장" contents={ethical1} />
      <EthicalManagementSub title="임직원 기본윤리" contents={ethical2} />
      <EthicalManagementSub title="임직원 윤리행동 지침" contents={ethical3} />
    </Div>
  );
};
export default EthicalManagement;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
