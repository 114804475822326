import styled from "styled-components";
export default styled.div`
  display: flex;
  padding: 0 1rem;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : 0)};
  & > div {
    font-size: 0.8em;
    &:first-child {
      background-color: ${({ theme }) => theme.colors?.background.default};
      color: ${({ theme }) => theme.colors?.foreground.light};
      width: 10rem;
      padding: 0 1rem;
    }
    &:nth-child(2) {
      padding: 0 1rem;
      color: ${({ theme }) => theme.colors?.foreground.normal};
    }
  }
  & > div:nth-child(2) {
    flex: 1;
  }
`;
