import img1 from "../../../resources/images/prizes/경영혁신/NICE평가정보_기업평가 우수기업 인증(2018).png";
import img2 from "../../../resources/images/prizes/경영혁신/관세평가분류원_근무혁신 우수기업 선정(2019).png";
import img3 from "../../../resources/images/prizes/경영혁신/경제포커스_중소기업 경영대상(2019).png";
import img4 from "../../../resources/images/prizes/경영혁신/여성가족부_가족친화기업 선정(2020).png";
import img5 from "../../../resources/images/prizes/경영혁신/서울지방고용노동청_일생활 균형캠페인 참여(2021).png";
import img6 from "../../../resources/images/prizes/경영혁신/인적자원개발 우수기관 인증(2021).png";
import img7 from "../../../resources/images/prizes/경영혁신/국제e비즈니스학회_혁신경영인상.png";


export const prizesManagement = [
  {
    presenter: "고용노동부",
    content: "근무혁신 우수기업 선정",
    year: 2019,
    image: img2,
  },
  {
    presenter: "경제포커스",
    content: "중소기업 경영대상",
    year: 2019,
    image: img3,
  },
  {
    presenter: "국제e비즈니스학회",
    content: "혁신경영인상",
    year: 2020,
    image: img7,
  },
  {
    presenter: "여성가족부",
    content: "가족친화기업 선정",
    year: 2020,
    image: img4,
  },
  {
    presenter: "서울지방고용노동청",
    content: "일생활 균형캠페인 참여",
    year: 2021,
    image: img5,
  },
  {
    presenter: "한국산업인력공단",
    content: "인적자원개발 우수기관 인증",
    year: 2021,
    image: img6,
  },
  {
    presenter: "NICE평가정보",
    content: "기술평가 우수기업 인증",
    year: 2018,
    image: img1,
  },


];
