import styled from "styled-components";
import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import ProcessElement from "../../../components/ui/common/ProcessElement";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import UseBreakpoints from "../../../components/hooks/ui/UseBreakpoints";
import DiamondScope from "../../../components/ui/specialty/DiamondScope";
import WorkScopes from "../../../components/ui/specialty/WorkScopes";
import DeclarationImage from "../../../../src/resources/images/declaration.png";
// import branchImage1 from "../../../resources/images/background/intro/branch1.png";
import img1 from "../../../resources/images/icon/1. 수출입통관/1. 프로세스/1. 신고 전단계.png";
import img2 from "../../../resources/images/icon/1. 수출입통관/1. 프로세스/2. 신고내용 검증.png";
import img3 from "../../../resources/images/icon/1. 수출입통관/1. 프로세스/3. 세관심사.png";
import img4 from "../../../resources/images/icon/1. 수출입통관/1. 프로세스/4. 물품반출.png";
import img5 from "../../../resources/images/icon/1. 수출입통관/1. 프로세스/5. 사후관리.png";
import img6 from "../../../resources/images/icon/1. 수출입통관/2. 업무범위/1. 수입신고, 수출신고.png";
import img7 from "../../../resources/images/icon/1. 수출입통관/2. 업무범위/2. 법규준수도 관리.png";
import img8 from "../../../resources/images/icon/1. 수출입통관/2. 업무범위/3. 통관월별 리포트 제공.png";
import img9 from "../../../resources/images/icon/1. 수출입통관/2. 업무범위/4. 잠정가격 및 확정가격 신고.png";
import img10 from "../../../resources/images/icon/1. 수출입통관/2. 업무범위/5. 과세가격 사전심사 신청.png";
import img11 from "../../../resources/images/icon/1. 수출입통관/2. 업무범위/6. 품목분류 사전심사 신청.png";
import img12 from "../../../resources/images/icon/1. 수출입통관/2. 업무범위/7. 원산지 사전심사 신청.png";
import img13 from "../../../resources/images/icon/1. 수출입통관/2. 업무범위/8. 관세 감면 및 담보 신청.png";
import img14 from "../../../resources/images/icon/1. 수출입통관/2. 업무범위/9. 관세 동향 및 법령개정 정보 제공.png";
import {
  device,
  deviceMax,
} from "../../../style/styled-components/helpers/device";
import CenterDiv from "../../../style/styled-components/components/CenterDiv";
import ImageDiv from "../../../style/styled-components/components/ImageDiv";
import ContactPoints from "../../../components/ui/members/ContactPoints";
import AoneOrganization from "../../../components/contents/members/AoneOrganization";

const ImpexpPage = () => {
  const useBreakpoints = UseBreakpoints();
  return (
    <PageTemplate
      title="수출입통관"
      large
      phrase="AONE만의 독자적인 통관관리 시스템을 통한 신속, 정확한 통관을 제공합니다."
      image={DeclarationImage}
      description="서울 본사를 중심으로 국내 7개 지사를 운영하고 있으며, 전지사 상시 근무
      체계로 고객에게 신속한 통관서비스를 제공합니다. 각 분야 및 기업 전담팀
      운영 및 전담 관세사를 두어 고객 맞춤형 수출입통관서비스 및 자문 컨설팅
      서비스를 제공합니다. 또한 자체 개발 통관관리시스템 TCMS 를 통하여
      고객사의 수출입통관 관련 위험진단 및 정보분석 등의 통합 서비스를
      제공합니다."
    >
      <TitleTemplate
        title="수출입통관 프로세스"
        noImage
        subHeader={"통관전문가들이 각 단계에 맞는 절차를 빈틈없이 수행합니다."}
      />
      <CenterDiv>
        <ProcessGrid breakpoints={useBreakpoints}>
          <ProcessElement
            title="신고준비"
            icon={img1}
            details={["수입신고의뢰", "필요서류인도", "물품반입"]}
          />

          <ProcessElement
            title="사전검증"
            icon={img2}
            details={[
              "품목분류",
              "원산지/FTA 검토",
              "요건신고",
              "검역신고",
              "수출입신고서 작성",
              "수출입신고서 전송",
            ]}
          />
          <ProcessElement
            title="세관심사"
            icon={img3}
            details={["수출입신고 심사", "물품검사", "수출입신고 수리"]}
          />
          <ProcessElement
            title="물품반출"
            icon={img4}
            details={["배차관리", "화물반출"]}
          />
          <ProcessElement
            title="사후관리"
            icon={img5}
            details={[
              "월별 통관리포트 발송",
              "확정가격신고기한관리",
              "용도세율 물품관리",
              "재수출 기한관리",
            ]}
          />
        </ProcessGrid>
      </CenterDiv>
      <TitleTemplate
        title="업무범위"
        noImage
        subHeader={"ONESTOP SERVICE로 통관 편의와 법적안정성을 제공합니다."}
      />
      <WorkScopes
        contents={[
          { title: "수입신고", icon: img6 },
          { title: "수출신고", icon: img6 },
          { title: "법규준수도 관리", icon: img7 },
          { title: "통관월별 리포트 제공", icon: img8 },
          { title: "잠정 및 확정가격 신고", icon: img9 },
          { title: "과세가격 사전심사 신청", icon: img10 },
          { title: "수입품목분류 사전심사 신청신고", icon: img11 },
          { title: "원산지 사전심사 신청", icon: img12 },
          { title: "관세 감면 및 담보 신청", icon: img13 },
          { title: "관세동향 및 법령개정 정보제공", icon: img14 },
        ]}
      />
      <TitleTemplate
        title="Strength"
        noImage
        subHeader="A-One 만의 장점으로 고객을 힘껏 서포트 해 나가겠습니다."
      />
      <DiamondScope
        contents={[
          { title: "MAN POWER", text: "체계적이고 전문화된 인력" },
          { title: "TASK-FORCE", text: "고객사별로 전담직원을 배치" },
          {
            title: "TCMS",
            text: "자체개발 시스템을 활용한 신속 정확한 통관서비스",
          },
          {
            title: "Customized System",
            text: "고객사별 니즈에 맞춘 시스템 환경 제공",
          },
        ]}
      />
      <TitleTemplate
        title="수행 조직"
        noImage
        subHeader="지역 거점에 위치한 5개의 총괄본부와 전국 2개 지사로 빈틈없는 관세서비스를 제공하고 있습니다"
      />
      <AoneOrganization />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["son0077", "k1234"]} />
    </PageTemplate>
  );
};
export default ImpexpPage;
const ProcessGrid = styled.div`
  display: flex;
  flex-direction: column;
  @media ${deviceMax.laptop} {
    gap: 1rem;
    align-items: flex-start;
  }
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

const BranchImage = styled.img`
  width: 45rem;
`;
