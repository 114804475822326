import ContactPoints from "../../../components/ui/members/ContactPoints";
import HexagonalFour from "../../../components/ui/common/template/hexagonal/HexagonalFour";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import PentagonScope from "../../../components/ui/specialty/PentagonScope";
import RequirementsImage from "../../../../src/resources/images/requirements.jpg";
import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import WorkScopes from "../../../components/ui/specialty/WorkScopes";
import img1 from "../../../resources/images/icon/4. 요건/1. 수출입요건 사전 검토서비스.png";
import img2 from "../../../resources/images/icon/4. 요건/2. 수출입 요건 신청면제신고 대행.png";
import img3 from "../../../resources/images/icon/4. 요건/3. 업체별 인증품목 관리.png";
import img4 from "../../../resources/images/icon/4. 요건/4. 전기전파KC인증시험대리.png";
import img5 from "../../../resources/images/icon/4. 요건/5. 생활용품 어린이 제품 KC 인증시험 대리.png";
import img6 from "../../../resources/images/icon/4. 요건/6. 연간 재활용 폐기물 신고대행.png";
import styled from "styled-components";
const RequirementPage = () => {
  return (
    <PageTemplate
      large
      image={RequirementsImage}
      title="인증요건"
      phrase="전문 인증요건팀과 함께하는 철저한 수출입 요건 관리를 약속합니다."
      description="수출입 시 각 물품에 대한 세관장 확인 대상 및 통합공고 대상을 확인 및
      인증요건 신청을 대리하여 기업의 관리 편의를 제공하며 통관 요건 및 인증에
      대한 RISK를 최소화합니다. 인증요건 전담팀 별도 운영을 통한
      체계적인 서비스로 고객만족을 제공해드립니다."
    >
      <TitleTemplate
        title="요건 구비"
        subHeader="HSK 별로 세분화된 요건/인증 검토를 통하여 법적안정성을 보장합니다."
        noImage
      />
      <HexagonalDiv className="hexagonal">
        <HexagonalFour
          title="수출입 요건"
          data={[
            {
              title: "수출입 공고",
              text: "수출입제한품목은 각 품목별 요령에 따라 승인 받은 경우 수출입 할 수 있다.",
            },
            {
              title: "통합공고",
              text: "약사법 등 64개 법령에서 정하는 수출입절차 또는 요령 통합공고에서 정한 요건 확인 내용과 수출입공고 제한 내용이 동시에 적용되는 경우 모두 충족하여야 수출입할 수 있다.",
            },
            {
              title: "세관장 확인",
              text: "관세법 제226조 따라 수출입 할 때 허가, 승인, 표시, 기타 조건 등 필요한 사항을 확인하는 것으로 마약류관리에 관한 법률 등 38개 법령에 따른다 세관장 확인이 생략되는 물품에 해당하는 경우라도, 일정 법령의 적용을 받는 물품은 세관장이 수출입요건 구비여부를 확인한다",
            },
          ]}
        />
      </HexagonalDiv>
      <TitleTemplate
        title="업무 범위"
        subHeader="각각의 개별법에서 정하는 모든 요건을 확인하고 준수하도록 서포트 합니다."
        noImage
      />
      <WorkScopes
        contents={[
          { title: "수출입요건 사전 검토 서비스", icon: img1 },
          { title: "수출입요건 신청/면제신고 대행", icon: img2 },
          { title: "업체별 인증품목 관리", icon: img3 },
          { title: "전기/전파 KC 인증시험 대리", icon: img4 },
          { title: "생활용품/어린이 제품 KC 인증시험 대리", icon: img5 },
          { title: "연간 재활용/폐기물 신고대행", icon: img6 },
        ]}
      />
      <TitleTemplate
        title="Strength"
        noImage
        subHeader="A-One 만의 장점으로 고객을 힘껏 서포트 해 나가겠습니다."
      />
      <PentagonalDiv>
        <div />
        <PentagonScope
          contents={[
            {
              title: "개정 법령 모니터링",
              text: "통합공고 64개 법령에 대한 Daily 개별 개정 법령 모니터링",
            },
            { title: "TASK-FORCE", text: "인증요건팀 전담 운영" },
            {
              title: "TCMS",
              text: "자체개발 시스템을 활용한 체계적 인증제품 관리",
            },
            {
              title: "전문 인증 서비스",
              text: "인증시험기관과 개별 계약 체결",
            },
            {
              title: "다양한 경험 보유",
              text: "다양한 품목의 인증시험 대행 경험 보유",
            },
          ]}
        />
      </PentagonalDiv>
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["jhpark0113"]} />
    </PageTemplate>
  );
};
export default RequirementPage;
const PentagonalDiv = styled.div`
  position: relative;
  & > div:first-child {
    width: 100%;
    max-width: 100vw;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: radial-gradient(#ace7 20%, transparent 20%),
      radial-gradient(#ace7 20%, transparent 20%);
    background-color: transparent;
    background-position: 0 0, 3px 3px;
    background-size: 6px 6px;
    opacity: 0.6;
    top: -8rem;
    height: 30rem;
    border-radius: 3em;
  }
`;
const HexagonalDiv = styled.div`
  margin-top: 4rem;
`;
