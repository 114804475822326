import UseBreakpoints from "../../../../components/hooks/ui/UseBreakpoints";
import PageTemplate from "../../../../components/ui/common/template/PageTemplate";
import TitleTemplate from "../../../../components/ui/common/template/TitleTemplate";
import AgreementContentStyle from "../../../../style/styled-components/components/consulting/fta/AgreementContentStyle";
import AgreementHeaderStyle from "../../../../style/styled-components/components/consulting/fta/AgreementHeaderStyle";
import AgreementTableStyle from "../../../../style/styled-components/components/consulting/fta/AgreementTableStyle";
import AgreementTemplateStyle from "../../../../style/styled-components/components/consulting/fta/AgreementTemplateStyle";
import Flag from "../../../../resources/images/flags/asean.png";
import SingaporeFlag from "../../../../resources/images/flags/singapore.webp";
import BruneiFlag from "../../../../resources/images/flags/brunei.webp";
import CambodiaFlag from "../../../../resources/images/flags/cambodia.webp";
import LaosFlag from "../../../../resources/images/flags/laos.webp";
import ThailandFlag from "../../../../resources/images/flags/thailand.webp";
import VietnamFlag from "../../../../resources/images/flags/vietnam.webp";
import MalaysiaFlag from "../../../../resources/images/flags/malaysia.webp";
import MyanmarFlag from "../../../../resources/images/flags/myanmar.webp";
import PhilippinesFlag from "../../../../resources/images/flags/philippines.webp";
import IndonesiaFlag from "../../../../resources/images/flags/indonesia.webp";
const ASEANPage = () => {
  const breakpoints = UseBreakpoints();
  return (
    <PageTemplate>
      <AgreementTableStyle breakpoints={breakpoints}>
        <TitleTemplate title="협정별 규정" noImage />
        <AgreementTemplateStyle breakpoints={breakpoints}>
          <div>
            <div>
                <img src={Flag} alt="국기" />
              <div>
                <img src={BruneiFlag} alt="국기" />
                <img src={CambodiaFlag} alt="국기" />
                <img src={IndonesiaFlag} alt="국기" />
                <img src={LaosFlag} alt="국기" />
                <img src={MalaysiaFlag} alt="국기" />
                <img src={MyanmarFlag} alt="국기" />
                <img src={PhilippinesFlag} alt="국기" />
                <img src={SingaporeFlag} alt="국기" />
                <img src={ThailandFlag} alt="국기" />
                <img src={VietnamFlag} alt="국기" />
              </div>
            </div>
            <div>
              브루나이, 캄보디아, 인도네시아, 라오스, 말레이시아
              <br />
              필리핀, 싱가포르, 태국, 베트남
            </div>
          </div>
          <div>
            <div>
              <span>한-ASEAN FTA 적용요건</span>
            </div>
            <div>
              <div>
                <AgreementHeaderStyle borderRadius="1rem 0 0 0">원산지증명서</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>발급방식</div>
                  <div>기관</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>발급주체</div>
                  <div>
                    아세안-정부기관
                    <br />
                    한국-세관, 상공회의소
                  </div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>서식</div>
                  <div>통일서식(AK서식)</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>유효기간</div>
                  <div>1년</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>제출생략 가능</div>
                  <div>
                    FOB 200달러 이하
                    <br />
                    상품 또는 우편물
                  </div>
                </AgreementContentStyle>
                <AgreementHeaderStyle>원산지검증</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>검증방식</div>
                  <div>원칙 간접, 예외 직접</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>검증주체</div>
                  <div>
                    한국-수출국세관
                    <br />
                    아세안-검증기관, 세관
                  </div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>회신기간</div>
                  <div>2개월(6개월 범위내 연장 가능)</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>회신주체</div>
                  <div>
                    한국-세관
                    <br />
                    아세안-발급기관
                  </div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>미회신조치</div>
                  <div>협정관세 제한</div>
                </AgreementContentStyle>
                <AgreementHeaderStyle>부가가치계산</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>계산방법</div>
                  <div>공제법, 집적법</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>부가가치기준</div>
                  <div>RVC(35~60%)</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>기준가격</div>
                  <div>FOB</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>비고</div>
                  <div>
                    공제법과 집적법 중 국가 선택
                    <br />
                    (한국-공제법)
                  </div>
                </AgreementContentStyle>
                <AgreementHeaderStyle>최소허용기준</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>일반품목(가격기준)</div>
                  <div>FOB 10%이하</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>농수산물(가격기준)</div>
                  <div>10%이하</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>섬유(중량기준)</div>
                  <div>10%이하</div>
                </AgreementContentStyle>
              </div>
              <div>
                <AgreementHeaderStyle borderRadius="0 1rem 0 0">중간재</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>인정여부</div>
                  <div>×(국내법에서는 인정)</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>대상물품</div>
                  <div>×</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>지정의무</div>
                  <div>×</div>
                </AgreementContentStyle>
                <AgreementHeaderStyle>
                  부속품, 예비부분품, 공구
                </AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>세번변경기준</div>
                  <div>본체의 원산지 판정시 고려하지 않음</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>부가가치기준</div>
                  <div>고려하지 않음</div>
                </AgreementContentStyle>
                <AgreementHeaderStyle>소매포장</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>세번변경기준</div>
                  <div>본체의 원산지 판정시 고려하지 않음</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>부가가치기준</div>
                  <div>원산지별 구분계상</div>
                </AgreementContentStyle>
                <AgreementHeaderStyle>세트물품</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>인정여부</div>
                  <div>×</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>비원산지 허용한도</div>
                  <div>×</div>
                </AgreementContentStyle>
                <AgreementHeaderStyle>운송</AgreementHeaderStyle>
                <AgreementContentStyle>
                  <div>직접운송규정</div>
                  <div>○</div>
                </AgreementContentStyle>
                <AgreementContentStyle>
                  <div>운송요건</div>
                  <div>
                    지리적 운송상 이유
                    <br />
                    경유국거래, 소비 금지
                    <br />
                    하역, 재선적, 상품보존
                    <br />
                    통관선하증권
                  </div>
                </AgreementContentStyle>
              </div>
            </div>
          </div>
        </AgreementTemplateStyle>
      </AgreementTableStyle>
    </PageTemplate>
  );
};
export default ASEANPage;
