import { Link } from "react-router-dom";
import styled from "styled-components";
import UseBreakpoints from "../../../../hooks/ui/UseBreakpoints";
const BranchElement = ({ location, setLocation, selectedBranch }) => {
  const breakpoints = UseBreakpoints();
  return (
    <Div>
      <Link to="#">
        <LocationDiv
          selectedBranch={selectedBranch}
          location={location}
          breakpoints={breakpoints}
          onClick={(e) => {
            setLocation(e.target.innerText);
          }}
        >
          {location}
        </LocationDiv>
      </Link>
    </Div>
  );
};
export default BranchElement;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & a {
    text-decoration: none;
    color: inherit;
  }
`;
const LocationDiv = styled.div`
display: flex;
justify-content: center;
  padding: 0.5em ${({ breakpoints }) => breakpoints?.md ? "1rem" : "0rem"};
  flex: 1;
  font-size: ${({ breakpoints }) => breakpoints?.sm ? "1.6em" : "1.3em"};
  font-weight: bold;
  color: ${({ selectedBranch, location, theme }) =>
    selectedBranch === location
      ? theme.colors?.foreground.inverted
      : "inherit"};
  background-color: ${({ selectedBranch, location, theme }) =>
    selectedBranch === location ? theme.colors?.dark : "inherit"};
  min-width: ${({ breakpoints }) => (breakpoints?.md ? "7rem" : "6rem")};
  border-radius: 1em;
`;
