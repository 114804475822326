import styled from "styled-components";
const PrizeTemplate = ({ presenter, content, year, image }) => {
  return (
    <Div>
      <div>
        <div />
        <PrizeImage src={image} />
      </div>
      <PresenterSpan>{presenter}</PresenterSpan>
      <ContentSpan>{content}</ContentSpan>
      <YearSpan>({year})</YearSpan>
    </Div>
  );
};
export default PrizeTemplate;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 13em;
  font-size: 1.2em;
  & > div:first-child {
    position: relative;
    & > div:first-child {
      position: absolute;
      background-color: #ddd;
      transform: skewX(-7deg);
      width: 83%;
      height: 84%;
      margin-top: 1em;
      margin-left: .9em;
    }
  }
`;
const PrizeImage = styled.img`
position: relative;
  margin: 1em;
  height: 12em;
  width: 9em;
  border: 1px solid #ddd;
`;
const PresenterSpan = styled.span`
  font-weight: bold;
`;
const ContentSpan = styled.span`
  width: em;
  text-align: center;
`;
const YearSpan = styled.span``;
