import styled from "styled-components";
import { device } from "../../../../style/styled-components/helpers/device";
const ExpressUnderstandingType = ({ type, contents }) => {
  return (
    <Div>
      <div>
        <div />
        <div />
        <div />
      </div>
      <div className="type">{type}</div>
      <ul className="list">
        {contents?.map((x, idx) => {
          return <li key={idx}>{x}</li>;
        })}
      </ul>
      <div />
    </Div>
  );
};
export default ExpressUnderstandingType;
const Div = styled.div`
position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #888;
  padding-top: 2.5em;
  & > div:first-child {
    position: relative;
    z-index: 1;
    & > div:first-child {
      width: 0;
      border-bottom: 7px solid #66c;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
    & > div:nth-child(2) {
      width: 20px;
      height: 12px;
      background-color: #66c;
    }

    & > div:last-child {
      width: 0;
      border-top: 7px solid #66c;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }
  & > div.type {
    font-size: 1.2em;
    @media ${device.mobileL} {
      font-size: 1.5em;
    }
    @media ${device.tablet} {
      font-size: 2em;
    }
    font-weight: bold;
  }
  & > div:last-child {
      position: absolute;
      width: 100%;
      height: 2.6em;
      top: .6em;
      border-style: dashed;
      border-top-width: 0;
      border-left-width: 1px;
      border-right-width: 1px;
      border-bottom-width: 1px;
      
    }
    & > ul.list {
      padding-left: 1.5em;
      font-size: max(0.8em, 1.8vw);
    @media ${device.tablet} {
      font-size: min(1.65vw, 1em);
      /* font-size: 1em; */
    }
    @media ${device.laptopL} {
      font-size: 1.2em;
    }
    }
`;
