export const chapter2 = {
  title: "비전2020과 Global",
  range: "2011~2018",
  years: [
    {
      year: 2011,
      actions: [
        "울산지사 설치",
        "New Vision 2020 선포",
        "국세청 조사모범납세자 표창",
      ],
    },
    {
      year: 2012,
      actions: [
        "미국 B&H관세법인과 FTA MOU체결",
        "E-Learning 교육시스템 개발",
        "선택적 복리후생제도 도입",
        "국제심포지엄 [FTA 원산지 검증] 개최",
      ],
    },
    {
      year: 2013,
      actions: [
        "서울여상과 [산학협력 협약] 체결",
        "대한상의와 [FTA 활용지원을 위한 자문업무 협약] 체결",
        "특송 통관업무 개시(김포)",
        "AEO 등급 상향(A =>AA)",
      ],
    },
    {
      year: 2014,
      actions: [
        "TIPA와 지재권보호 협업 MOU 체결",
        "이탈리아 로펌 Jus & Trad 와 관세 분야 협업 MOU 체결",
      ],
    },
    {
      year: 2015,
      actions: [
        "인도네시아 SPL Logistics와 관세 협력 MOU체결",
        "AEO협회와 AEO 공인획득 지원사업 MOU 체결",
        "FTA GTEC 세미나 (미·중. EU 3회) 개최",
        "베트남 PTV와 관세 협력 MOU 체결",
      ],
    },
    {
      year: 2016,
      actions: [
        "중국 Xinhai 관세법인과 관세 협력 MOU체결",
        "AEO 공인획득 지원사업 평가 결과 컨설팅기관 1위 획득",
        "평택지사 신사옥 준공",
        "검역 전문회사 Q&L 창업",
        "한·중 FTA 파트너쉽 유공 대상 수상(산업통상자원부장관)",
      ],
    },
    {
      year: 2017,
      actions: [
        "평가, 품목분류, 외환 전문가 그룹 구성",
        "세액 정산제도 전담팀 구성",
      ],
    },
    {
      year: 2018,
      actions: ["AEO AA등급 재인증", "통합신고 센터 설치"],
    },
  ],
};
