import AuditExperienceTable from "../../../components/contents/specialty/legal/customs/AuditExperienceTable";
import AuditPointsTable from "../../../components/contents/specialty/legal/customs/AuditPointsTable";
import CenterDiv from "../../../style/styled-components/components/CenterDiv";
import ContactPoints from "../../../components/ui/members/ContactPoints";
import ContentDiv from "../../../style/styled-components/components/ContentDiv";
import CustomsImage from "../../../resources/images/customs_legal.jpg";
import DiamondScope from "../../../components/ui/specialty/DiamondScope";
import HeadedBox from "../../../components/ui/common/template/HeadedBox";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import styled from "styled-components";
const CustomsPage = () => {
  return (
    <PageTemplate
      large
      image={CustomsImage}
      title="관세심사"
      phrase="심사전문 컨설팅 그룹의 전문성과 책임감있는 심사대리 서비스를 제공합니다."
    >
      <p>
        정기적으로 기업이 수검받는 종합심사, 법인 심사, 기획심사의 경우 수입시
        납부한 관세뿐만아니라 환급, 외환 과태료와 더불어 수입관련법령에 근거한
        처벌 조항까지 폭넓게 리스크가 발생할 수 있으므로 전문적인 대리인의
        대응이 필수적입니다.
        <br /> 에이원은 다양한 경험을 통하여 검증된 대리인이 고객사와 함께 하며
        기업 리스크 안정화와 최소화를 위하여 업무를 수행합니다.
      </p>
      <TitleTemplate title="관세심사 종류" noImage />
      <CenterDiv>
        <AuditTypeDiv>
          <HeadedBox
            div="종합심사"
            subdiv="AEO공인업체 대상"
            text="AEO(수출입안전관리 우수업체)를 대상으로 
5년마다 주기적으로 과세가격, 품목분류, 관세환급, 외국환거래의 
적정성 등을 주요 심사항목으로 통관적법성을 확인하는 절차"
          />
          <HeadedBox
            div="법인심사"
            subdiv="비 AEO업체 대상"
            text="비 AEO 업체를 대상으로하는 정기적 사후심사제도로 과세가격, 품목분류, 관세환급, 외국환거래의 적정성 등을 주요 심사항목으로 통관적법성에 대하여 기업단위로 적정성을 확인하는 절차
        "
          />
          <HeadedBox
            div="기획심사"
            subdiv="비 AEO업체 대상"
            text="사후심사제도로 정보분석을 통하여 
        혐의가 의심되는 항목에 대하여 적정성을 확인하는 절차"
          />
        </AuditTypeDiv>
      </CenterDiv>
      <TitleTemplate title="주요 심사 POINT" noImage />
      <CenterDiv>
        <AuditPointsTable />
      </CenterDiv>
      <TitleTemplate title="풍부한 컨설팅 경험" noImage />
      <CenterDiv>
        <ContentDiv>
          <AuditExperienceTable />
        </ContentDiv>
      </CenterDiv>
      <TitleTemplate
        title="Strength"
        noImage
        subHeader="고객의 관세업무 리스크 통제를 위하여 관세 분야별 전문가 그룹을 통한 컨설팅을 수행합니다.."
      />
      <DiamondScope
        contents={[
          {
            title: "관세업무 수행 경력",
            text: "전기전자, 화학, 식품, 위류 등 전 산업에 대상으로 300여개 업체의 컨설팅 수행경력 보유",
          },
          {
            title: "MAN-POWER",
            text: "세관에서 경력을 쌓은 전문가, 업계 최고 관세사로 구성된 심사 전문 컨설팅 그룹",
          },
          {
            title: "분야별 전문 컨설팅",
            text: "관세 전문가 그룹을 통한 분야별 맞춤형 전문 컨설팅 수행",
          },
          {
            title: "관세 자문단 보유",
            text: "관세청 관세평가분류원장, 중앙관세분석소장 등 역임한 국내 최고 관세분야별 전문인력 보유",
          },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["customsAuditMain"]} />
    </PageTemplate>
  );
};
export default CustomsPage;
const AuditTypeDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;
