import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import UseBreakpoints from "../../../components/hooks/ui/UseBreakpoints";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import EthicalManagement from "../../../components/ui/intro/esg/EthicalManagement";
import QualityManagement from "../../../components/ui/intro/esg/QualityManagement";
import ValueImprovement from "../../../components/ui/intro/esg/ValueImprovement";
import ESGImage from "../../../resources/images/background/intro/esg.png";
import { Link } from "react-scroll";
import UseScroll from "../../../components/hooks/ui/UseScroll";
const ESGPage = () => {
  const breakpoints = UseBreakpoints();
  const scroll = UseScroll();
  const posRefs = [useRef(), useRef(), useRef()];
  // X
  const [sectionPos, setSectionPos] = useState([]);
  const [inSection, setInSection] = useState([]);
  // This function calculate X and Y

  // Get the position of the red box in the beginning
  useEffect(() => {
    const getPosition = () => {
      setSectionPos(
        posRefs?.map((ref) => {
          return {
            x: ref.current?.offsetLeft ? ref.current?.offsetLeft : 0,
            y: ref.current?.offsetTop ? ref.current?.offsetTop : 0,
          };
        })
      );
    };
    getPosition();
    window.addEventListener("resize", getPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Re-calculate X and Y of the red box when the window is resized by the user
  useEffect(() => {
    setInSection([
      scroll?.y >= sectionPos[0]?.y - 388 && scroll?.y < sectionPos[1]?.y - 388,
      scroll?.y >= sectionPos[1]?.y - 388 && scroll?.y < sectionPos[2]?.y - 388,
      scroll?.y >= sectionPos[2]?.y - 388,
    ]);
  }, [sectionPos, scroll]);
  return (
    <PageTemplate
      title="지속가능한 경영"
      phrase="업무환경 선진화에 따른 임직원 가치향상을 위해 노력합니다."
      image={ESGImage}
      large
    >
      <ContentDiv>
        <SelectDiv breakpoints={breakpoints} inSection={inSection}>
          <Link smooth offset={-170} to="valueSection">
            임직원 가치향상
          </Link>
          <Link smooth offset={-170} to="ethicalSection">
            윤리경영
          </Link>
          <Link smooth offset={-170} to="qualitySection">
            품질경영
          </Link>
        </SelectDiv>
        <section id="section-1" name="valueSection" ref={posRefs[0]}>
          <TitleTemplate title="임직원 가치향상" noImage />
          <ValueImprovement />
        </section>
        <section id="section-2" name="ethicalSection" ref={posRefs[1]}>
          <TitleTemplate title="윤리경영" noImage />
          <EthicalManagement />
        </section>
        <section id="section-3" name="qualitySection" ref={posRefs[2]}>
          <TitleTemplate title="품질경영" noImage />
          <QualityManagement />
        </section>
      </ContentDiv>
    </PageTemplate>
  );
};
export default ESGPage;
const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const SelectDiv = styled.div`
  position: sticky;
  z-index: 2;
  top: 5rem;
  max-width: 100vw;
  display: flex;
  flex-direction: ${({ breakpoints }) => (breakpoints?.lg ? "row" : "row")};
  background-color: white;
  padding: 1em;
  justify-content: space-evenly;
  gap: ${({ breakpoints }) =>
    breakpoints.xl ? "2em" : breakpoints?.md ? "1em" : ".5em"};
  & a {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    background-color: ${({ theme }) => theme?.colors?.background?.dark};
    color: white;
    padding: ${({ breakpoints }) => (breakpoints?.md ? "1em" : ".5em")};
    width: ${({ breakpoints }) => (breakpoints?.sm ? "7em" : "6em")};
    font-size: ${({ breakpoints }) =>
      breakpoints.xl ? "1.8em" : breakpoints?.md ? "1.5em" : "1.2em"};
    font-weight: bolder;
    &:first-child {
      background-color: ${({ theme, inSection }) =>
        inSection[0]
          ? theme?.colors?.background?.dark
          : theme?.colors?.background?.light};
    }
    &:nth-child(2) {
      background-color: ${({ theme, inSection }) =>
        inSection[1]
          ? theme?.colors?.background?.dark
          : theme?.colors?.background?.light};
    }
    &:nth-child(3) {
      background-color: ${({ theme, inSection }) =>
        inSection[2]
          ? theme?.colors?.background?.dark
          : theme?.colors?.background?.light};
    }
  }
`;
