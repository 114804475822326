import styled from "styled-components";
import UseBreakpoints from "../../../../hooks/ui/UseBreakpoints";
const BranchSimpleMapSubway = ({ subway }) => {
  const breakpoints = UseBreakpoints();
  return (
    <Div breakpoints={breakpoints}>
      {subway && (
        <>
          <div className="title">
            <div>지하철 이용 시</div>
            <hr />
          </div>
          <ul>
            {subway?.map((x, idx) => (
              <li key={idx}>
                <div className="line">
                  <SubwayLine city={x.city} lineNo={x.lineNo}>
                    {x.lineNo}
                  </SubwayLine>
                </div>
                {` ${x.station}역 ${x.exit}번 출구 ${x.direction} 방향 ${x.distance}`}
              </li>
            ))}
          </ul>
        </>
      )}
    </Div>
  );
};
export default BranchSimpleMapSubway;
const subwayColor = {
  수도권: {
    1: { background: "navy", foreground: "inverted" },
    2: { background: "green", foreground: "inverted" },
    3: { background: "orange", foreground: "inverted" },
    4: { background: "blue", foreground: "inverted" },
    5: { background: "purple", foreground: "inverted" },
    6: { background: "brown", foreground: "inverted" },
    7: { background: "darkolivegreen", foreground: "inverted" },
    8: { background: "pink", foreground: "inverted" },
    9: { background: "#d7c700", foreground: "inverted" },
    분당선: { background: "yellow", foreground: "normal" },
    공항철도: { background: "skyblue", foreground: "inverted" },
  },

  부산: {
    1: { background: "orange", foreground: "inverted" },
    2: { background: "green", foreground: "inverted" },
    3: { background: "gold", foreground: "inverted" },
    4: { background: "blue", foreground: "inverted" },
    동해: { background: "skyblue", foreground: "inverted" },
    부산김해: { background: "purple", foreground: "inverted" },
  },
};

const Div = styled.div`
  padding: 1em;
  & > div {
    &.title {
      font-size: 1.5em;
      font-weight: bold;
      & > div {
        padding: 0 1em;
      }
    }
  }
  & > ul {
    display: flex;
    flex-direction: column;
    gap: 1em;
    list-style: none;
    padding: 1rem 2rem;
    & > li {
      display: flex;
      gap: 1em;
      & > div.line {
        display: flex;
        align-items: flex-start;
        min-width: ${({ breakpoints }) => (breakpoints.sm ? "5em" : "3.5em")};
      }
    }
  }
`;
const SubwayLine = styled.div`
  color: ${({ city, lineNo, theme }) =>
    theme.colors.foreground[subwayColor[city][lineNo].foreground]};
  background-color: ${({ city, lineNo }) =>
    subwayColor[city][lineNo].background};
  padding: 0 0.5em;
  border-radius: 1em;
`;
