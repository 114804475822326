import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getMemberbyId } from "../../context/members/membercontext";
const ProfilePage = ({ match }) => {
  const { id } = useParams();
  const [currentUser, setCurrentUser] = useState({});
  console.log(currentUser);
  useEffect(() => {
    setCurrentUser(getMemberbyId(id));
  }, []);
  return <Div><ProfileImage src={currentUser?.image}/>{currentUser?.name} {currentUser?.grade}</Div>;
};
export default ProfilePage;
const Div = styled.div``;

const ProfileImage = styled.img``;