import styled from "styled-components";
import { device } from "../helpers/device";
export default styled.div`
    width: 90vw;
  @media ${device.tablet} {
    width: 40rem;
  }
  @media ${device.laptop}{
    width: 55rem;
  }
  @media ${device.laptopL}{
    width: 65rem;
  }
`;
