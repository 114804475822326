import Img1 from "../../resources/images/profiles/hq/정운기회장님3.jpg";
//import Img_sj6291 from "../../resources/images/profiles/other/이신재_울산_trim-removebg.png";
import Img123 from "../../resources/images/profiles/other/울산 정진국.png";
//import Img20 from "../../resources/images/profiles/hq/박현우님.jpg";
//import Img23 from "../../resources/images/profiles/hq/신민화님.jpg";
import Img15 from "../../resources/images/profiles/hq/전진아님.jpg";
import Img16 from "../../resources/images/profiles/hq/하창길님.jpg";
import Img17 from "../../resources/images/profiles/hq/강동윤본부장님.jpg";
import Img18 from "../../resources/images/profiles/incheon/송윤규.jpg";
import Img2 from "../../resources/images/profiles/hq/황재호님.jpg";
import Img21 from "../../resources/images/profiles/hq/손은정.png";
import Img22 from "../../resources/images/profiles/hq/오영록님.jpg";
import Img25 from "../../resources/images/profiles/hq/유정님.jpg";
import Img26 from "../../resources/images/profiles/hq/양민주님.jpg";
import Img28 from "../../resources/images/profiles/other/강태인.jpg";
//import Img29 from "../../resources/images/profiles/hq/심응선님.jpg";
import Img30 from "../../resources/images/profiles/hq/박성우님.jpg";
import Img32 from "../../resources/images/profiles/hq/손영희님.jpg";
import Img35 from "../../resources/images/profiles/other/구미 정성락.jpg";
import Img36 from "../../resources/images/profiles/incheon/김교일.jpg";
import Img37 from "../../resources/images/profiles/incheon/김의걸.jpg";
import Img38 from "../../resources/images/profiles/incheon/어대경.jpg";
import Img39 from "../../resources/images/profiles/busan/김진숙-modified.png";
import Img4 from "../../resources/images/profiles/hq/박찬욱님.jpg";
import Img40 from "../../resources/images/profiles/incheon/송영규.png";
import Img41 from "../../resources/images/profiles/busan/박현욱_modified.png";
import Img42 from "../../resources/images/profiles/busan/부승길.jpg";
import Img46 from "../../resources/images/profiles/incheon/김수연.jpg";
import Img47 from "../../resources/images/profiles/hq/정현균님.png";
import Img48 from "../../resources/images/profiles/hq/백준일님.jpg";
import Img49 from "../../resources/images/profiles/평택 김동희.JPG";
import Img5 from "../../resources/images/profiles/busan/이성욱.png";
import Img50 from "../../resources/images/profiles/유길석.png";
import Img6 from "../../resources/images/profiles/hq/김기영님.jpg";
// import Img19 from "../../resources/images/profiles/busan/이흥대.jpg";
import Img_ccam7413 from "../../resources/images/profiles/busan/이흥대_modified.png";
import Img_chobok5079 from "../../resources/images/profiles/hq/조보규님.jpg";
import Img_freelencer78 from "../../resources/images/profiles/busan/김주남_modified.png";
//import Img12 from "../../resources/images/profiles/장희석.png";
import Img_jdbaek from "../../resources/images/profiles/other/창원 조동백_modified.png";
import Img_jung3515 from "../../resources/images/profiles/busan/정광석_modified.png";
import Img_khk0220 from "../../resources/images/profiles/hq/김현경님.jpg";
import Img_kht3073 from "../../resources/images/profiles/hq/김현태님.jpg";
import Img_lily93 from "../../resources/images/profiles/busan/장희정-modified.png";
import Img_woon4545 from "../../resources/images/profiles/busan/박종운_modified.png";
import Img_yjkim from "../../resources/images/profiles/hq/김윤진님.jpg";
import img_dongyoung from "../../resources/images/profiles/hq/이동영님.jpg";
import img_gilho from "../../resources/images/profiles/hq/임길호님.jpg";
import img_sunggun from "../../resources/images/profiles/noimage.jpg";
import img_suyun from "../../resources/images/profiles/incheon/김수연.jpg";
import img_yeonghwan from "../../resources/images/profiles/hq/김영환님.jpg";
import img_jhLee from "../../resources/images/profiles/other/이종혁관세사님.jpg";
import img_meenss from "../../resources/images/profiles/hq/강민수팀장님.png";
import img_jhpark0113 from "../../resources/images/profiles/hq/박지훈님.png";
//import Img31 from "../../resources/images/profiles/hq/유병찬님.jpg";

// import Img_myname4 from "../../resources/images/profiles/hq/김동규.png";

export const emptyObject = {
  id: "",
  name: "",
  grade: "",
};
export const memberArray = [
  {
    name: "",
    grade: "행정쟁송 담당자",
    tel: "02-2017-3300",
    id: "contestationMain",
    email: "aone-trade@aonecustoms.com",
    division: false,
    spec: [],
    history: [],
    Chinesehistory: [],
    // image: logo
  },
  {
    name: "",
    grade: "요건 담당자",
    tel: "02-2017-3300",
    id: "requirementMain",
    email: "aone-trade@aonecustoms.com",
    division: false,
    spec: [],
    history: [],
    Chinesehistory: [],
    // image: logo
  },
  {
    name: "",
    grade: "담당자",
    tel: "02-2017-3300",
    id: "vacant",
    email: "aone-trade@aonecustoms.com",
    division: false,
    spec: [],
    history: [],
    Chinesehistory: [],
  },
  {
    name: "",
    grade: "AEO 담당자",
    tel: "02-2017-3300",
    id: "AEOMain",
    email: "aone-trade@aonecustoms.com",
    division: false,
    spec: [],
    history: [],
    Chinesehistory: [],
    // image: logo
  },
  {
    name: "",
    grade: "ACVA 담당자",
    tel: "02-2017-3300",
    id: "ACVAMain",
    email: "aone-trade@aonecustoms.com",
    division: false,
    spec: [],
    history: [],
    Chinesehistory: [],
    // image: logo
  },
  {
    name: "",
    grade: "품목분류 담당자",
    tel: "02-2017-3300",
    id: "classificationMain",
    email: "aone-trade@aonecustoms.com",
    division: false,
    spec: [],
    history: [],
    Chinesehistory: [],
    // image: logo
  },
  {
    name: "",
    grade: "기업 진단 전문가",
    tel: "02-2017-3300",
    id: "diagnosticsMain",
    email: "aone-trade@aonecustoms.com",
    division: false,
    spec: [],
    history: [],
    Chinesehistory: [],
    // image: logo
  },
  {
    name: "",
    grade: "환급 담당자",
    tel: "02-2017-3300",
    id: "drawbackMain",
    email: "aone-trade@aonecustoms.com",
    division: false,
    spec: [],
    history: [],
    Chinesehistory: [],
    // image: logo
  },
  {
    name: "",
    grade: "외환조사/검사 담당자",
    tel: "02-2017-3300",
    id: "forexMain",
    email: "aone-trade@aonecustoms.com",
    division: false,
    spec: [],
    history: [],
    Chinesehistory: [],
    // image: logo
  },
  {
    name: "",
    grade: "관세심사 담당자",
    tel: "02-2017-3300",
    id: "customsAuditMain",
    email: "aone-trade@aonecustoms.com",
    division: false,
    spec: [],
    history: [],
    Chinesehistory: [],
    // image: logo
  },
  {
    name: "",
    grade: "기업교육 담당자",
    tel: "02-2017-3300",
    id: "corpEduMain",
    email: "aone-trade@aonecustoms.com",
    division: false,
    spec: [],
    history: [],
    Chinesehistory: [],
    // image: logo
  },
  {
    name: "정운기",
    grade: "회장",
    tel: "02-2017-3300",
    id: "woonki21",
    division: false,
    spec: ["관세정책"],
    organization: "임원",
    advisory: ["관세정책"],
    Chinesehistory: [],
    history: [
      "관세법인 에이원 대표(현재)",
      "제17회 행정고시 합격",
      "주 홍콩 총영사관 영사, 관세청 관세중앙분석소장(부이사관)",
      "관세동우회 회장(현재)",
      "주미 한국대사관 관세참사관(이사관) 등",
      // "목포 세관장, 제주 세관장, 관세청 비서관, 총괄징수과장, 심리과장",
      // "김포세관장(이사관), 한국관세무역개발원 이사장",
      // "관우회 이사장(3년 임기만료)",
      // "관세청 초일류세관추진위원회 위원, 한국관세학회 고문(현재), 한국관세학회 부회장, 관세청 관세행정발전 자문위원",
      // "재경부 FIU 자문위원, 케이티넷 고객만족위원회 위원장, 한국관세사회 회장(2년 임기만료)",
      // "한국무역관련지식재산권협회 자문위원(현재), 법원 전문심사관, 세정신문 논설위원(현재), 한국AEO진흥협회부회장 ",
      // "관세발전 실무위원회 자문위원(현재)관세행정발전심의위원회 회원(현재), 유네스코 한국위원회 홍보개발위원(현재)",
    ],
    image: Img1,
  },
  {
    name: "황재호",
    grade: "대표관세사",
    tel: "02-2017-3303",
    id: "ezpass",
    spec: [],
    division: false,
    organization: "임원",
    history: [
      "고려종합국제운송",
      "에이원 무역관세사법인",
      "에이원 무역관세사법인 재직(관세법인에이원)",
      "관세법인 에이원인천권총괄본부장",
      "제16회 관세사자격시험 합격",
    ],
    ChineseCheck: false,
    Chinesehistory: [
      {
        div: "現",
        content: "중국 옌타이 보세항구 자문위원",
      },
      {
        div: "現",
        content: "한국관세사회 제도발전위원회 위원",
      },
      {
        div: "現",
        content: "중앙행정심판위원회 자문위원",
      },
      {
        div: "前",
        content: "한국관세사회 상생협력위원회 위원",
      },
    ],
    image: Img2,
  },

  {
    serial: 13,
    name: "박찬욱",
    grade: "대표관세사",
    tel: "02-2017-3304",
    id: "cupark",
    image: Img4,
    division: false,
    organization: "임원",
    spec: [],
    license: 17,
    history: [
      "관세법인 에이원 대표관세사",
      "現 한국관세사회 정보화위원회 위원",
      "現 한국관세사회 평택지부 운영위원",
      "現 평택세관 외부평가위원",
      "現 농림축산식품부 유통이력 심의위원",
      // "관세청장 표창 (2011)",
      // "FTA 컨설팅 인증관세사 (2012, 한국원산지정보원)",
      // "AEO 경영컨설턴트 (2010, 관세청)",
      // "자율심사인증관세사 (2006, 관세청)",
      "서울대학교 과학기술혁신 최고전략과정 이수",
    ],
    historyOld: [
      "FTA 전략지원본부장 및 중부권통관사업본부장, 수도권총괄본부장, 무역협회 FTA 협력지원단장",
      ", FTA 컨설턴트 (2011, 서울산업통상진흥원)",
      "구로공단 무역/통관 자문위원, 관세청 CRM 자문위원, 벤쳐기업협회 자문관세사, 서울중소기업청 자문관세사",
      "관세법인 에이원 인천공항지사 통관팀장, 인천지사장 및 해상사업본부장, 기획총괄본부장",
      "FTA 컨설팅 인증관세사, FTA 컨설턴트, AEO 경영컨설턴트, 자율심사인증관세사",
    ],
    Chinesehistory: [],
  },
  {
    serial: 14,
    name: "이성욱",
    grade: "대표관세사",
    tel: "051-460-7101",
    id: "tnt-2000",
    image: Img5,
    division: false,
    organization: "임원",
    spec: [],
    license: 16,
    history: [
      "현. 관세법인 에이원 대표관세사(식품, 유통 분야 전문)",
      "전. 관세법인 에이원 물류검역사업 본부장",
      "식품유통업체 검역검사 컨설팅 수행",
      "부경대학교 산업대학원 식품공학과 수료",
      "제6회 해양수산부 주관 항만물류 CEO과정 수료",
      "관세사 16회 자격시험 합격",
    ],
    Chinesehistory: [],
  },
  {
    serial: 21,
    name: "유길석",
    grade: "전무 / CFO",
    tel: "02-2017-3390",
    id: "suk1279",
    division: "경영기획실",
    spec: [""],
    advisory: [""],
    image: Img50,
    history: [
      "관세법인 에이원 CFO(전무)",
      "한국관세무역개발원 물류본부장",
      "한국관세무역개발원 상무이사",
      "서울세관 발전심의위원",
    ],
    Chinesehistory: [],
  },
  {
    serial: 31,
    name: "강동윤",
    grade: "총괄본부장",
    tel: "02-2017-3300",
    id: "kdy22",
    image: Img17,
    division: "컨설팅총괄본부",
    advisory: ["관세심사"],
    spec: ["관세심사", "기업심사"],
    history: [
      "광주세관/서울세관/인천세관 심사과",
      "심사분야 선임 전문관",
      "기업진단, 특수관계 이전가격, 기업심사 등 500여개 기업 수행",
    ],
    ChineseCheck: false,
    Chinesehistory: [
      {
        div: "現",
        content: "관세국경관리연수원 심사 전문교수",
      },
      {
        div: "現",
        content: "관세평가위원회, 관세평가포럼 위원",
      },
    ],
  },
  {
    serial: 32,
    name: "이흥대",
    grade: "총괄본부장",
    tel: "051-460-7102",
    id: "ccam7413",
    image: Img_ccam7413,
    division: "부산총괄본부",
    advisory: ["품목분류"],
    spec: ["품목분류"],
    history: [
      "마산세관 통관지원과장, 부산세관 분석실장 역임",
      "관세평가분류원 품목분류 과장 역임",
      "관세국경관리연수원 품목분류 교수",
      "중앙관세분석소, 울산세관, 부산세관, 관세청 등 근무",
    ],
    Chinesehistory: [],
  },
  {
    serial: 33,
    name: "송윤규",
    grade: "총괄본부장",
    tel: "032-745-0876",
    id: "hd11234",
    image: Img18,
    division: "인천총괄본부",
    advisory: ["수출입통관"],
    spec: ["수출입통관"],
    history: [
      "관세청 경력 총 32년",
      "(전)서울본부세관 통관국",
      "(전)인천본부세관 조사감시국, 통관국",
    ],
    Chinesehistory: [],
  },
  {
    serial: 34,
    name: "김수연",
    grade: "총괄본부장",
    tel: "02-2017-3300",
    id: "sykim0608",
    division: "특송총괄본부",
    image: Img46,
    advisory: ["특송"],
    spec: ["특송"],
    history: [
      "관세청 경력 총34년",
      "관세청 통관기획과 수입통관기획, 특수송관과 특수통관총괄",
      "서울세관 조사총괄과 조사업무총괄",
      "김포공항세관 세관장",
    ],
    Chinesehistory: [],
  },
  {
    serial: 41,
    name: "김기영",
    grade: "연구원장",
    tel: "02-2017-3364",
    id: "y2kykim",
    image: Img6,
    division: false,
    advisory: ["행정쟁송", "관세정책"],
    spec: ["행정쟁송"],
    history: [
      "관세청 경력 총 37년",
      "(전) 서울본부세관장",
      "(전) 관세청 심사국장",
      "(전) 조세심판원 비상임심판관",
    ],
    Chinesehistory: [],
  },

  {
    serial: 43,
    name: "박성우",
    grade: "전문위원",
    tel: "02-2017-3300",
    id: "parksw11",
    image: Img30,
    advisory: ["행정쟁송"],
    spec: ["행정쟁송"],
    division: false,
    history: [
      "관세청 경력 총30년",
      "서울본부세관 조사국장",
      "부산본부세관 조사국장",
      "인천본부세관 조사국장",
    ],
    Chinesehistory: [],
  },

  {
    serial: 44,
    name: "이동영",
    grade: "고문변호사",
    tel: "02-6941-2634",
    id: "solgae8",
    image: img_dongyoung,
    advisory: ["행정쟁송"],
    spec: ["행정쟁송"],
    division: false,
    history: [
      "법률사무소 호우 대표변호사",
      "우체국금융개발원 규제입증위원회 위촉위원",
      "우체국금융개발원 청렴시민감사관",
      "서울특별시 공익변호사",
      "지역문화진흥원 자문",
      "전 사법연수원 특강강사(2017~2019)",
      "전 한국출판문화산업진흥원 분쟁조정위원회 위촉위원",
      "전 대법원 국선변호인",
      "전 서울중앙지방법원 국선변호인",
      "감사원 및 행정법원 실무심화 연수",
    ],
    Chinesehistory: [],
  },

  {
    id: "yjkim",
    name: "김윤진",
    grade: "팀장",
    tel: "02-2017-3358",
    image: Img_yjkim,
    division: "혁신전략실 VALUE혁신팀",
    advisory: ["품목분류"],
    spec: ["품목분류"],
    license: 34,
    history: [
      "혁신전략실 VALUE혁신팀 팀장",
      "거래업체 고객관리컨설팅 다수 (품목분류, FTA, 과세가격 등)",
      "수입세액정산 실무관세사",
      "AEO종합심사, 관세조사 대리",
    ],
  },
  {
    serial: 52,
    name: "손은정",
    grade: "본부장",
    tel: "02-2017-3337",
    id: "son0077",
    image: Img21,
    division: "수도권총괄본부",
    advisory: ["수출입통관"],
    spec: ["수출입통관", "기업심사"],
    license: 23,
    history: [
      "수도권총괄본부 통관사업부 총괄",
      "거래업체 고객관리컨설팅 대리(품목분류, 과세가격, FTA 등)",
      "기업심사 대리 다수",
      "FTA 컨설팅 정부지원사업 참여(한국무역협회 OK FTA PM)",
    ],
    Chinesehistory: [],
  },

  {
    serial: 53,
    name: "강태인",
    grade: "본부장",
    tel: "031-618-1307",
    id: "lgman",
    image: Img28,
    division: "평택지점",
    spec: ["수출입통관", "품목분류"],
    history: [
      "통관실무 경력 30년, 보세사",
      "평택지사 지사장",
      "외국계자동차, 전기전자 부품 관련 대기업 통관 경력",
      "자동차 부분품, 전자기기,기계류등 품목분류 컨설팅",
      "연구용 감면, FTA 등 실무 총괄",
    ],
    Chinesehistory: [],
  },
  {
    serial: 64,
    name: "김교일",
    grade: "부문장",
    tel: "032-745-0813",
    id: "k1234",
    image: Img36,
    division: "인천총괄본부",
    spec: ["수출입통관"],
    history: [
      "수출입통관 업무 경력 20년",
      "인천총괄본부 부문장",
      "전기전자 업체 및 면세접 등 다수업체 수출입 통관 수행",
      "거래업체 통관 및 리스크 업무 수행",
    ],
    Chinesehistory: [],
  },
  {
    name: "손영희",
    grade: "부문장",
    tel: "02-2017-3335",
    id: "demianyh",
    image: Img32,
    division: "컨설팅총괄본부",
    spec: ["AEO"],
    history: [
      "AEO업무 경력 11년",
      "컨설팅총괄본부 부문장",
      "위험평가, 내부통제평가, 거래업체 평가 프로세스 구축 전문",
      "80여개 업체 컨설팅 수행",
    ],
    Chinesehistory: [],
  },
  {
    name: "김현태",
    grade: "팀장",
    tel: "02-2017-3385",
    id: "kht3073",
    image: Img_kht3073,
    division: "컨설팅총괄본부 컨설팅2팀",
    spec: ["AEO"],
    history: [
      "컨설팅2팀 팀장",
      "AEO컨설팅 경력 10년",
      "수출입업체(화학, 전자, 자동차 전문)",
      "AEO인증/사후관리/종합심사",
    ],
    Chinesehistory: [],
  },
  {
    serial: 101,
    name: "유정",
    grade: "팀장",
    tel: "02-2017-3311",
    id: "yoojung",
    image: Img25,
    division: "수도권총괄본부 통관1팀",
    spec: ["수출입통관"],
    history: [
      "수출입통관경력 20년",
      "수도권총괄본부 통관1팀 팀장",
      "반도체 업체 및 생활용품 및 제지 업체 등 통관",
      "거래업체 통관 및 리스크 관리 업무 수행",
    ],
    Chinesehistory: [],
  },
  {
    name: "전진아",
    grade: "부문장",
    tel: "02-2017-3368",
    id: "jina1012",
    gender: "f",
    image: Img15,
    division: "수도권총괄본부",
    spec: ["수출입통관", "품목분류"],
    license: 24,
    history: [
      "수도권총괄본부 부문장",
      "외국계 자동차 기업 수출입통관관리",
      "AEO 인증 컨설팅 ",
    ],
    Chinesehistory: [],
  },
  {
    name: "하창길",
    grade: "팀장",
    tel: "032-745-0885",
    id: "changroad",
    image: Img16,
    division: "특송총괄본부",
    spec: ["수출입통관, 특송통관"],
    license: 24,
    history: [
      "특송총괄본부 특송통관팀 팀장",
      "前 수도권총괄본부 통관3팀 팀장",
      "AEO 인증컨설팅 수행",
    ],
    ChineseCheck: false,
    Chinesehistory: [],
  },
  {
    serial: 107,
    name: "김의걸",
    grade: "팀장",
    tel: "032-745-0872",
    id: "xanhy",
    image: Img37,
    division: "인천총괄본부 일반통관팀",
    spec: ["수출입통관"],
    history: [
      "수출입통관 업무 경력 20년",
      "특송통관 및 전자상거래 등 다수업체 업무 수행",
      "거래업체 통관 및 리스크 관리 업무 수행",
    ],
    Chinesehistory: [],
  },
  {
    name: "어대경",
    grade: "팀장",
    tel: "032-745-0830",
    id: "pass911",
    image: Img38,
    division: "인천총괄본부 검역통관팀",
    spec: ["수출입통관", "검역"],
    license: 29,
    history: [
      "검역통관팀 팀장",
      "유통, 식품관련 업체 등 수출입통관 전담관세사",
      "요건 및 검역 전문",
    ],
    Chinesehistory: [],
  },
  {
    name: "김진숙",
    grade: "팀장",
    tel: "051-460-7103",
    id: "jskim",
    image: Img39,
    division: "부산총괄본부 CS팀",
    spec: ["과세가격", "FTA"],
    license: 32,
    history: [
      "부산총괄본부 CS팀 팀장",
      "FTA컨설팅 및 원산지검증컨설팅",
      "수입세액정산 및 법인심사 대리",
      "화확 및 식품업 등 다수업체 수출입 통관업무 수행",
    ],
    Chinesehistory: [],
  },
  {
    name: "송영규",
    grade: "팀장",
    tel: "032-745-0885",
    id: "abhae2710",
    image: Img40,
    division: "인천총괄본부 특송통관팀",
    spec: ["수출입통관", "품목분류"],
    history: [
      "인천세관, 김포세관 수출입통관과 근무",
      "인천세관 관세조사과 근무",
      "관세청 정보화 기획과 / 관리과 근무",
    ],
    Chinesehistory: [],
  },
  {
    serial: 111,
    name: "정진국",
    grade: "팀장",
    tel: "052-272-3206",
    id: "albatross",
    division: "울산지점",
    image: Img123,
    spec: [],
    history: [],
    Chinesehistory: [],
  },

  {
    serial: 112,
    name: "박현욱",
    grade: "부문장",
    tel: "051-460-7130",
    id: "josep62",
    image: Img41,
    division: "부산총괄본부 전략검역팀",
    spec: ["검역"],
    history: [
      "부산총괄본부 전략검역팀 팀장",
      "미국 FDA FSPCA(Foreign Supplier Verification Programs)교육 수료",
      "대형 프렌차이즈 수입식품 컨설팅 진행",
      "대형유통업체 수입식품 컨설팅 진행",
      "건강기능식품 전문업체 건강기능식품 컨설팅 진행",
    ],
    Chinesehistory: [],
  },

  {
    serial: 113,
    name: "부승길",
    grade: "팀장",
    tel: "051-460-7137",
    id: "boo6999",
    image: Img42,
    division: "부산총괄본부 검역통관팀",
    spec: ["검역"],
    history: [
      "검역, 통관경력 10년",
      "부산총괄본부 검역사업팀 팀장",
      "식품관련 수출입 통관 진행 ",
      "축산물 검역 전문",
    ],
    Chinesehistory: [],
  },

  {
    serial: 115,
    name: "박종운",
    grade: "팀장",
    tel: "051-460-7100",
    id: "woon4545",
    image: Img_woon4545,
    division: "부산총괄본부 통관팀",
    spec: ["수출입통관"],
    history: [
      "다수 업체 통관업무 수행",
      "부산총괄본부 통관팀 팀장",
      "대형 유통업체, 반도체 업체등 다수업체 통관업무수행",
      "거래업체 통관 및 리스크 관리 업무 수행",
    ],
    Chinesehistory: [],
  },
  {
    serial: 116,
    name: "김동희",
    grade: "팀장",
    tel: "031-8054-7557",
    id: "donghee7747",
    image: Img49,
    division: "평택지점",
    spec: ["수출입통관", "개별소비세 환급"],
    history: [
      "통관실무 경력 30년, 원산지관리사",
      "평택지사 통관팀 팀장",
      "수입자동차 및 중고자동차 통관 실무 Knowhow 겸비",
      "개별소비세환급",
    ],
    Chinesehistory: [],
  },

  {
    serial: 117,
    name: "김세희",
    grade: "팀장",
    tel: "051-460-7107",
    gender: "f",
    id: "seyung",
    division: "부산총괄본부 업무지원팀",
    spec: [],
    history: [],
    Chinesehistory: [],
  },

  {
    serial: 118,
    name: "김영태",
    grade: "팀장",
    tel: "02-2017-3326",
    id: "buggy",
    division: "경영기획실 경영기획팀",
    spec: [],
    history: [],
    Chinesehistory: [],
  },

  {
    name: "정현균",
    grade: "팀장",
    tel: "02-2017-3348",
    id: "jeongyun",
    image: Img47,
    division: "컨설팅총괄본부 컨설팅1팀",
    spec: ["기업심사", "과세가격", "FTA"],
    license: 34,
    history: [
      "컨설팅총괄본부 컨설팅1팀 팀장",
      "수입세액정산 과세가격, 외환 담당 관세사",
      "AEO 종합심사 및 관세조사 대리 다수",
      "과세전적부심사 대리",
    ],
    Chinesehistory: [],
  },
  {
    name: "장희정",
    grade: "파트장",
    tel: "051-460-7113",
    id: "lily93",
    image: Img_lily93,
    division: "부산총괄본부 통관팀",
    advisory: [],
    spec: ["환급"],
    history: [
      "관세법인 에이원(환급 업무 10년)",
      "전 SNT 모티브 구매혁신팀(환급 업무 7년)",
      "환급 실무 수행 및 세관 대응(주류, 식품, 건설 등)",
      "관세환급 시스템 구축 및 초기세팅 컨설팅(자동차 부품, 화학 등)",
    ],
    Chinesehistory: [],
  },
  {
    name: "임길호",
    grade: "본부장",
    tel: "02-2017-3300",
    id: "gilho",
    image: img_gilho,
    division: "컨설팅총괄본부",
    spec: [],
    history: [
      "컨설팅총괄본부 본부장",
      "前 관세청심사국원산지심사과",
      "前 세관심사과,심사정책과",
      "前 관세청심사국,통관국,자유무역협정집행과",
      "前 관세평가분류원",
    ],
    Chinesehistory: [],
  },
  {
    name: "김성근",
    grade: "전무",
    tel: "02-2017-3300",
    id: "sunggun",
    image: img_sunggun,
    division: "전문위원",
    spec: [],
    history: [
      "식약청 경력 총 23년",
      "前 통영수입식품검사소장",
      "前 부산식약처HACCP총괄",
    ],
    Chinesehistory: [],
  },
  {
    name: "김수연",
    grade: "총괄본부장",
    tel: "02-2017-3300",
    id: "suyun",
    image: img_suyun,
    division: "특송총괄본부",
    spec: [],
    history: [
      "前 김포공항세관 세관장",
      "前 서울본부세관 조사총괄과 조사업무총괄",
      "前 관세청 특수통관과 특수통관총괄",
    ],
    Chinesehistory: [],
  },
  {
    name: "김영환",
    grade: "총괄본부장",
    tel: "02-2017-3300",
    id: "yeonghwan",
    image: img_yeonghwan,
    division: "컨설팅총괄본부",
    spec: [],
    history: [
      "관세청 경력 총 38년",
      "현. 컨설팅총괄본부 총괄본부장, 울산지사장",
      "前 군산세관 세관장",
      "前 김해세관 세관장",
      "前 인천세관 감시국장",
    ],
    Chinesehistory: [],
  },
  {
    name: "이종혁",
    grade: "",
    image: img_jhLee,
    tel: "02-2017-3316",
    id: "jhlee",
    division: "관세사",
    spec: ["인증/요건"],
    license: 31,
    history: ["現 품목분류 컨설팅 PM", "前 특송통관 수입CS 담당"],
    Chinesehistory: [],
  },
  {
    name: "강민수",
    grade: "팀장",
    image: img_meenss,
    tel: "02-2017-3333",
    id: "meenss",
    division: "수도권총괄본부 혁신전략팀",
    history: [
      "제32회 관세사시험 합격",
      "관세환급 프로세스 컨설팅 수행",
      "발전사, 철강 식품 등 거래업체 통관관리(품목분류, FTA 등)",
      "한-중FTA 기획심사 대응 등 FTA 컨설팅 수행",
    ],
  },
  {
    name: "박지훈",
    grade: "책임",
    image: img_jhpark0113,
    tel: "02-2017-3377",
    id: "jhpark0113",
    division: "수도권총괄본부 인증요건팀",
    history: [
      "의류•섬유제품, 조명 및 전기용품 무역회사 실무 경력",
      "전파법, 전안법 등 국내인증 및 수입요건 컨설팅",
      "거래업체 인증제품 관리 및 요건실무 수행",
      "전기•전자제품, 생활용품, 어린이제품 등 KC인증 시험 대리",
    ],
  },
  // {
  //   name: "김동규",
  //   grade: "파트장",
  //   tel: "02-2017-3386",
  //   id: "myname4",
  //   image: Img_myname4,
  //   division: "수도권총괄본부 인증요건팀",
  //   spec: [],
  //   history: [
  //     "수도권총괄본부 인증요건팀 파트장",
  //     "전자, 반도체 업종 수출입 요건 검토 및 대리",
  //     "수출입통관 업체 관리",
  //     "FTA 컨설팅 대리",
  //   ],
  //   Chinesehistory: [],
  // },
];
export const getMembersbyId = (id) => {
  return memberArray.filter((x) => x.id === id);
};

export const getMemberbyId = (id) => {
  return id ? memberArray.find((x) => x.id === id) : emptyObject;
};
