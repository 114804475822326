import styled from "styled-components";
import UseBreakpoints from "../../hooks/ui/UseBreakpoints";
const BoardMemberCard = ({
  member: { id, name, rank, history, tel, email, image, engTitle },
  type,
}) => {
  const breakpoints = UseBreakpoints();
  return (
    <Div type={type} breakpoints={breakpoints}>
      <BackgroundDiv type={type} breakpoints={breakpoints}>
        <img src={image} alt={name} />
      </BackgroundDiv>
      <DescriptionDiv type={type} breakpoints={breakpoints}>
        <EngTitleSpan>{engTitle}</EngTitleSpan>
        <NameSpan type={type}>{name}</NameSpan>
        <TitleSpan>
          {rank === "P"
            ? "회장 / 대표이사 / 관세사"
            : rank === "R"
            ? "대표 관세사"
            : rank === "VP"
            ? "대표 관세사"
            : rank === "CFO"
            ? "CFO / 전무"
            : null}
        </TitleSpan>
        <ul>
          {history?.map((x) => {
            return (
              <HistoryList
                breakpoints={breakpoints}
                type={type}
                key={x.content}
                bold={x.div === "現"}
              >
                {[x.div, x.content].join(" ")}
              </HistoryList>
            );
          })}
        </ul>
      </DescriptionDiv>
    </Div>
  );
};
export default BoardMemberCard;
const Div = styled.div`
  flex: 1;
  width: ${({ type, breakpoints }) =>
    breakpoints?.lg && type === "1" ? "100%" : "inherit"};
  display: flex;
  justify-content: center;
  align-items: center;
  <!--gap: 0.5em;-->
  margin: ${({ type, breakpoints }) =>
    breakpoints?.lg && type === "1" ? "0 0 3em 0" : "1.5em 0"};
  & img {
    height: ${({ type, breakpoints }) =>
      breakpoints?.lg && type === "1" ? "30em" : "20em"};
    position: absolute;
    bottom: ${({ type, breakpoints }) =>
      breakpoints?.lg && type === "1" ? "-12em" : "-8em"};
    right: ${({ type, breakpoints }) =>
      breakpoints?.lg && type === "1" ? "-10em" : "-7em"};
  }
`;
const DescriptionDiv = styled.div`
  padding: ${({ type, breakpoints }) =>
    breakpoints?.lg && type === "1" ? "2em" : "2em 1em"};
  width: ${({ type, breakpoints }) =>
    breakpoints?.lg && type === "1" ? "30em" : type === "2" ? "18em" : "14em"};
  display: flex;
  flex-direction: column;
  & > ul {
    list-style: none;
    padding: 0.5rem 0 0 0.5rem;
  }
`;
const EngTitleSpan = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-weight: bolder;
  font-size: 1.3em;
`;
const NameSpan = styled.span`
  font-size: ${({ type }) => (type === "1" ? "3em" : "2em")};
  font-weight: bold;
`;
const TitleSpan = styled.span`
  font-size: 0.85em;
  color: #888;
  letter-spacing: -0.07em;
  font-weight: 300;
`;
const HistoryList = styled.li`
  font-size: ${({ type, breakpoints }) =>
    breakpoints.lg ? (type === "1" ? "1.2em" : ".9em") : "inherit"};
  font-weight: ${(props) => (props.bold ? "500" : "300")};
  color: #444;
  list-style: none;
`;

const BackgroundDiv = styled.div`
  position: relative;
  padding: 0em;
  width: 0px;
  height: 0px;
  border-top: ${({ type, breakpoints }) =>
      breakpoints?.lg && type === "1" ? "12em" : "8em"}
    solid transparent;
  border-left: ${({ type, breakpoints }) =>
      breakpoints?.lg && type === "1" ? "10em" : "7em"}
    solid transparent;
  border-bottom: ${({ type, breakpoints }) =>
      breakpoints?.lg && type === "1" ? "12em" : "8em"}
    solid ${({ theme }) => theme.colors.monotone.light};
  border-right: ${({ type, breakpoints }) =>
      breakpoints?.lg && type === "1" ? "10em" : "7em"}
    solid ${({ theme }) => theme.colors.monotone.light};
  & > img {
  }
`;
