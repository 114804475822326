import styled from "styled-components";
const HeadedBoxCol = ({ div, subdiv, subHeader, text, numberedContents }) => {
  return (
    <Div>
      <div>
        <div>{div}</div>
        <div>{subdiv}</div>
      </div>
      {subHeader && <SubHeaderDiv>{subHeader}</SubHeaderDiv>}
      {text && <TextDiv>{text}</TextDiv>}
      {numberedContents && (
        <div>
          <ol>
            {numberedContents?.map((x, idx) => (
              <li key={idx}>{x}</li>
            ))}
          </ol>
        </div>
      )}
    </Div>
  );
};
export default HeadedBoxCol;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    background-color: ${({ theme }) => theme.colors?.dark};
    margin: 2em;
    & > div:first-child {
      border-bottom: 1px solid ${({ theme }) => theme.colors?.dark};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 8em;
      font-weight: bold;
      color: white;
      font-size: 1.5em;
    }
    & > div:nth-child(2) {
      color: #aaa;
    }
  }
  & > div:last-child {
    font-size: 1.2em;
    & > ol {
      list-style: none;
      counter-reset: item;
      & > li {
        counter-increment: item;
        margin-bottom: 5px;
        &:before {
          margin-right: 10px;
          padding: 0 0.1em;
          content: counter(item);
          background: ${({ theme }) => theme.colors?.dark};
          border-radius: 100%;
          color: white;
          width: 1.2em;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }
`;
const SubHeaderDiv = styled.div`
  padding: 1em;
  color: ${({ theme }) => theme.colors?.dark};
  font-size: 1.2em;
  background-image: radial-gradient(#ace7 13%, transparent 20%),
    radial-gradient(#ace7 13%, transparent 20%);
  background-color: transparent;
  background-position: 0 0, 3px 3px;
  background-size: 6px 6px;
`;

const TextDiv = styled.div`
  padding: 2em 3em;
  color: ${({ theme }) => theme.colors?.dark};
  font-size: 1.1em;
  background-image: radial-gradient(#ace7 13%, transparent 20%),
    radial-gradient(#ace7 13%, transparent 20%);
  background-color: transparent;
  background-position: 0 0, 3px 3px;
  background-size: 6px 6px;
`;
