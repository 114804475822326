import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../style/styled-components/helpers/device";
const HomeCard = ({
  content: {
    category = "",
    title = "",
    author = "",
    link = "",
    date = new Date().toLocaleDateString(),
    image = null,
  },
  colored = false,
}) => {
  return (
    <Div colored={colored} image={image}>
      <Link to={link}>
        <div>
          <div className="category">{category}</div>
          <hr />
          <div className="title">{title}</div>
          <div className="info">
            <div className="date">{date}</div>
            <div className="author">{author}</div>
          </div>
        </div>
        <div>{/* <div /> 테두리!! */}</div>
        <div className="background" />
      </Link>
    </Div>
  );
};
export default HomeCard;
const Div = styled.div`
  position: relative;
  display: flex;
  width: 90vw;
  min-height: 10rem;
  @media ${device.mobileL} {
    width: 11.2rem;
    min-height: 15.1rem;
  }
  @media ${device.tablet} {
    width: 13.2rem;
    min-height: 15.1rem;
  }
  margin: 2rem 1rem;
  /* box-shadow: ${({ image, colored }) => image ? null : `1rem 1rem 3rem
      ${(colored ? "#1b2236" : "#c7c7c7")},
    -.5rem -.5rem 3rem ${(colored ? "#5d77ca" : "#fff")}`}; */
    
    background: ${({ colored, theme, image }) =>
    image ? null : colored ? theme?.colors?.littledark : theme?.colors?.monotone?.extralight};
    color: ${({ image, theme }) => image ? theme.colors.foreground.inverted : "inherit"};
    border: 2px ${({ theme, colored, image }) => image ? "rgba(0,0,0,0.6)" : colored ? theme?.colors?.medium : theme?.colors?.monotone?.extralight} solid;
  & > a {
    flex: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    & hr {
      width: 100%;
      color: ${({ colored, theme }) =>
    colored ? theme?.colors?.monotone?.light : "#fff"};
    }
    & > div.background {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-image: ${({ image }) => image ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${image})` : null};
    background-position: center;
    background-size: cover;
    
          }
          &:hover > div.background {
      transform: scale(1.05);
            background-image: ${({ image }) => image ? `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(${image})` : null};
      transition: all 0.24s;
    }
    & > div {
      // main
      position: relative;
      z-index: 2;
      &:first-child {
        width: calc(100% - 2rem);
        height: calc(100% - 2rem);
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0rem 0 0 0;

        & > div {
          font-weight: 300;

          &.category {
            // 카테고리
            display: flex;
  @media ${device.mobileL} {
            padding: 0.5rem;
  }
            font-size: 1.3em;
            font-weight: lighter;
          }
          &.title {
            // 일반 제목
            flex: 1;
            display: flex;
            padding: 1rem 0.5rem 0 0.5rem;
            justify-content: center;
            align-items: flex-start;
          }
          &.info {
            display: flex;
            justify-content: space-between;
            & > div.author {
              // 저자
              display: flex;
              font-weight: lighter;
              font-size: 0.8em;
              justify-content: right;
              padding: 0 0.3rem;
            }
            & > div.date {
              // 날짜
              display: flex;
              justify-content: right;
              font-size: 0.8em;
              padding: 0 0.3rem;
            }
          }
        }
      }
      &:nth-child(2) {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        & > div {
          display: absolute;
          width: calc(100% - 2rem);
          height: calc(100% - 2rem);
          border: solid 3px
            ${({ colored, theme }) =>
    colored ? "#fff" : theme?.colors?.medium};
        }
      }
    }
  }
  &:hover {
    color: ${({ theme, colored, image }) =>
    image ? theme.colors?.foreground.highlightinverted :
      colored
        ? theme.colors?.foreground.highlightinverted
        : theme.colors?.foreground.highlight};
    transition: 0.24s all ease-in;
    /* transform: translate(0.15rem, -0.15rem); */
    border: 2px ${({ theme, colored }) => colored ? theme.colors.extralight : theme.colors.medium} solid;
  }
`;
