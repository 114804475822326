import ClassificationImage from "../../../resources/images/classification.jpg";
import ContactPoints from "../../../components/ui/members/ContactPoints";
import DiamondScope from "../../../components/ui/specialty/DiamondScope";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import TripleBox from "../../../components/ui/common/template/TripleBox";
import UnderConstructionDiv from "../../../style/styled-components/components/UnderConstructionDiv";
import WorkScopes from "../../../components/ui/specialty/WorkScopes";
import img1 from "../../../resources/images/icon/6. 품목분류/1. 정확한 품목분류 필요성/1. 세율의 결정.png";
import img2 from "../../../resources/images/icon/6. 품목분류/1. 정확한 품목분류 필요성/2. 인증 요건의 구비.png";
import img3 from "../../../resources/images/icon/6. 품목분류/1. 정확한 품목분류 필요성/3. 과오납 또는 추징 방지.png";
import img4 from "../../../resources/images/icon/6. 품목분류/2. 업무범위/1. 통관물품 품목분류 결정.png";
import img5 from "../../../resources/images/icon/6. 품목분류/2. 업무범위/2. 품목분류 적용 적합성 검증.png";
import img6 from "../../../resources/images/icon/6. 품목분류/2. 업무범위/3. 품목분류 의견서 제공.png";
import img7 from "../../../resources/images/icon/6. 품목분류/2. 업무범위/4. 품목분류 사전심사 신청대리.png";
import img8 from "../../../resources/images/icon/6. 품목분류/2. 업무범위/5. 품목분류 컨설팅.png";
const ClassificationPage = () => {
  return (
    <PageTemplate
      title="품목분류"
      large
      image={ClassificationImage}
      phrase="모든 분야에서 다양한 품목을 다룬 경험을 기반으로 정확한 품목분류 서비스를 제공합니다."
    >
      <p>
      정확한 품목분류는 수출입통관의 첫걸음입니다. 
화학, 제약, 기계, 자동차, 식품, 의류 등 다양한 산업분야에 걸쳐 품목분류 컨설팅을 수행한 경험을 바탕으로 
신뢰도 높은 품목분류를 제공합니다. 각 수출입물품에 적정 HS code를 부여하여 통관 리스크 저감을 실현합니다

      </p>
      <TitleTemplate
        title="정확한 품목분류의 필요성"
        noImage
        subHeader="수출입통관의 첫 단추는 물품의 품목분류로부터 시작됩니다."
      />
      <TripleBox
        contents={[
          {
            title: "세율의 결정",
            list: [
              "HSK 10단위별로 기본 세율 결정",
              "WTO 협정 세율의 적용 기준",
              "HS Code 6단위별로 FTA 적용 대상 결정",
            ],
            icon: img1,
          },
          {
            title: "인증, 요건의 구비",
            list: [
              "HSK10단위를 기준으로 수출입 요령이 정해져 있어, 통관 시 해당 요건을 반드시 갖추어야 함",
            ],
            icon: img2,
          },
          {
            title: "과오납 또는 추징 방지",
            list: [
              "잘못된 세번의 적용으로 과다하게 세금을 납부하는 것을 방지",
              "법인 심사 결과 추징, 가산세 납부를 미연에 방지",
            ],
            icon: img3,
          },
        ]}
      />
      <TitleTemplate
        title="업무 범위"
        noImage
        subHeader="풍부한 경험을 기반으로 대상 물품에 대한 신속 정확한 분류절차를 이행합니다."
      />
      <WorkScopes
        contents={[
          {
            icon: img4,
            title: "통관물품 품목분류 결정",
          },
          {
            icon: img5,
            title: "품목분류 적용 적합성 검증",
          },
          {
            icon: img6,
            title: "품목분류 의견서 제공",
          },
          {
            icon: img7,
            title: "품목분류 사전심사 신청 대리",
          },
          {
            icon: img8,
            title: "품목분류 컨설팅",
          },
        ]}
      />
      <TitleTemplate
        title="Strength"
        noImage
        subHeader="A-One만의 장점으로 고객을 힘껏 서포트 해 나가겠습니다."
      />
      <DiamondScope
        contents={[
          {
            title: "MAN POWER",
            text: "전기전자, 화학, 식품, 의류 등 전 산업을 대상으로 300여개 업체의 컨설팅 수행경력 보유",
          },
          {
            title: "TASK-FORCE",
            text: "품목분류 자문단과 관세사가 팀을 이루어 보다 전문적인 품목분류 의견 제공",
          },
          {
            title: "Experience",
            text: "전 산업군의 다양한 품목을 대상으로 다양한 품목분류 경험을 보유",
          },
          {
            title: "Consulting",
            text: "품목분류 컨설팅을 통한 현황 진단 및 RISK 제거",
          },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["classificationMain"]} />
    </PageTemplate>
  );
};
export default ClassificationPage;
