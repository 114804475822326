const theme = {
  colors: {
    dark: "#125",
    littledark: "#283868",
    medium: "#458",
    light: "#89b",
    extralight: "#f8f8ff",
    safe: "#080",
    caution: "#b40",
    foreground: {
      highlight: "#125",
      highlight2: "#347",
      inverted: "white",
      highlightinverted: "#dcb",
      normal: "black",
      dimmed: "#666",
    },
    monotone: {
      dark: "#333",
      medium: "#888",
      light: "#ddd",
      extralight: "#f8f8f8",
    },
    background: {
      dark: "#125",
      medium: "#458",
      light: "#89b",
      extralight: "#f8f8ff",
      default: "white",
      lightyellow: "#ffe",
      lightgreen: "#efe",
    },
    pages: {
      branches: {
        background: "rgba(230, 236, 230, .3)",
      },
      prizes: {
        background: "rgb(242, 242, 236)",
      },
      history: {
        background: "rgba(236, 230, 230, .3)",
        navigationBackground: "rgba(236, 230, 230)",
      },
      esg: {
        background: "rgb(246, 246, 252)",
      },
    },
  },
};
export default theme;
