import styled from "styled-components";
export default styled.div`
  display: flex;
  flex-direction: ${({ breakpoints }) => (breakpoints?.lg ? "row" : "column")};
  gap: ${({ breakpoints }) => (breakpoints?.lg ? "3" : "1")}rem;
  align-items: ${({ breakpoints }) =>
    breakpoints?.lg ? "stretch" : "center"};
  justify-content: center;  
  & > * {
    flex: 1;
  }
`;
