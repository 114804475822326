import styled from "styled-components";
import Hexagon from "../../../../../style/styled-components/components/Hexagon";
const HexagonalFour = ({ title, data }) => {
  return (
    <Div>
      <div>
        <div>
          <Hexagon color="#bbb" width={12}>
            <div />
            <div />
            <div />
            <div></div>
            <div>{data[0].title}</div>
            <div>
              <div></div>
              <div></div>
              <div>{data[0].text}</div>
            </div>
          </Hexagon>
        </div>
        <div>
          <Hexagon color="#ddd" width={12}>
            <div />
            <div />
            <div />
            <div></div>
            <div>{title}</div>
          </Hexagon>
        </div>
        <div>
          <Hexagon color="#ccc" width={12}>
            <div />
            <div />
            <div />
            <div></div>
            <div>{data[2].title}</div>
            <div>
              <div />
              <div />
              <div>{data[2].text}</div>
            </div>
          </Hexagon>
        </div>
        <div>
          <Hexagon color="#eee" width={12}>
            <div />
            <div />
            <div />
            <div> {data[1].title}</div>
            <div></div>
            <div></div>
            <div>
              <div />
              <div />
              <div>{data[1].text}</div>
            </div>
          </Hexagon>
        </div>
      </div>
    </Div>
  );
};
export default HexagonalFour;
const hexWidth = 12;
const Div = styled.div`
  display: flex;
  justify-content: center;
  & > div {
    position: relative;
    height: ${hexWidth * 1.8}em;
    & > div:first-child {
      position: absolute;
      left: -${0.58 * hexWidth}em;
      bottom: ${2.312 * hexWidth}em;
    }
    & > div:nth-child(2) {
      position: absolute;
      bottom: ${2 * hexWidth}em;
      left: -${0.58 * hexWidth}em;
      top: ${0.5 * hexWidth}em;
    }
    & > div:nth-child(3) {
      position: absolute;
      top: ${1.01 * hexWidth}em;
      left: ${0.29 * hexWidth}em;
    }
    & > div:last-child {
      position: absolute;
      top: ${1.01 * hexWidth}em;
      right: ${1.45 * hexWidth}em;
    }
  }
`;
