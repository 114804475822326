import AEOImage from "../../../../src/resources/images/aeo.jpg";
import AEOProcessImage from "../../../resources/images/aeoprocess.png";
import CenterDiv from "../../../style/styled-components/components/CenterDiv";
import ContactPoints from "../../../components/ui/members/ContactPoints";
import DiamondScope from "../../../components/ui/specialty/DiamondScope";
import ImageDiv from "../../../style/styled-components/components/ImageDiv";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import WorkScopes from "../../../components/ui/specialty/WorkScopes";
import img1 from "../../../resources/images/icon/8. AEO/1. AEO 신규인증.png";
import img2 from "../../../resources/images/icon/8. AEO/2. AEO사후관리.png";
import img3 from "../../../resources/images/icon/8. AEO/3. AEO 정기자체평가 관리.png";
import img4 from "../../../resources/images/icon/8. AEO/4. AEO 평가 대응서비스.png";
import img5 from "../../../resources/images/icon/8. AEO/5. AEO 종합심사대응.png";
import styled from "styled-components";

const AEOPage = () => {
  return (
    <PageTemplate
      large
      image={AEOImage}
      title="AEO 인증"
      phrase="AEO의 시작과 성장 및 발전을 함께 해온 에이원만의 Know-How를 제공합니다."
      description="국내의 수출입 기업 및 포워딩, 운영인 등 수출입 공급망의 기업을 대상으로
      AEO 인증 획득, 사후관리, 정기자체평가서 평가 및 제출 지원, AEO
      인증기업의 종합심사 대응, 정기 수입세액정산제도에 따른 심사와 보고서
      작성 및 기획/법인심사 대리, 사전 Audit, FTA(원산지 검증, 서류 발급) 등
      관세업무에서 발생할 수 있는 모든 업무에 대한 서비스를 제공합니다.
      해당 업무에는 관세사 및 관세 전문가를 전담으로 배정하여 기업의
      환경에 따라 제공하는 맞춤형 서비스를 제공합니다."
    >
      <TitleTemplate
        title="AEO 인증절차"
        noImage
        subHeader="AEO 인증부터 종합심사까지 필요한 모든 업무에 대한 서비스를 제공합니다."
      />
      <ImageDiv>
        <FTAImage src={AEOProcessImage} alt="AEO 인증 프로세스" />
      </ImageDiv>
      <TitleTemplate title="AEO 인증 혜택" noImage />
      <CenterDiv>
        <AEOBenefitTable>
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th rowSpan={2}>특례기준</th>
              <th colSpan={3}>AEO 등급</th>
              <th rowSpan={2}>비고</th>
            </tr>
            <tr>
              <th>A</th>
              <th>AA</th>
              <th>AAA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>행정형벌보다 과태료 등 행정질서벌 우선 고려</td>
              <td>○</td>
              <td>○</td>
              <td>○</td>
              <td>AM정보제공</td>
            </tr>
            <tr>
              <td>과태료 경감</td>
              <td>20%</td>
              <td>30%</td>
              <td>50%</td>
              <td></td>
            </tr>
            <tr>
              <td>통고처분 금액의 경감</td>
              <td>15%</td>
              <td>30%</td>
              <td>50%</td>
              <td></td>
            </tr>
            <tr>
              <td>기업 ERP에 의한 수출입 및 화물신고</td>
              <td>○</td>
              <td>○</td>
              <td>○</td>
              <td></td>
            </tr>
            <tr>
              <td>신고오류로 인한 제재 감경</td>
              <td>30%</td>
              <td>50%</td>
              <td>70%</td>
              <td>PL정지 등</td>
            </tr>
            <tr>
              <td>AM에 의한 수출입신고 정정, 오류점수 미부과</td>
              <td>○</td>
              <td>○</td>
              <td>○</td>
              <td></td>
            </tr>
            <tr>
              <td>
                국제공항 입출국시 전용검사대 이용
                <br />
                국제공항 출국시 승무원 전용통로 이용
              </td>
              <td>○</td>
              <td>○</td>
              <td>○</td>
              <td>
                A/AA: 대표자
                <br />
                AAA: 대표자, 총괄책임자
              </td>
            </tr>
          </tbody>
        </AEOBenefitTable>
      </CenterDiv>
      <TitleTemplate
        title="업무범위"
        noImage
        subHeader="각 인증 부문별 고객사에 해당하는 맞춤형 서비스를 제공합니다."
      />
      <WorkScopes
        contents={[
          { title: "AEO 신규인증", icon: img1 },
          { title: "AEO 사후관리", icon: img2 },
          { title: "AEO 정기자체평가 관리", icon: img3 },
          { title: "AEO 평가 대응 서비스", icon: img4 },
          { title: "AEO 종합심사대응", icon: img5 },
        ]}
      />
      <TitleTemplate
        title="Strength"
        noImage
        subHeader="A-One 만의 장점으로 고객을 힘껏 서포트 해 나가겠습니다."
      />
      <DiamondScope
        contents={[
          {
            title: "ONE-STOP SERVICE",
            text: "신규인증부터 재인증까지 책임 서비스 ",
          },
          {
            title: "TASK-FORCE",
            text: "국내 최고 AEO전문 전담팀 구성으로 전문성 확보",
          },
          {
            title: "Management",
            text: "사후관리를 통한 AEO 재인증 심사 대비",
          },
          {
            title: "Consulting",
            text: "전문 컨설턴트가 기업을 직접 방문하여 AEO 인증관리",
          },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["AEOMain"]} />
    </PageTemplate>
  );
};
export default AEOPage;
const FTAImage = styled.img`
  width: 70em;
`;
const AEOBenefitTable = styled.table`
  border-collapse: collapse;
  & > thead {
    & > tr {
      &:first-child {
        & > th:first-child {
          border-radius: 1rem 0 0 0;
        }
        & > th:last-child {
          border-radius: 0 1rem 0 0;
        }
      }
      & > th {
        font-weight: normal;
        font-size: 1.2em;
        background-color: ${({ theme }) => theme.colors?.dark};
        color: white;
      }
    }
  }
  & > tbody {
    & * {
      padding: 1em;
      text-align: center;
    }
    & > tr {
      &:nth-child(even) {
        background-color: ${({ theme }) => theme?.colors?.monotone?.extralight};
      }
      &:last-child {
        & > td {
          &:first-child {
            border-radius: 0 0 0 1rem;
          }
          &:last-child {
            border-radius: 0 0 1rem 0;
          }
        }
      }
    }
  }
`;
