import styled from "styled-components";
import PageTemplate from "../../components/ui/common/template/PageTemplate";
import BoardMemberCard from "../../components/ui/members/BoardMemberCard";
import { getBoardMemberbyName } from "../../context/members/boardscontext";
import CenterDiv from "../../style/styled-components/components/CenterDiv";
import PrizeImage from "./../../resources/images/background/intro/prizes.jpg";
const BoardMembersPage = () => {
  return (
    <PageTemplate
      title="경영진 소개"
      large
      image={PrizeImage}
      phrase="현역 관세 스페셜리스트가 관세법인에 최적화된 경영을 선도하고 있습니다."
    >
      <PageDiv>
        <div>
          <CenterDiv>
            <BoardMemberCard type="1" member={getBoardMemberbyName("정운기")} />
          </CenterDiv>
          <VPDiv>
            <BoardMemberCard type="3" member={getBoardMemberbyName("황재호")} />
            <BoardMemberCard type="3" member={getBoardMemberbyName("박찬욱")} />
          </VPDiv>
          <VPDiv>
            <BoardMemberCard type="3" member={getBoardMemberbyName("이성욱")} />
            <BoardMemberCard type="2" member={getBoardMemberbyName("송창석")} />
          </VPDiv>
          <VPDiv>
            <BoardMemberCard type="2" member={getBoardMemberbyName("유길석")} />
          </VPDiv>
        </div>
      </PageDiv>
    </PageTemplate>
  );
};
export default BoardMembersPage;
const PageDiv = styled.div`
  & > div {
    display: flex;
    flex-direction: column;
  }
`;
const VPDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin-top: 2em;
`;
