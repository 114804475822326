import imgP from "../../resources/images/profiles/hq/정운기회장님3-removebg.png";
import imgLee from "../../resources/images/profiles/busan/이성욱.png";
import imgPark from "../../resources/images/profiles/hq/박찬욱님-removebg.png";
import imgHwang from "../../resources/images/profiles/hq/황재호님-removebg.png";
import imgYoo from "../../resources/images/profiles/유길석-removebg.png";
import imgSong from "../../resources/images/profiles/other/송창석.png";
const boardmembers = [
  {
    name: "정운기",
    rank: "P",
    engTitle: "President",
    history: [
      {
        div: "現",
        content: "관세동우회 회장",
      },
      {
        div: "現",
        content: "AEO 진흥협회 부회장",
      },
      {
        div: "前",
        content: "한국관세사회 회장(2007-2009)",
      },
      {
        div: "前",
        content: "관세행정발전 심의위원(관세청)",
      },
      {
        div: "前",
        content: "한국관세무역개발원 원장",
      },
      {
        div: "前",
        content: "인천공항세관 세관장",
      },
      {
        div: "前",
        content: "주 미국 한국대사관 관세참사관",
      },
      {
        div: "前",
        content: "관세중앙분석소장",
      },
    ],
    image: imgP,
  },
  {
    name: "송창석",
    rank: "R",
    engTitle: "Representative",
    history: [
      {
        div: "現",
        content: "서울본부세관 보세창고 특허심사위원회 위원",
      },
      {
        content: "한국관세학회 부회장",
      },
      {
        content: "기업협력협의회 이사, 중소기업청 자문위원",
      },
      {
        content: "인하물류대학원 MBA 석사",
      },
      {
        content: "연세정보대학원 최고위과정",
      },
      {
        content: "인하물류대학원 GLMP 수료",
      },
      {
        content: "기획재정부 장관상, 산업통상부 장관상 수상",
      },
      {
        content: "로얄어패럴 무역부(외자구매, 물류) 근무(1994~1998)",
      },
    ],
    image: imgSong,
  },

  {
    name: "황재호",
    rank: "VP",
    engTitle: "Representative",
    history: [
      {
        div: "現",
        content: "중국 옌타이 보세항구 자문위원",
      },
      {
        div: "現",
        content: "한국관세사회 제도발전위원회 위원",
      },
      {
        div: "現",
        content: "중앙행정심판위회 자문위원",
      },
      {
        div: "前",
        content: "한국관세사회 상생협력위원회 위원",
      },
    ],
    image: imgHwang,
  },

  {
    name: "박찬욱",
    rank: "VP",
    engTitle: "Representative",
    history: [
      {
        div: "現",
        content: "한국관세사회 정보화위원회 위원",
      },
      {
        div: "現",
        content: "한국관세사회 평택지부 운영위원",
      },
      {
        div: "現",
        content: "평택세관 외부평가위원",
      },
      {
        div: "現",
        content: "농림축산식품부 유통이력 심의위원",
      },
      {
        div: "前",
        content: "벤쳐기업협회 자문 관세사",
      },
      {
        div: "前",
        content: "구로공단 무역/통관 자문위원",
      },
      {
        div: "前",
        content: "관세청 CRM 자문위원",
      },
      {
        div: "前",
        content: "서울중소기업청 자문관세사",
      },
    ],
    image: imgPark,
  },

  {
    name: "이성욱",
    rank: "VP",
    engTitle: "Representative",
    history: [
      {
        div: "現",
        content: "농림축산검역본부 자문관세사",
      },
      {
        div: "前",
        content: "부산지방식약청 자문위원",
      },
      {
        content: "부경대 산업대학원 식품공학 수료",
      },
      {
        content: "해양수산부 항만물류 최고위과정 수료",
      },
    ],
    image: imgLee,
  },
  {
    name: "유길석",
    rank: "CFO",
    engTitle: "CFO",
    history: [
      // { div: '現', content: '관세법인 에이원 CFO(전무)' },
      { div: "現", content: "서울세관 발전심의위원" },
      { div: "前", content: "한국관세무역개발원 물류본부장" },
      { div: "前", content: "한국관세무역개발원 상무이사" },
    ],
    image: imgYoo,
  },
];

export default boardmembers;

export const getBoardMemberbyName = (name) => {
  return boardmembers.find((x) => x.name === name);
};
