import styled from "styled-components";
const AuditPointsTable = () => {
  return (
    <Table>
      <colgroup>
        <col />
        <col />
        <col />
      </colgroup>
      <thead>
        <tr>
          <th>심사종류</th>
          <th>주요포인트</th>
          <th>타업체 주요 적발사항</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>과세가격</td>
          <td>
            <ul>
              <li>거래가격 적정성</li>
              <li>가산/공제신고 적정성</li>
              <li>특수관계자간 가격 적정성</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>특수관계자간 거래물품 저가신고/역마진</li>
              <li>비정상적 할인, 채권/채무간 상계로 인한 저가신고</li>
              <li>
                현지구매 원재료비/원자재 저가제공, 인건비등 생산지원비 누락
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>외환</td>
          <td>
            <ul>
              <li>무역 지급내역 신고/과표 누락</li>
              <li>무역외 지급 내역 신고 적정성</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>상계, 제3자지급 등 미신고 및 과표 누락</li>
              <li>해외운영자금 신고/변경신고 누락</li>
              <li>임차 기계 등의 신고 및 과표 누락</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>품목분류</td>
          <td>
            <ul>
              <li>세번 적정성</li>
              <li>세율 적정성</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>재질분류 물품의 부분품 세번 적용 오류</li>
              <li>동일물품의 세번 상이 오류</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>환급</td>
          <td>
            <ul>
              <li>소요량 적정성</li>
              <li>환급대상 원재료 적정성</li>
              <li>부산물 공제 적정성</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>마감데이터 오류로 인한 소요량 과다</li>
              <li>환급 비대상 원재료의 과다환급, 이중환급, 대체환급 부적정</li>
              <li>부산물 미공제</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>감면</td>
          <td>
            <ul>
              <li>감면대상 물품 적정성</li>
              <li>사후관리 이행 적정성</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>재수출 이행여부</li>
              <li>감면물품의 용도외 사용 미신고</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>요건 등</td>
          <td>
            <ul>
              <li>절차 이행여부 적정성</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>유해화학물질 확인명세서 미제출 등</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
export default AuditPointsTable;
const Table = styled.table`
  border-collapse: collapse;
  & > thead {
    font-size: 1.2em;
    background-color: ${({ theme }) => theme.colors?.dark};
    color: ${({ theme }) => theme.colors?.foreground?.inverted};
    & > tr {
      &:first-child {
        & > th {
          font-weight: normal;
          padding: 1em;
          &:first-child {
            border-radius: 1rem 0 0 0;
            background-color: ${({ theme }) => theme.colors?.dark};
          }
          &:last-child {
            border-radius: 0 1rem 0 0;
          }
        }
      }
    }
  }
  & > tbody {
    & > tr {
      &:nth-child(even) {
        background-color: ${({ theme }) => theme.colors?.monotone?.extralight};
      }
      & > td {
        padding: .5em 1em;
        &:first-child {
          text-align: center;
        }
      }
      &:last-child {
        & > td:first-child {
          border-radius: 0 0 0 1rem;
        }
        & > td:last-child {
          border-radius: 0 0 1rem 0;
        }
      }
    }
    & ul {
      margin: 0;
      padding: 0.5em 2em;
    }
  }
`;
