import { Fragment } from "react";
import styled from "styled-components";
import UseBreakpoints from "../../../../hooks/ui/UseBreakpoints";
const BranchSimpleMapBus = ({ bus }) => {
  const breakpoints = UseBreakpoints();
  const busType = [
    {
      type: "시내",
      node: "city",
      color: "rgb(86, 199, 51)",
    },
    {
      type: "간선",
      node: "major",
      color: "rgb(39, 112, 194)",
    },
    {
      type: "지선",
      node: "minor",
      color: "rgb(86, 199, 51)",
    },
    {
      type: "마을",
      node: "local",
      color: "rgb(106, 211, 90)",
    },
    {
      type: "광역",
      node: "express",
      color: "rgb(247, 69, 22)",
    },
    {
      type: "공항",
      node: "airport",
      color: "#6bb8d7",
    },
  ];
  return (
    <>
      {bus && (
        <Div>
          <div className="title">
            <div>버스 이용 시 </div>
            <hr />
            {bus.description && <div>{bus.description}</div>}
          </div>
          <div className="from">
            <ul>
              {bus.from?.airport && (
                <li>
                  <div>✈️</div>
                  <div>{bus.from?.airport.name}에서</div>
                  <div>
                    {bus.from?.airport.bus?.map((bus, idx) => {
                      return <div key={idx}>{bus}</div>;
                    })}
                  </div>
                </li>
              )}
              {bus.from?.terminal && (
                <li>
                  <div>🚌</div>
                  <div>{bus.from?.terminal.name}에서</div>
                  <div>
                    {bus.from?.terminal.bus?.map((bus, idx) => {
                      return <div key={idx}>{bus}</div>;
                    })}
                  </div>
                </li>
              )}
              {bus.from?.train && (
                <li>
                  <div>🚆</div>
                  <div>{bus.from?.train.name}에서</div>
                  <div>
                    {bus.from?.train?.bus?.map((bus, idx) => {
                      return <div key={idx}>{bus}</div>;
                    })}
                  </div>
                </li>
              )}
              {/* {from.terminal && <li>{from}</li>}
                  {from.train && <li>{from}</li>} */}
            </ul>
          </div>
          <div className="byType">
            {busType?.map((type, idx) => {
              return (
                <Fragment key={idx}>
                  {bus.buses?.[type.node] && (
                    <BusListByType breakpoints={breakpoints} color={type.color}>
                      <div>
                        <div className="bustype">{type.type}</div>
                      </div>
                      <ul>
                        {bus?.buses?.[type.node]?.map((number, idx) => (
                          <li key={idx}>
                            <div>{number}</div>
                            <div>
                              {bus?.stops
                                ?.filter((x) => x.bus.includes(number))
                                .map((stop, idx) => {
                                  return (
                                    <div key={idx}>
                                      <div>{stop.direction}방향</div>
                                      <div>
                                        {stop.stop}({stop.code})
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </BusListByType>
                  )}
                </Fragment>
              );
            })}
          </div>
        </Div>
      )}
    </>
  );
};
export default BranchSimpleMapBus;
const Div = styled.div`
  padding: 1em;
  & > div {
    &.title {
      & > div {
        padding: 0 1em;
        &:first-child {
          font-size: 1.5em;
          font-weight: bold;
        }
        &:nth-child(3) {
          color: ${({ theme }) => theme?.colors?.foreground?.dimmed};
        }
      }
    }
    &.from {
      // 어디서 오십니까?
      & > ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        & > li {
          display: flex;
          gap: 1em;
          & > div {
            display: flex;
            gap: 0.5em;
          }
        }
      }
    }
    &.byType {
      display: flex;
      flex-direction: column;
      align-items: left;
      padding: 1rem;
      gap: 1rem;
    }
  }
`;
const BusListByType = styled.div`
  padding: 0em 1em;
  margin: 0;
  display: flex;
  align-items: flex-start;
  list-style: none;
  gap: 1em;
  & > div {
    display: flex;
    min-width: ${({ breakpoints }) => (breakpoints.sm ? "5em" : "3.5em")};
    & > div {
      &.bustype {
        // 버스타입
        display: flex;
        font-size: 0.9em;
        padding: 0.1em 1em;
        border-radius: 1em;
        background-color: ${({ color }) => color};
        color: ${({ theme }) => theme.colors?.foreground?.inverted};
      }
    }
  }
  & > ul {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 0.6em;
    align-items: center;
    list-style: none;
    flex-wrap: wrap;
    & > li {
      cursor: pointer;
      position: relative;
      & > div:first-child {
        &:hover + div {
          display: flex;
        }
      }
      & > div:last-child {
        position: absolute;
        display: none;
        font-size: 0.8em;
        flex-direction: column;
        overflow-wrap: unset;
        width: 20em;
        top: 0em;
        left: 100%;
        z-index: 1;
        background-color: #eee;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        & > div {
          display: flex;
          flex-direction: column;
          & > div:first-child {
            font-size: 0.7em;
            color: ${({ theme }) => theme.colors?.foreground?.highlight2};
          }
        }
      }

      // 각 버스 번호
      /* padding: 0.1em 0.5em;
            border-radius: 0.5em;
            background-color: #eee; */
    }
  }
`;
