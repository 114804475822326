import CenterDiv from "../../style/styled-components/components/CenterDiv";
import ContactPoints from "../../components/ui/members/ContactPoints";
import EducationImage from "../../resources/images/education.jpg";
import PageTemplate from "../../components/ui/common/template/PageTemplate";
import TitleTemplate from "../../components/ui/common/template/TitleTemplate";
import TriangleScope from "../../components/ui/specialty/TriangleScope";
import UseBreakpoints from "../../components/hooks/ui/UseBreakpoints";
import styled from "styled-components";
// import EducationImage from "../../../resources/images/contestation.jpg";

const EducationPage = () => {
  const breakpoints = UseBreakpoints();
  return (
    <PageTemplate
      large
      image={EducationImage}
      title="기업교육"
      phrase="글로벌 기업의 미래를 선도할 무역 인재들의 경쟁력을 책임지겠습니다."
    >
      <p>
        전문성과 노하우를 모두 갖춘 최고의 전문가와 함께 관세 무역 교육을
        제공합니다. <br /> 국제무역, 수출입통관실무, 통관적법성, AEO, 기업 관세
        Issue 별 특화 교육 등 관세 무역 분야의 기초부터 심화까지 교육 대상의
        눈높이에 맞는 종합 교육을 실시합니다.
      </p>

      <TitleTemplate
        title="교육 구성"
        noImage
        subHeader="온 · 오프라인 교육 플랫폼 제공을 통해 학습 효율성을 제고합니다."
      />
      <CenterDiv breakpoints={breakpoints}>
        <DashedBox>
          <div>Offline Learning</div>
          <div>오프라인 현장교육</div>
          <hr />
          <div>
            기업 환경 분석을 통하여
            <br />
            최적화된 교육 커리큘럼 설계
          </div>
          <div>
            <ul>
              <li>담당자 업무 역량을 고려한 맞춤형 눈높이 교육</li>
              <li>강사와의 상호 커뮤니케이션을 통한 현장 밀착형 교육</li>
              <li>교육 후 주요 법령 개정 시 관세 웨비나 제공 가능</li>
            </ul>
          </div>
        </DashedBox>
        <DashedBox>
          <div>Online Learning</div>
          <div>온라인 실시간 교육</div>
          <hr />
          <div>
            온라인 교육 커리큘럼에서
            <br />
            수강 희망 과정 선택
          </div>
          <div>
            <ul>
              <li>관세 · 무역 전 분야에 걸친 교육 커리큘럼 보유</li>
              <li>핵심 내용 위주로 구성된 컨텐츠로 학습 집중도 향상</li>
              <li>기업 전용 온라인 플랫폼을 통한 교육관리 효율화</li>
            </ul>
          </div>
        </DashedBox>
      </CenterDiv>
      <TitleTemplate
        title="AONE 교육서비스 강점"
        noImage
        subHeader="실무 경험을 기반으로 한 수준 높은 강사진에 의한 맞춤형 교육을 제공합니다."
      />
      <CenterDiv breakpoints={breakpoints}>
        <StrengthBox>
          <div>
            <div>Professional Instructors</div>
          </div>
          <div>전문 강사진</div>
          <div>
            <ul>
              <li>
                <mark>분야별 전문성과 노하우</mark>를 갖춘 전문 강사진 보유
              </li>
              <li>
                현업 강사에 의한 <mark>정확한 이론과 실무 강의</mark> 제공
              </li>
            </ul>
          </div>
        </StrengthBox>
        <StrengthBox>
          <div>
            <div>Various Education Courses</div>
          </div>
          <div>다양한 교육 과정</div>
          <div>
            <ul>
              <li>
                신입사원부터 관리자까지, 업무 역량을 고려한{" "}
                <mark>맞춤형 눈높이 교육</mark>
              </li>
              <li>
                관세 · 무역 전 분야에 대하여{" "}
                <mark>기초부터 심화까지 교육 커리큘럼</mark> 보유
              </li>
            </ul>
          </div>
        </StrengthBox>
        <StrengthBox>
          <div>
            <div>Efficient Edu Infrastructure</div>
          </div>
          <div>효율적인 교육 인프라</div>
          <div>
            <ul>
              <li>
                기업 환경에 최적화된 교육 서비스 제공을 위한{" "}
                <mark>다양한 교육 플랫폼</mark> 보유
              </li>
              <li>
                <mark>온 · 오프라인 교육의 병행</mark>을 통한 교육 효율성 제고
              </li>
            </ul>
          </div>
        </StrengthBox>
      </CenterDiv>

      <TitleTemplate
        title="Strength"
        noImage
        subHeader="A-One 만의 장점으로 고객을 힘껏 서포트 해 나가겠습니다."
      />
      <TriangleScope
        contents={[
          {
            title: "MAN-POWER",
            text: "전문성과 노하우를 모두 갖춘 최고의 전문가들로 구성된 강사진",
          },
          {
            title: "Infrastructure",
            text: "다양한 교육 인프라를 활용하여 고객사에게 최적화된 교육환경 제공",
          },
          {
            title: "Consulting",
            text: "고객사별 맞춤형 교육 과정 설계",
          },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
        <ContactPoints members={["corpEduMain"]} />
    </PageTemplate>
  );
};
export default EducationPage;
const DashedBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1em;
  padding: 1rem;
  border: dashed 1px #888;
  gap: 1rem;
  & > div {
    &:first-child {
      font-size: 1.2em;
      text-align: center;
    }
    &:nth-child(2) {
      font-size: 1.8em;
      font-weight: bold;
      text-align: center;
    }
    &:nth-child(4) {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 2rem;
      font-size: 1.5em;
      line-height: 2em;
      background-image: radial-gradient(#ace7 20%, transparent 20%),
        radial-gradient(#ace7 20%, transparent 20%);
      background-color: transparent;
      background-position: 0 0, 3px 3px;
      background-size: 6px 6px;
    }
    &:nth-child(5) {
      display: flex;
      justify-content: center;
      line-height: 2em;
      font-size: 1.1em;
      & > ul {
        list-style: none;
        & > li:before {
          content: "✔️";
          margin-left: -1em;
          margin-right: 0.5em;
        }
      }
    }
  }
  & > hr {
    width: 90%;
  }
`;
const StrengthBox = styled.div`
  box-shadow: 1rem 1rem 3rem #c7c7c7, -1rem -1rem 3rem #ffffff;
  border-radius: 1rem;
  padding: 2rem;
  & > div {
    &:first-child {
      font-size: 1.5em;
      position: relative;
      top: 1.4em;
      right: 0.3em;
      color: ${({ theme }) => theme.colors?.medium};
      text-align: center;
      opacity: 0.3;
      display: flex;
      justify-content: right;
    }
    &:nth-child(2) {
      z-index: 1;
      border-radius: 1rem;
      text-align: center;
      padding: 1rem;
      background-color: ${({ theme }) => theme.colors?.dark};
      color: white;
      font-size: 1.2em;
      font-weight: bold;
    }
    &:nth-child(3) {
      font-size: 1.1em;
      line-height: 2em;
      & li {
        padding: 1rem;
      }
      & mark {
        background-color: inherit;
        font-weight: bold;
      }
    }
  }
`;
