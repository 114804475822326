import styled from "styled-components";
import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import DiamondScope from "../../../components/ui/specialty/DiamondScope";
import HeadedBox from "../../../components/ui/common/template/HeadedBox";
import AuditExperienceTable from "../../../components/contents/specialty/legal/customs/AuditExperienceTable";
import AuditPointsTable from "../../../components/contents/specialty/legal/customs/AuditPointsTable";
import CustomsImage from "../../../resources/images/customs_legal.jpg";
import CenterDiv from "../../../style/styled-components/components/CenterDiv";
import ContactPoints from "../../../components/ui/members/ContactPoints";
import ContentDiv from "../../../style/styled-components/components/ContentDiv";
const QNLPage = () => {
    return (
        <PageTemplate
            large
            image={CustomsImage}
            title="물류서비스"
            phrase="체계적인 물류 서비스를 제공합니다."
        >
            <p>
                물류 서비스가 있습니다.
                <br />큐앤엘 솔루션은 물류 서비스를 제공합니다.
            </p>
            <TitleTemplate title="물류서비스" noImage />
            <CenterDiv>


            </CenterDiv>

            <TitleTemplate
                title="Strength"
                noImage
                subHeader="물류 컨설팅을 수행합니다."
            />
            <DiamondScope
                contents={[
                    {
                        title: "화물",
                        text: "화물 잘다룸",
                    },
                    {
                        title: "배차",
                        text: "칼같은 배차",
                    },
                    {
                        title: "창고",
                        text: "유수의 제휴창고",
                    },
                    {
                        title: "3PL",
                        text: "토탈 물류",
                    },
                ]}
            />
            <TitleTemplate
                title="Contact Point"
                noImage
                subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
            />
            <ContactPoints members={["suk1279"]} />
        </PageTemplate>
    );
};
export default QNLPage;
const AuditTypeDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;