import { useState } from "react";
import styled from "styled-components";
import CenterDiv from "../../../../../style/styled-components/components/CenterDiv";
import UseBreakpoints from "../../../../hooks/ui/UseBreakpoints";
import SelectOne from "../../../common/SelectOne";
import BranchMapHOC from "./BranchMapHOC";
const BranchDetails = ({ detail }) => {
  const breakpoints = UseBreakpoints();
  const [selected, setSelected] = useState(0);
  return (
    <Div breakpoints={breakpoints}>
      <div>
        <BranchNameSpan>{detail.name}</BranchNameSpan>
        <ul>
          {detail.branches?.map((x) => (
            <li key={x}>{x}</li>
          ))}
        </ul>
        <AddressDiv>
          <div>🏢</div>
          <AddressSpan>
            {detail.address1} <span>{detail.addressKey}</span>
          </AddressSpan>

          <AddressSpan>{detail.address2}</AddressSpan>
        </AddressDiv>
        <ContactDiv>
          <div>
            <TelSpan title="tel">📞 {detail.tel}</TelSpan>
          </div>
          {detail?.fax && (
            <div>
              <TelSpan title="fax">📠 {detail.fax}</TelSpan>
            </div>
          )}
        </ContactDiv>
        <CenterDiv>
          <SelectOne
            options={["오시는길", "구글맵"]}
            selected={selected}
            setSelected={setSelected}
          />
        </CenterDiv>
      </div>
      <BranchMapHOC selected={selected} detail={detail} />
    </Div>
  );
};
export default BranchDetails;
const Div = styled.div`
  flex: 1;
  display: flex;
  flex-direction: ${({ breakpoints }) => (breakpoints?.xl ? "row" : "column")};
  padding: ${({ breakpoints }) => breakpoints?.md ? "0" : "2rem" } 0;
  & > div:first-child {
    min-width: ${({ breakpoints }) => (breakpoints?.md ? "15em" : null)};
    flex: ${({ breakpoints }) => (breakpoints?.lg ? null : 1)};
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.colors?.foreground.dimmed};
    background-color: ${({ theme }) => theme.colors?.monotone.light};
    padding: 2em;
    font-size: 1.2em;
    & > hr {
      width: 100%;
    }
    & ul li {
      line-height: 2em;
      font-size: 1.1em;
    }
  }
`;
const BranchNameSpan = styled.span`
  font-size: 2em;
  font-weight: bold;
  color: ${({ theme }) => theme.colors?.foreground.normal};
`;
const AddressSpan = styled.span`
  & > span {
    font-weight: bold;
  }
`;

const TelSpan = styled.span``;
const ContactDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1em;
  font-size: 0.9em;
  & > div {
    padding: 0 0.5em;
  }
`;
const AddressDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.colors?.monotone?.medium};
  border-radius: 1em;
  padding: 1em;
  background-color: inherit;
  font-size: 0.9em;
  & > div:first-child {
    position: absolute;
    top: -0.8em;
    left: 1em;
    background-color: inherit;
    padding: 0 0.3em;
  }
`;
