import styled from "styled-components";
import { device } from "../../../../style/styled-components/helpers/device";
import UseBreakpoints from "../../../hooks/ui/UseBreakpoints";
import QualityManagementSub from "./QualityManagementSub";
const QualityManagement = () => {
  const breakpoints = UseBreakpoints();
  return (
    <Div breakpoints={breakpoints}>
      <div>
        <QualityManagementSub
          number="01"
          title="AEO AA등급 취득"
          text="수출입안전관리 우수업체 AA등급 취득으로 안정적인 관세업무 서비스 제공"
        />
        <QualityManagementSub
          number="02"
          title="높은 법규 준수도 유지"
          text="오류 없는 관세서비스 제공을 통해 수년간 업계 최고수준으로 법규준수도 유지"
        />
        <QualityManagementSub
          number="03"
          title="IT solution 제공"
          text="IT solution 검증 모듈을 활용하여 관세업무 리스크를 사전 예방하여 고품질의 관세서비스 제공"
        />
        <QualityManagementSub
          number="04"
          title="손해배상책임보험 가입"
          text="약 10.7억 상당의 손해배상책임보험에 가입하여 지속 가능한 재정유지"
        />
      </div>
    </Div>
  );
};
export default QualityManagement;
const Div = styled.div`
  position: relative;
  margin-top: 5rem;
  background-image: radial-gradient(#ace7 13%, transparent 20%),
    radial-gradient(#ace7 13%, transparent 20%);
  background-color: transparent;
  background-position: 0 0, 3px 3px;
  background-size: 6px 6px;
  & > div {
    position: relative;
    display: grid;
    grid-row-gap: 4rem;
    top: -4rem;

    @media ${device.tablet} {
      top: -4.5rem;
    }
    @media ${device.laptop} {
      top: -6rem;
    }
    grid-template-columns: ${({ breakpoints }) =>
      breakpoints?.lg ? "1fr ".repeat(4) : "1fr ".repeat(2)};
  }
`;
