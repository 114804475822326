import styled from "styled-components";
import UseBreakpoints from "../../hooks/ui/UseBreakpoints";
const CompareTableRow = ({ contents: { div, content1, content2 } }) => {
  const breakpoints = UseBreakpoints();
  return (
    <Div breakpoints={breakpoints}>
      {content1 && (
        <ContentDiv breakpoints={breakpoints}>{content1}</ContentDiv>
      )}
      <DivDiv breakpoints={breakpoints}>{div}</DivDiv>
      {content2 && (
        <ContentDiv breakpoints={breakpoints}>{content2}</ContentDiv>
      )}
    </Div>
  );
};
export default CompareTableRow;
const Div = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  border-bottom: ${({ theme }) => theme.colors?.dark} 2px dotted;
  & > div {
    display: flex;
    justify-content: center;
    &:first-child,
    &:last-child {
    }
  }
`;
const ContentDiv = styled.div`
flex: 1;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: ${({ breakpoints }) =>
    breakpoints?.xxl ? "1.2em" : breakpoints?.lg ? "1.05em" : "0.9em"};
    width: 25em;
`;
const DivDiv = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.dark};
  font-weight: bold;
  color: white;
  padding: 0.5em;
  text-align: center;
  width: ${({ breakpoints }) =>
    breakpoints?.xl ? "8em" : breakpoints?.md ? "7em" : "6em"};
  font-size: ${({ breakpoints }) =>
    breakpoints?.xl ? "1.4em" : breakpoints?.md ? "1.2em" : "1em"};
`;
