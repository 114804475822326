import { useMediaQuery } from "react-responsive";
import { size } from "../../../style/responsive/resolution";
const UseBreakpoints = () => {
  const xxl = useMediaQuery({ minWidth: size.xxl });
  const xl = useMediaQuery({ minWidth: size.xl });
  const lg = useMediaQuery({ minWidth: size.lg });
  const md = useMediaQuery({ minWidth: size.md });
  const sm = useMediaQuery({ minWidth: size.sm });
  const xs = useMediaQuery({ minWidth: size.xs });
  const tablet = useMediaQuery({ minWidth: size.tablet});
  const laptop = useMediaQuery({ minWidth: size.laptop});
  return {
    xxl: xxl,
    xl: xl,
    lg: lg,
    md: md,
    sm: sm,
    xs: xs,
    tablet: tablet,
    laptop: laptop,
  };
};
export default UseBreakpoints;
