import styled from "styled-components";
import { device } from "../../../../style/styled-components/helpers/device";
import ExpressUnderstandingDiv from "./ExpressUnderstandingDiv";
import ExpressUnderstandingType from "./ExpressUnderstandingType";
const ExpressUnderstanding = () => {
  return (
    <Div>
      <ExpressUnderstandingType
        type="목록통관"
        contents={[
          "자가 사용 물품 중 미화 150불 이하 물품",
          "기업 사용 샘플 중 미화 150불 이하 물품",
        ]}
      />
      <div>
        <ExpressUnderstandingDiv amount="$150" />
        <div />
      </div>
      <ExpressUnderstandingType
        type="간이수입신고"
        contents={[
          "미화 150불 초과 2,000불 이하",
          "수입제한 품목 등 일부 품목은 일반수입신고",
        ]}
      />
      <div>
        <ExpressUnderstandingDiv amount="$2,000" />
        <div />
      </div>
      <ExpressUnderstandingType
        type="일반수입신고"
        contents={[
          "미화 2,000불 초과 2,000불 이하",
          "목록통관, 간이수입신고 대상 제외물품",
        ]}
      />
    </Div>
  );
};
export default ExpressUnderstanding;
const Div = styled.div`
  display: flex;
  padding-top: 8rem;
  @media ${device.laptop} {
  padding-top: 10rem;
  }
  align-items: flex-start;
  @media ${device.tablet} {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  & > div:nth-child(odd) {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  & > div:nth-child(even) {
    position: relative;
    & > div:first-child {
      position: absolute;
      bottom: 1rem;
      @media ${device.mobileL} {
        bottom: 3rem;
      }
      @media ${device.laptop} {
        bottom: 5rem;
      }
      left: -3rem;
    }
    & > div:nth-child(2) {
      position: absolute;
      width: 0.3rem;
      left: -0.15rem;
      height: 1rem;
      top: -1rem;
      @media ${device.mobileL} {
        height: 3rem;
        top: -3rem;
      }
      @media ${device.laptop} {
        height: 5rem;
        top: -5rem;
      }
      background-color: ${({ theme }) => theme.colors?.dark};
    }
  }
`;
