import styled from "styled-components";
const TitlePill = ({ title }) => {
  return <Div>{title}</Div>;
};
export default TitlePill;
const Div = styled.div`
  margin: 1em 0;
  padding: 0.5em 1em;
  font-size: 1.5em;
  font-weight: bold;
  background-color: ${({theme}) => theme.colors?.dark};
  border-radius: 1.5em;
  color: white;
`;
