import { Link } from "react-router-dom";
import styled from "styled-components";
import ArticleListElement from "./ArticleListElement";
const ArticleList = ({ category, contents = [] }) => {
  return (
    <Div>
      {contents.length > 0 ? (
        <>
          {contents?.map(({ title, no, date }, idx) => (
            <ArticleListElement
              key={idx}
              category={category}
              title={title}
              no={no}
              date={date}
            />
          ))}
        </>
      ) : <div>등록된 글이 없습니다.</div>}
    </Div>
  );
};
export default ArticleList;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: .3rem;
  margin: 1rem 0;
`;
