import styled from "styled-components";
import UseBreakpoints from "../../../hooks/ui/UseBreakpoints";
const HeadedBox = ({ div, subdiv, text }) => {
  const breakpoints = UseBreakpoints();
  return (
    <Div breakpoints={breakpoints}>
      <div>
        <div>{div}</div>
        <div>{subdiv}</div>
      </div>
      <div>
        <div>{text}</div>
      </div>
    </Div>
  );
};
export default HeadedBox;
const Div = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors?.dark};
  max-width: 65rem;
  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
    background-color: ${({ theme }) => theme.colors?.dark};
    min-width: ${({ breakpoints }) => (breakpoints.sm ? "12em" : "6em")};
    & > div:first-child {
      font-weight: bold;
      color: white;
      font-size: 1.5em;
    }
    & > div:last-child {
      font-size: 0.9em;
      color: #aaa;
    }
  }
  & > div:last-child {
    display: flex;
    align-items: center;
    flex: 1;
    & > div {
      padding: 1em;
      font-size: 1.2em;
    }
  }
`;
