import styled from "styled-components";
import { device } from "../../../../style/styled-components/helpers/device";
const QualityManagementSub = ({ number, title, text }) => {
  return (
    <Div>
      <div>{number}</div>
      <div className="title">{title}</div>
      <div className="text">{text}</div>
    </Div>
  );
};
export default QualityManagementSub;
const Div = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: 1.5rem;
  @media ${device.tablet} {
  gap: 2.5rem;
  }
  & > div:first-child {
    position: relative;
    background-color: white;
    box-shadow: 1rem 1rem 3rem #c7c7c7, -1rem -1rem 3rem #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    // 동그라미 크기 부분 - 시작
    font-size: 2.5em;
    width: 7rem;
    height: 7rem;
    border-radius: 7rem;
    @media ${device.tablet} {
      font-size: 3em;
      width: 9rem;
      height: 9rem;
      border-radius: 9rem;
    }
    @media ${device.laptop} {
      font-size: 4em;
      width: 12rem;
      height: 12rem;
      border-radius: 12rem;
    }
// 동그라미 크기 부분 - 끝
    font-weight: bold;
    color: ${({ theme }) => theme?.colors?.dark};
  }
  & > div.title {
    color: ${({ theme }) => theme?.colors?.dark};
    font-size: 1.2em;
    @media ${device.tablet} {
    font-size: 1.5em;
    }
    font-weight: bold;
  }
  & > div.text {
    width: 8em;
    font-size: .9em;
    @media ${device.tablet} {
      width: 12em;
    font-size: 1em;
  }
  }
`;
