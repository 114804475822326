import styled from "styled-components";
import { device } from "../../../style/styled-components/helpers/device";
import UseBreakpoints from "../../hooks/ui/UseBreakpoints";
const PentagonScope = ({ contents, length = 4 }) => {
  const breakpoints = UseBreakpoints();
  return (
    <StrengthDiv length={breakpoints.md ? length : length * .6}>
      {contents && (
        <div>
          <div id="first" className="triangle" />
          <div id="second" className="triangle" />
          <div id="third" className="triangle" />
          <div id="fourth" className="triangle" />
          <div id="fifth" className="triangle" />
          <div id="first" className="description">
            <div className="title">{contents[0].title}</div>
            <div className="text">{contents[0].text}</div>
          </div>
          <div id="second" className="description">
            <div className="title">{contents[1].title}</div>
            <div className="text">{contents[1].text}</div>
          </div>
          <div id="third" className="description">
            <div className="title">{contents[2].title}</div>
            <div className="text">{contents[2].text}</div>
          </div>
          <div id="fourth" className="description">
            <div className="title">{contents[3].title}</div>
            <div className="text">{contents[3].text}</div>
          </div>
          <div id="fifth" className="description">
            <div className="title">{contents[4].title}</div>
            <div className="text">{contents[4].text}</div>
          </div>
        </div>
      )}
    </StrengthDiv>
  );
};
export default PentagonScope;
const StrengthDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
align-items: center;
  flex-direction: column;
  min-height: 20rem;
  @media ${device.tablet} {
    min-height: 25rem;
            }
  margin-top: 6rem;
  max-width: 90vw;
  & > div {
    padding: 0;
  max-width: 100%;
    display: flex;
    flex-direction: column;
  justify-content: center;
align-items: center;
    height: ${({ length }) => 4 * length}rem;
    position: relative;
    & > div.triangle {
      position: absolute;
      width: 0em;
  /* border-top: ${({ length }) => 0.86 * length}em solid transparent;       */
  border-right: ${({ length }) => length}rem solid transparent;
  border-left: ${({ length }) => length}rem solid transparent;
      &#first {
        transform: rotate(-144deg);
        bottom: ${({ length }) => 2.7 * length}rem;
        left: ${({ length }) => -0.605 * length}rem;
  border-bottom: ${({ length }) => 1.39 * length}rem solid #779;
      }
      &#second {
        position: absolute;
        transform: rotate(-216deg);
        bottom: ${({ length }) => 2.7 * length}rem;
        right: ${({ length }) => -0.605 * length}rem;
  border-bottom: ${({ length }) => 1.39 * length}rem solid #557;
      }
      &#third {
        position: absolute;
        transform: rotate(-288deg);
        top: ${({ length }) => 0.67 * length}rem;
        right: ${({ length }) => -0.36 * length}rem;
  border-bottom: ${({ length }) => 1.39 * length}rem solid #335;
      }
      &#fourth {
        position: absolute;
        transform: rotate(0deg);
        top: ${({ length }) => 1.14 * length}rem;
        left: ${({ length }) => -1 * length}rem;
  border-bottom: ${({ length }) => 1.39 * length}rem solid #223;
      }
      &#fifth {
        position: absolute;
        transform: rotate(-72deg);
        top: ${({ length }) => 0.67 * length}rem;
        left: ${({ length }) => -0.36 * length}rem;
  border-bottom: ${({ length }) => 1.39 * length}rem solid #557;
      }

    }
    & > div.description {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      
      width: 5rem;
      @media ${device.mobileL} {
              width: 8rem;
            }
      @media ${device.tablet} {
              width: 13rem;
            }
            /* @media ${device.tablet} {
              font-size: 2em;
            } */
      &#first {
        bottom: ${({ length }) => 4.8 * length}rem;
      }
      &#second {
        top: ${({ length }) => -.2 * length}rem;
        right: ${({ length }) => 1.8 * length}rem;
      }
      &#third {
        top: ${({ length }) => -.2 * length}rem;
        left: ${({ length }) => 1.8 * length}rem;
      }
      &#fourth {
        top: ${({ length }) => 2.5 * length}rem;
        right: ${({ length }) => 1 * length}rem;
      }
      &#fifth {
        top: ${({ length }) => 2.5 * length}rem;
        left: ${({ length }) => 1 * length}rem;
      }
      & > div {
        &.title {
            font-weight: bold;
            font-size: 1em;
            @media ${device.mobileL} {
              font-size: 1.2em;
            }
            @media ${device.tablet} {
              font-size: 1.5em;
            }
          }
          &.text {
          font-size: .8em;   
          @media ${device.tablet} {
              font-size: .9em;
            }
          }
      }
    }
  }
`;
