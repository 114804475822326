import styled from "styled-components";
import UseBreakpoints from "../../../hooks/ui/UseBreakpoints";
const TripleBox = ({ contents }) => {
  const breakpoints = UseBreakpoints();
  return (
    <>
      {contents && contents.length === 3 && (
        <Div breakpoints={breakpoints}>
          <div>
            <img src={contents[0].icon} alt={contents[0].alt} />
            <span>{contents[0].title}</span>
            <div>{contents[0].text}</div>
            <div>
              {contents[0].list && (
                <ul>
                  {contents[0].list?.map((x, idx) => {
                    return <li key={idx}>{x}</li>;
                  })}
                </ul>
              )}
            </div>
          </div>
          <div>
            <img src={contents[1].icon} alt={contents[1].alt} />
            <span>{contents[1].title}</span>
            <div>{contents[1].text}</div>
            <div>
              {contents[1].list && (
                <ul>
                  {contents[1].list?.map((x, idx) => {
                    return <li key={idx}>{x}</li>;
                  })}
                </ul>
              )}
            </div>
          </div>
          <div>
            <img src={contents[2].icon} alt={contents[2].alt} />
            <span>{contents[2].title}</span>
            <div>{contents[2].text}</div>
            <div>
              {contents[2].list && (
                <ul>
                  {contents[2].list?.map((x, idx) => {
                    return <li key={idx}>{x}</li>;
                  })}
                </ul>
              )}
            </div>
          </div>
        </Div>
      )}
    </>
  );
};
export default TripleBox;
const Div = styled.div`
  display: flex;
  flex-direction: ${({ breakpoints }) => (breakpoints.md ? "row" : "column")};
  & > div {
    padding: 2em 0 0 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > img {
      width: 100px;
      height: 100px;
    }
    & > span:nth-child(2) {
      font-size: 1.5em;
      font-weight: bold;
      padding: 1em 0;
      color: ${({theme}) => theme.colors?.dark};
      text-align: center;
    }
    & > div:nth-child(3) {
        text-align: center;
        padding: 1em 3em;
    }
    & > div:last-child {
      width: 100%;
      & > ul {
        margin: 0;
        padding: 2em 1em 1em 3em;
        list-style: circle;
      }
      & > div {
        padding: 2em;
      }
    }
    &:not(:last-child) {
      /* border-right: ${({breakpoints}) => breakpoints.md ? "solid 1px gray" : "none" } ; */
    }
  }
`;
