import BranchMap from "../../../../contents/intro/branch/BranchMap";
import BranchSimpleMap from "./BranchSimpleMap";

const BranchMapHOC = ({ selected, detail }) => {
  switch (selected) {
    case 0:
      return <BranchSimpleMap branch={detail} />;
    case 1:
      return <BranchMap branch={detail} />;
    default:
      return <></>;
  }
};
export default BranchMapHOC;
