export const branchcontext = [
  {
    location: "서울",
    name: "서울본사",
    branches: [
      "수도권총괄본부",
      "컨설팅총괄본부",
      "IT운영센터",
      "큐앤엘솔루션",
      "현대관세무역연구원",
    ],
    address1: "서울특별시 강남구",
    addressKey: "언주로 703",
    address2: "LX한국국토정보공사 3층 (06053)",
    tel: "02-2017-3300",
    fax: "02-2017-3397",
    coordinate: {
      lat: 37.5158942,
      lng: 127.0327232,
    },
    pinOnMap: {
      x: 39,
      y: 20,
    },
    direction: {
      taxi: "서울세관사거리, (구)프뢰벨, LX국토정보공사, 토지공사, 건설회관, 임페리얼팰리스호텔, (구)아미가호텔",
      parking:
        "건물 옆 주차타워 이용 가능합니다.(SUV 및 대형 차량은 주차가 어렵습니다.)",
      subway: [
        {
          city: "수도권",
          lineNo: 7,
          station: "학동",
          exit: 10,
          direction: "강남구청역",
          distance: "200m",
        },
        {
          city: "수도권",
          lineNo: "분당선",
          station: "강남구청",
          exit: 3,
          direction: "학동역",
          distance: "500m",
        },
      ],
      bus: {
        buses: {
          major: [141, 242, 401],
          minor: [3414, 6411],
          express: [3600],
          local: ["강남08"],
          airport: [6703],
        },
        stops: [
          {
            stop: "서울세관",
            code: 23130,
            bus: [141],
            direction: "도산공원",
          },
          {
            stop: "서울세관",
            code: 23131,
            bus: [141, 3600],
            direction: "펜트힐루논현.임페리얼팰리스호텔",
          },
          {
            stop: "서울세관사거리",
            code: 23147,
            bus: [6411, "강남08"],
            direction: "학동역",
          },
          {
            stop: "세관앞",
            code: 23129,
            bus: [242, 401, 3414, "강남08"],
            direction: "논현동사거리.학동역",
          },
          {
            stop: "임페리얼팰리스호텔.펜트힐루논현",
            code: 23140,
            bus: [141, 242, 6703],
            direction: "서울세관",
          },
          {
            stop: "펜트힐루논현.임페리얼팰리스호텔",
            code: 23141,
            bus: [141, 242, 6411, 3600],
            direction: "논현아이파크",
          },
        ],
      },
    },
  },
  {
    location: "부산",
    name: "부산지점",
    branches: ["통관사업부문", "검역사업부문"],
    address1: "부산광역시 중구",
    addressKey: "충장대로9번길 46",
    address2: "관정빌딩 표시층11층 (48936)",
    tel: "051-460-7100",
    fax: "051-460-7177",
    coordinate: {
      lat: 35.109317,
      lng: 129.038815,
    },
    pinOnMap: {
      x: 67,
      y: 64,
    },
    direction: {
      taxi: "국가철도공단영남지역본부, 한광미술관, KT중부산지사",
      parking: "관정빌딩 내 무인주차장 이용 가능합니다.(06시~23시)",
      subway: [
        {
          city: "부산",
          lineNo: 1,
          station: "중앙",
          exit: 14,
          direction: "중부소방서",
          distance: "545m",
        },
        {
          city: "부산",
          lineNo: 1,
          station: "부산",
          exit: 2,
          direction: "영주네거리",
          distance: "669m",
        },
      ],
      bus: {
        description:
          "김해공항에서 오실 때는 서부시외버스터미널(사상역)까지 부산김해경전철을 이용하시기 바랍니다.",
        from: {
          terminal: { name: "서부시외버스터미널", bus: [61] },
          train: {
            name: "부산역",
            bus: [17, 26, 27, 40, 41, 61, 81, 82, 87, 134, 508],
          },
        },
        buses: {
          city: [
            17, 26, 27, 40, 41, 61, 66, 81, 82, 85, 87, 88, 101, 103, 134, 190,
            508,
          ],
          express: [1004],
        },
        stops: [
          {
            stop: "영주동",
            code: "01701",
            bus: [17, 26, 27, 40, 41, 61, 81, 82, 87, 103, 134, 190, 508, 1004],
            direction: "중부경찰서삼거리",
          },
          {
            stop: "영주동",
            code: "01702",
            bus: [
              17, 26, 27, 40, 41, 61, 66, 81, 82, 85, 87, 88, 101, 103, 134,
              190, 508, 1004,
            ],
            direction: "부산역",
          },
          {
            stop: "임페리얼팰리스호텔.펜트힐루논현",
            code: "23140",
            bus: [141, 242, 6703],
            direction: "서울세관",
          },
          {
            stop: "펜트힐루논현.임페리얼팰리스호텔",
            code: "23141",
            bus: [141, 242, 6411, 3600],
            direction: "논현아이파크",
          },
        ],
      },
    },
  },
  {
    location: "인천",
    name: "인천공항지점",
    branches: ["인천총괄본부", "특송총괄본부"],
    address1: "인천광역시 중구",
    addressKey: "영종대로 124",
    address2: "한스빌딩 5층(22731)",
    tel: "032-745-0800",
    fax: "032-745-0898~9",
    coordinate: {
      lat: 37.49471,
      lng: 126.4931,
    },
    pinOnMap: {
      x: 65,
      y: 21,
      direction: "right",
    },
    direction: {
      taxi: "한스빌딩, 운서역",
      parking:
        "운서역환승주차장을 이용하시면 편리합니다. (최초 30분 400원, 이후 15분당 200원)",
      subway: [
        {
          city: "수도권",
          lineNo: "공항철도",
          station: "운서",
          exit: 1,
          direction: "환승주차장",
          distance: "200m",
        },
      ],
      bus: {
        buses: {
          express: [304, 307],
          major: [203, 204, 206, 223],
        },
        stops: [
          {
            stop: "운서역",
            code: "35242",
            bus: [203, 204, 206, 223, 304, 307],
            direction: "은골사거리",
          },
          {
            stop: "운서역",
            code: "35257",
            bus: [203, 204, 206, 223],
            direction: "KT인천공항지사",
          },
        ],
      },
    },
  },
  {
    location: "평택",
    name: "평택지점",
    branches: [],
    address1: "경기도 평택시 포승읍",
    addressKey: "연암길 37-3",
    address2: "(17962)",
    tel: "031-8054-7550",
    fax: "070-7500-0047~9",
    coordinate: {
      lat: 36.96225,
      lng: 126.85236,
    },
    pinOnMap: {
      x: 38,
      y: 30,
    },
    direction: {
      parking: "건물 주차장 이용 가능합니다.",
      taxi: "만호교회, 만도아파트",
      bus: {
        buses: {
          city: [80, "81-1"],
        },
        stops: [
          {
            stop: "한라아파트",
            code: "15030",
            bus: ["81-1"],
            direction: "만호5리입구",
          },
          {
            stop: "한라아파트",
            code: "15031",
            bus: [80, "81-1"],
            direction: "만호5리",
          },
        ],
        from: {
          train: {
            name: "평택역",
            bus: [80],
          },
        },
      },
    },
  },

  {
    location: "울산",
    name: "울산지점",
    branches: [],
    address1: "울산광역시 남구",
    addressKey: "수암로 185-1",
    address2: "2층",
    tel: "052-272-3206",
    fax: "",
    coordinate: {
      lat: 35.52703,
      lng: 129.32736,
    },
    pinOnMap: {
      x: 69,
      y: 49,
      direction: "top",
    },
    direction: {
      taxi: "대현동행정복지센터",
      parking: "울산세관 주차장 이용 가능합니다.",
      bus: {
        buses: {
          local: [923, 926, 929],
          city: [126, 216, 256],
        },
        stops: [
          {
            stop: "대현동주민센터",
            code: "31209",
            bus: [126, 216, 256, 926],
            direction: "야음시장앞",
          },
          {
            stop: "대현초등학교앞",
            code: "31210",
            bus: [126, 216, 256, 926],
            direction: "동부아파트입구",
          },
          {
            stop: "울산세관입구",
            code: "74903",
            bus: [923, 926, 929],
            direction: "신정현대홈타운106동",
          },
          {
            stop: "울산세관입구",
            code: "74904",
            bus: [923],
            direction: "홈플러스",
          },
        ],
      },
    },
  },
  {
    location: "GLOBAL",
  },
];
