import styled from "styled-components";
const EthicalManagementSub = ({ title, contents }) => {
  return (
    <Div>
      <div>{title}</div>
      <div>
        <ul>
          {contents?.map((x, idx) => {
            return <li key={idx}>{x}</li>;
          })}
        </ul>

      </div>

    </Div>
  );
};
export default EthicalManagementSub;
const Div = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  & > div:first-child {
    font-size: 1.8em;
    font-weight: bold;
    padding: 1rem;
    z-index: 1;
  }
  & > div:nth-child(2) {
    padding: .5rem;
  box-shadow: 1rem 1rem 3rem #c7c7c7, -1rem -1rem 3rem #ffffff;
    
    border-radius: 1rem;
    & > ul {
      display: flex;
flex-direction: column;
      list-style: none;
      gap: .2em;
      & > li {
        position: relative;
        color: ${({ theme }) => theme?.colors?.foreground?.dimmed};
        &:before {
          content: "";
          position: absolute;
          top: 1.4em;
          left: -2em;
          margin-top: -0.9em;
          background: ${({ theme }) => `linear-gradient(45deg, ${theme?.colors?.monotone?.dark} , ${theme?.colors?.monotone?.medium})`};
          height: .5em;
          width: .5em;
          border-radius: 50%;
        }
      }
    }

  }
`;
