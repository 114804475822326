import styled from "styled-components";
import PageTemplate from "../../components/ui/common/template/PageTemplate";
import OrgImage from "../../../src/resources/images/organization.png";
import CenterDiv from "../../style/styled-components/components/CenterDiv";
import UseBreakpoints from "../../components/hooks/ui/UseBreakpoints";
import AoneOrganization from "../../components/contents/members/AoneOrganization";
const OrganizationPage = () => {
  const breakpoints = UseBreakpoints();
  return (
    <PageTemplate title="조직도" noImage large >
      <CenterDiv>
        <AoneOrganization />
      </CenterDiv>
    </PageTemplate>
  );
};
export default OrganizationPage;
const OrganizationImage = styled.img`
  width: ${({ breakpoints }) => (breakpoints.lg ? "80%" : "95%")};
`;
const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
`;
