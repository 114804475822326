import { Link } from "react-router-dom";
import styled from "styled-components";
const ArticleListElement = ({
  category = "",
  no = "",
  title = "",
  date = "",
}) => {
  return (
    <Link to={`/boards/${category}/${no}`}>
      <Div>
        <div className="title">{title}</div>
        <div className="date"> {date}</div>
      </Div>
    </Link>
  );
};
export default ArticleListElement;
const Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1.5rem;
  border: 2px solid ${({ theme }) => theme.colors.background.default};
  padding: .5rem;
  & > div {
    &.title {
      flex: 1;
      font-size: 1.2em;
    }
    &.date {
    }
  }
  &:hover {
  border: 2px solid ${({ theme }) => theme.colors.background.medium};
    transition: all 0.5s;
    color: ${({ theme }) => theme.colors.foreground.highlight};
  }
`;
