// mobile(portrait) -  360~428
// mobile(landscape) - 667~926
// tablet(portrait) - 600~800
// tablet(landscape) - 960~1280
// laptop - 1200~1600
// desktop - 1280~1920
// highres - 1920~7680
export const size = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};
