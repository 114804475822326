import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import HistoryImage from "../../../resources/images/background/intro/history1.png";
import { chapter1 } from "../../../context/intro/history/chapter1";
import { chapter2 } from "../../../context/intro/history/chapter2";
import { chapter3 } from "../../../context/intro/history/chapter3";
import HistoryChapter from "../../../components/ui/intro/history/HistoryChapter";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import UseScroll from "../../../components/hooks/ui/UseScroll";
import { Link } from "react-scroll";
import UseBreakpoints from "../../../components/hooks/ui/UseBreakpoints";
import { device } from "../../../style/styled-components/helpers/device";
const HistoryPage = () => {
  const breakpoints = UseBreakpoints();
  const scroll = UseScroll();
  const posRefs = [useRef(), useRef(), useRef()];
  // X
  const [sectionPos, setSectionPos] = useState([]);
  const [inSection, setInSection] = useState([]);

  // Get the position of the red box in the beginning
  useEffect(() => {
    const getPosition = () => {
      setSectionPos(
        posRefs?.map((ref) => {
          return {
            x: ref.current?.offsetLeft ? ref.current?.offsetLeft : 0,
            y: ref.current?.offsetTop ? ref.current?.offsetTop : 0,
          };
        })
      );
    };
    getPosition();
    window.addEventListener("resize", getPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Re-calculate X and Y of the red box when the window is resized by the user
  useEffect(() => {
    setInSection([
      scroll?.y >= sectionPos[0]?.y - 388 && scroll?.y < sectionPos[1]?.y - 388,
      scroll?.y >= sectionPos[1]?.y - 388 && scroll?.y < sectionPos[2]?.y - 388,
      scroll?.y >= sectionPos[2]?.y - 388,
    ]);
  }, [sectionPos, scroll]);
  return (
    <PageTemplate
      title="연혁"
      image={HistoryImage}
      phrase={"관세법인의 새로운 역사를 쓰다."}
      large aone
    >
      <PageDiv>
        <SelectDiv breakpoints={breakpoints} inSection={inSection}>
          <Link smooth offset={-250} to="section-1">
            {chapter1.title}
          </Link>
          <Link smooth offset={-250} to="section-2">
            {chapter2.title}
          </Link>
          <Link smooth offset={-250} to="section-3">
            {chapter3.title}
          </Link>
        </SelectDiv>
        <HistoryDiv>
          <section id="section-1" name="section-1" ref={posRefs[0]}>
            <HistoryChapter context={chapter1} />
          </section>
          <section id="section-2" name="section-2" ref={posRefs[1]}>
            <HistoryChapter context={chapter2} />
          </section>
          <section id="section-3" name="section-3" ref={posRefs[2]}>
            <HistoryChapter context={chapter3} />
          </section>
        </HistoryDiv>
      </PageDiv>
    </PageTemplate>
  );
};
export default HistoryPage;
const PageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`;
const HistoryDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5em;
`;
const SelectDiv = styled.div`
  position: sticky;
  z-index: 2;
  top: 6rem;
  max-width: 100vw;
  display: flex;
  flex-direction: ${({ breakpoints }) => (breakpoints?.lg ? "row" : "row")};
  background-color: ${({ theme }) => theme?.colors?.pages?.history?.navigationBackground};
  padding: 1rem 0;
  @media ${device.mobileL} {
    padding: 1rem 1rem;
  }
  @media ${device.tablet} {
    padding: 1rem 3rem;
  }
  justify-content: space-evenly;
  gap: ${({ breakpoints }) =>
    breakpoints.xl ? "2em" : breakpoints?.md ? "1em" : ".5em"};
  & a {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    background-color: ${({ theme }) => theme?.colors?.background?.dark};
    color: white;
    padding: ${({ breakpoints }) => (breakpoints?.md ? ".6em 1em" : ".3em .5em")};
    width: ${({ breakpoints }) => (breakpoints?.sm ? "7em" : "6em")};
    font-size: ${({ breakpoints }) =>
    breakpoints.xl ? "1.6em" : breakpoints?.md ? "1.4em" : "1.2em"};
    font-weight: bolder;
    &:first-child {
      background-color: ${({ theme, inSection }) =>
    inSection[0]
      ? theme?.colors?.background?.dark
      : theme?.colors?.background?.light};
    }
    &:nth-child(2) {
      background-color: ${({ theme, inSection }) =>
    inSection[1]
      ? theme?.colors?.background?.dark
      : theme?.colors?.background?.light};
    }
    &:nth-child(3) {
      background-color: ${({ theme, inSection }) =>
    inSection[2]
      ? theme?.colors?.background?.dark
      : theme?.colors?.background?.light};
    }
  }
`;
