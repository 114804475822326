import styled from "styled-components";
import UseBreakpoints from "../../../hooks/ui/UseBreakpoints";
import branchImage2 from "../../../../resources/images/background/intro/branch2.png";
const GlobalBranches = () => {
  const breakpoints = UseBreakpoints();
  return (
    <Div breakpoints={breakpoints} >
      <div className="title">GLOBAL NETWORK</div>
      <hr />
      <ul className="branchlist">
        <li>
          <div className="branch">
            <div className="name">미국(뉴욕, LA)</div>
            <div>
              B&amp;H관세법인 , J.J,KIM &amp; Associates와 협업 MOU 체결
            </div>
          </div>
        </li>
        {/* <li>
          <div className="branch">
            <div className="name">이탈리아</div>
            <div>jus&amp;Trade, MOU 체결</div>
          </div>
        </li> */}
        <li>
          <div className="branch">
            <div className="name">중국</div>
            <div>신하이 관세법인 MOU 체결</div>
          </div>
        </li>
        {/* <li>
            <div className="branch">
              <div className="name">일본</div>
              <div>일본 MOU</div>
            </div>
          </li> */}
        {/* <li>
          <div className="branch">
            <div className="name">싱가포르</div>
            <div>운영 무역관련 지식재산권보호협회(TIPA)와 MOU체결</div>
          </div>
        </li>
        <li>
          <div className="branch">
            <div className="name">베트남</div>
            <div>PTV 와 MOU 체결, AEO협회와 AEO지원 MOU 체결</div>
          </div>
        </li>
        <li>
          <div className="branch">
            <div className="name">인도네시아</div>
            <div>SPL Logistics와 MOU체결</div>
          </div>
        </li> */}
      </ul>
      <GlobalMapDiv>
        <MapImage src={branchImage2} breakpoints={breakpoints} />
      </GlobalMapDiv>
    </Div>
  );
};
export default GlobalBranches;
const Div = styled.div`

padding: ${({ breakpoints }) => breakpoints.md ? ".75rem" : "3rem"} 0;
  & > div.title {
    font-size: 2em;
    font-weight: bold;
    padding: 0 1.5rem;
  }
  & > ul.branchlist {
    padding: 0;
  font-size: clamp(1em, 1.7vw, 1.3em);
  list-style: none;
  line-height: 2em;
  & > li {
  }
  & div.branch {
    display: flex;
  & > div.name {
    display: flex;
  width: ${({ breakpoints }) => breakpoints.md ? "8rem" : "5.5rem"};
  color: ${({ theme }) => theme?.colors?.foreground?.dimmed};
  /* background-color: ${({ theme }) => theme?.colors?.monotone?.light}; */
  padding: 0 0 0 1em;
  }
  & > div:nth-child(2) {
    flex: 1;
    padding: 0 1em 0;
  }
  }
  }
`;
const GlobalMapDiv = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
const MapImage = styled.img`
  width: ${({ breakpoints }) =>
    breakpoints.xl
      ? "40%"
      : breakpoints.lg
        ? "50%"
        : breakpoints.md
          ? "60%"
          : breakpoints.sm
            ? "70%"
            : breakpoints.xs
              ? "80%"
              : "90%"};
`;
