import styled from 'styled-components';
import HomeShortcut from './HomeShortcut';
import impexpSVG from '../../resources/images/home/shortcuts/noun-custom-clearance-4649289-FFFFFF.svg';
import drawbackSVG from '../../resources/images/home/shortcuts/noun-monetary-turn-214819-FFFFFF.svg';
import requirementSVG from '../../resources/images/home/shortcuts/noun-requirement-2209179-FFFFFF.svg';
import quarantineSVG from '../../resources/images/home/shortcuts/noun-quarantine-3985410-FFFFFF.svg';
import ftaSVG from '../../resources/images/home/shortcuts/noun-trade-1290817-FFFFFF.svg';
import aeoSVG from '../../resources/images/home/shortcuts/noun-authorize-1949799-FFFFFF.svg';
import customslegalSVG from '../../resources/images/home/shortcuts/noun-legal-2492909-FFFFFF.svg';
import { languageStore } from '../../state/language';
const HomeShortcuts = () => {
  const {language} = languageStore();

    return (<Div>
        <HomeShortcut title={language === "EN" ? "DECLARATION" : "수출입통관"} url="/impexp" icon={impexpSVG} />
        <HomeShortcut title={language === "EN" ? "DRAWBACK" : "환급"} url="/drawback" icon={drawbackSVG} />
        <HomeShortcut title="요건" url="/requirement" icon={requirementSVG}/>
        <HomeShortcut title="검역" url="/quarantine" icon={quarantineSVG} />
        <HomeShortcut title="FTA" url="/consulting/fta" icon={ftaSVG} />
        <HomeShortcut title="AEO" url="/consulting/aeo" icon={aeoSVG} />
        <HomeShortcut title="관세심사" url="/legal/customs" icon={customslegalSVG} />
        </Div>)
}
export default HomeShortcuts;
const Div = styled.div`
display: flex;
width: 100%;
`;