import styled from "styled-components";
export default styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  & > img {
    top: 1rem;
    max-width: 85vw;
    /* transform: translateX(-2rem); */
  }
`;
