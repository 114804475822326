import CenterDiv from "../../../style/styled-components/components/CenterDiv";
import CompareTable from "../../../components/ui/specialty/CompareTable";
import ContactPoints from "../../../components/ui/members/ContactPoints";
import DiamondScope from "../../../components/ui/specialty/DiamondScope";
import DrawbackImage from "../../../../src/resources/images/drawback.png";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import UseBreakpoints from "../../../components/hooks/ui/UseBreakpoints";
import WorkScopes from "../../../components/ui/specialty/WorkScopes";
import { device } from "../../../style/styled-components/helpers/device";
import img1 from "../../../resources/images/icon/3. 환급/1. 관세환급 진단 컨설팅, 소요량 사전심사 컨설팅.png";
import img3 from "../../../resources/images/icon/3. 환급/3. 개별환급 신청.png";
import img4 from "../../../resources/images/icon/3. 환급/4. 간이정액환급 신청.png";
import img5 from "../../../resources/images/icon/3. 환급/5. 기초원재료납세증명서 신청, 분할증명서 신청.png";
import img6 from "../../../resources/images/icon/3. 환급/6. 소요량 사전심사 대리.png";
import img7 from "../../../resources/images/icon/3. 환급/7. 품목별 소요량 관리 및 검토.png";
import img8 from "../../../resources/images/icon/3. 환급/8. 환급심사 대응.png";
import styled from "styled-components";

const DrawbackPage = () => {
  const breakpoints = UseBreakpoints();
  return (
    <PageTemplate
      large
      image={DrawbackImage}
      title="환급"
      phrase="수출입통관부터 관세환급까지 One-Stop 서비스를 제공합니다."
      description="다년간 쌓은 수출입 통관 지식과 경력을 바탕으로 관세환급에 접근하여
      업무효율성을 증대시키고 차별화된 서비스를 제공합니다. 환급대상 원재료와
      수출을 파악하여 개별환급과 간이환급 중 최적화된 환급을 진단하여 RISK를
      최소화하고 잠재된 환급액을 찾아 기업 이윤을 극대화 합니다."
    >
      <TitleTemplate
        title="관세 환급"
        subHeader="요건에 맞는 정확한 환급 방법을 통하여 기업의 기윤을 극대화 합니다."
        noImage
      />
      <CenterDiv>
        <CompareTable
          contents={[
            { div: "구분", content1: "개별환급", content2: "간이 정액 환급" },
            {
              div: "대상자",
              content1: "모든 수출입 기업",
              content2: "연간환급금이 6억원 이하인 중소기업",
            },
            {
              div: "대상품목",
              content1: "모든 수출품목",
              content2: "간이정액율표에 게기된 수출품목",
            },
            {
              div: "제출서류",
              content1: "수출사실 확인서류, 납부세액 증명서류, 소요량 계산서",
              content2: "수출사실 확인서류",
            },
            {
              div: "환급금액",
              content1: "원재료 수입시 납부세액 산출하여 환급금 지급",
              content2: "수출금액 1만원당 품목별 일정금액을 환급금으로 지급",
            },
          ]}
        />
      </CenterDiv>
      <TitleTemplate
        title="환급 관리"
        subHeader="환급전문가가 함께하는 AONE만의 특별한 관리시스템을 운영하고 있습니다."
        noImage
      />
      <CenterDiv>
        <DrawbackManageDiv
          breakpoints={breakpoints}
          hexagonWidth={breakpoints.md ? 9 : 7}
          multiple={1.12}
        >
          <div>
            <div>
              <div>
                <div />
                <div />
                <div />
              </div>
              <div>
                <div />
                <div />
                <div />
              </div>
              <div>환급 data 관리</div>
            </div>
            <div>
              <ul>
                <li>환급 신청 및 기납증 발행 data 관리</li>
                <li>수출물품 1단위 환급액 변동성 점검</li>
                <li>
                  기존 환급 이력 DB를 바탕으로 수출물품 별 원재료로 구성된
                  Master File 작성을 통한 DB 기반 검증
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div>
            <div>
              <div>
                <div />
                <div />
                <div />
              </div>
              <div>
                <div />
                <div />
                <div />
              </div>
              <div>Self-Audit</div>
            </div>
            <div>
              <ul>
                <li>제품별 원재료 소요량 일관성 점검</li>
                <li>조정고시대상 원재료에 대한 환급 이력 관리</li>
                <li>부산물 공제비율 변동성 점검</li>
              </ul>
            </div>
          </div>
          <hr />
          <div>
            <div>
              <div>
                <div />
                <div />
                <div />
              </div>
              <div>
                <div />
                <div />
                <div />
              </div>
              <div>컨설팅 연계</div>
            </div>
            <div>
              <ul>
                <li>향후 심사 수검대비 리스크 관리</li>
                <li>AM정보제공 분야 중 "환급" 관련 대응</li>
                <li>필요시 소요량 사전심사 신청 등 유권해석</li>
              </ul>
            </div>
          </div>
        </DrawbackManageDiv>
      </CenterDiv>

      <TitleTemplate
        title="업무 범위"
        subHeader="환급 신청 부터 컨설팅 및 관리까지 환급 전문가가 고객사의 환급업무를 책임집니다."
        noImage
      />
      <WorkScopes
        contents={[
          { title: "관세환급 진단 컨설팅", icon: img1 },
          { title: "개별환급 신청", icon: img3 },
          { title: "간이정액환급 신청", icon: img4 },
          { title: "기초원재료 납세증명서 신청", icon: img5 },
          { title: "분할증명서 신청", icon: img5 },
          { title: "소요량 사전심사 컨설팅", icon: img1 },
          { title: "소요량 사전심사 대리", icon: img6 },
          { title: "품목별 소요량 관리 및 검토", icon: img7 },
          { title: "환급심사 대응", icon: img8 },
        ]}
      />
      <TitleTemplate
        title="Strength"
        subHeader="A-One 만의 장점으로 고객을 힘껏 서포트 해 나가겠습니다."
        noImage
      />
      <DiamondScope
        contents={[
          {
            title: "환급 전담조직",
            text: "환급 전문관세사 및 전문 컨설턴트로 구성된 환급 전담조직 보유",
          },
          {
            title: "풍부한 업무 경험",
            text: "다양한 산업 기업의 환급 컨설팅 및 환급대행업무 실적보유",
          },
          {
            title: "환급관련 법률 자문 제공",
            text: "환급관련 법률 자문 및 개정사항 상시 제공",
          },
          {
            title: "Consulting",
            text: "고객사 특성별 환급방법 컨설팅 및 세관 이슈 발생시 적극 대응",
          },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["yeonghwan", "meenss"]} />
    </PageTemplate>
  );
};
export default DrawbackPage;
const DrawbackManageDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  & ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 1.5rem;
    min-height: ${({ hexagonWidth, multiple }) => hexagonWidth * multiple}rem;
  }
  & > hr {
    display: none;
    margin: 0;
  }
  & > div {
    display: flex;
    &:nth-child(4n-3) > div:first-child {
      left: ${({ breakpoints, hexagonWidth }) =>
        breakpoints.md ? hexagonWidth * 0.3 : 0}em;
    }
    &:nth-child(4n-1) > div:first-child {
      right: ${({ breakpoints, hexagonWidth }) =>
        breakpoints.md ? hexagonWidth * 0.3 : 0}em;
    }
    & > div:first-child {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: ${({ breakpoints, hexagonWidth }) =>
        breakpoints.md ? hexagonWidth * 2 : hexagonWidth * 1.3}em;

      & > div {
        &:first-child {
          position: absolute;
          display: flex;
          flex-direction: column;
          & > div {
            position: relative;
            &:first-child {
              border-bottom: solid
                ${({ hexagonWidth, multiple }) =>
                  0.288 * hexagonWidth * multiple}em
                ${({ theme }) => theme.colors?.light};
              border-left: solid
                ${({ hexagonWidth, multiple }) =>
                  0.5 * hexagonWidth * multiple}em
                transparent;
              border-right: solid
                ${({ hexagonWidth, multiple }) =>
                  0.5 * hexagonWidth * multiple}em
                transparent;
            }
            &:nth-child(2) {
              height: ${({ hexagonWidth, multiple }) =>
                0.576 * hexagonWidth * multiple}em;
              width: ${({ hexagonWidth, multiple }) =>
                hexagonWidth * multiple}em;
              background-color: ${({ theme }) => theme.colors?.light};
            }
            &:nth-child(3) {
              border-top: solid
                ${({ hexagonWidth, multiple }) =>
                  0.288 * hexagonWidth * multiple}em
                ${({ theme }) => theme.colors?.light};
              border-left: solid
                ${({ hexagonWidth, multiple }) =>
                  0.5 * hexagonWidth * multiple}em
                transparent;
              border-right: solid
                ${({ hexagonWidth, multiple }) =>
                  0.5 * hexagonWidth * multiple}em
                transparent;
            }
          }
        }
        &:nth-child(2) {
          position: absolute;
          display: flex;
          flex-direction: column;
          & > div {
            position: relative;
            &:first-child {
              border-bottom: solid
                ${({ hexagonWidth }) => 0.288 * hexagonWidth}em
                ${({ theme }) => theme.colors?.dark};
              border-left: solid ${({ hexagonWidth }) => 0.5 * hexagonWidth}em
                transparent;
              border-right: solid ${({ hexagonWidth }) => 0.5 * hexagonWidth}em
                transparent;
            }
            &:nth-child(2) {
              height: ${({ hexagonWidth }) => 0.576 * hexagonWidth}em;
              width: ${({ hexagonWidth }) => hexagonWidth}em;
              background-color: ${({ theme }) => theme.colors?.dark};
            }
            &:nth-child(3) {
              border-top: solid ${({ hexagonWidth }) => 0.288 * hexagonWidth}em
                ${({ theme }) => theme.colors?.dark};
              border-left: solid ${({ hexagonWidth }) => 0.5 * hexagonWidth}em
                transparent;
              border-right: solid ${({ hexagonWidth }) => 0.5 * hexagonWidth}em
                transparent;
            }
          }
        }
        &:nth-child(3) {
          color: white;
          font-weight: bold;
          font-size: ${({ breakpoints }) =>
            breakpoints.md ? "1.3em" : "1.1em"};
          z-index: 1;
        }
      }
    }
    & > div:nth-child(2) {
      display: flex;
      align-items: center;
      flex: 1;
      min-height: ${({ hexagonWidth }) => hexagonWidth * 1}em;
      font-size: 0.9em;
      @media ${device.tablet} {
        font-size: 1.1em;
      }
      line-height: 1.8em;
    }
  }
`;
