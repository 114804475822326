import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: ${({ breakpoints }) => (breakpoints?.lg ? "row" : "column")};
  & > *:first-child {
    flex: 1;
    padding: 1rem;
    & > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & > img {
        width: 80%;
        border-radius: 1rem;
      }
      & > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        & > img {
          flex: 1;
          max-width: 23%;
          object-fit: contain;
          border-radius: 1rem;
        }
      }
    }

    & > div:nth-child(2) {
      font-size: 1.2em;
      font-weight: bold;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  & > *:nth-child(2) {
    flex: 2;
  }

  & div.countries {
    & > img {
      height: 100%;
    }
  }
`;
