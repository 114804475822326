import styled from "styled-components";
import Logo from "../../../../src/resources/images/logo/aone_logo_only-removebg.png";
import { device } from "../../../style/styled-components/helpers/device";
import UseBreakpoints from "../../hooks/ui/UseBreakpoints";
const NameCard = ({
  member: {
    id = "",
    name = "",
    division = "",
    grade = "",
    tel = "",
    email = "",
    image = null,
    history = [],
  },
  unmaskContact = false,
}) => {
  const breakpoints = UseBreakpoints();
  return (
    <Div breakpoints={breakpoints} unmaskContact={unmaskContact}>
      <div className="text">
        <div>
          <div className="title">
            <div className="name">{name}</div>
            <div />
            <div>
              <OrgDiv>{division}</OrgDiv>
              <PositionDiv>{grade}</PositionDiv>
            </div>
          </div>
        </div>
        {breakpoints.sm && (
          <div className="contact">
            <div className="background" />
            {unmaskContact && (
              <div>
                <div>
                  <span>{`☎ ${tel}`}</span>
                </div>
                <div>
                  <span>
                    {"✉ "}
                    {email ? email : `${id}@aonecustoms.com`}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="history">
          <ul>
            {history?.map((x, idx) => {
              return <li key={idx}>{x}</li>;
            })}
          </ul>
        </div>
      </div>
      {breakpoints?.sm && (
        <div>
          <Image src={image} />
        </div>
      )}
    </Div>
  );
};
export default NameCard;
const Div = styled.div`
  position: relative;
  display: flex;
  border-radius: 1rem;
  padding: 1em;
  height: ${({ breakpoints }) => (breakpoints.sm ? "15rem" : "10rem")};
  width: ${({ breakpoints }) => (breakpoints.sm ? "27rem" : "80vw")};
  box-shadow: 1rem 1rem 3rem #c7c7c7, -1rem -1rem 3rem #ffffff;
  place-self: center;
  &:not(:hover) {
    background-color: ${({ theme }) => theme?.colors?.background?.default};
    transition: 0.3s background-color;
    & > div:first-child {
      & > div:first-child {
        & > div:first-child {
          & > div:nth-child(2) {
          }
        }
      }
      & > div:nth-child(2) {
      }
      & > div:nth-child(3) {
        // 논-호버 시 약력 숨김
        display: none;
      }
    }
    & > div:nth-child(2) {
      // 호버 시 사진 숨김
      position: relative;
      width: 6rem;
      transition: opacity 0.7s 0.2s;
    }
  }
  @media ${device.sm} {
    &:hover {
      transition: background-color 0.5s;
      background-color: ${({ theme }) => theme?.colors?.background?.medium};
      & > div:first-child {
        & > div:first-child {
          & > div:first-child {
            color: ${({ theme }) => theme?.colors?.foreground?.inverted};
            & > div:nth-child(2) {
              background-color: ${({ theme }) =>
    theme?.colors?.foreground?.inverted};
            }
          }
        }
        & > div:nth-child(2) {
          display: none;
        }
        & > div:nth-child(3) {
          flex: 1;
          background-image: none;
        }
      }
      & > div:nth-child(2) {
        opacity: 0;
        position: relative;
        width: 6rem;
      }
    }
  }
  & > div.text {
    position: relative;
    display: ${({ breakpoints }) => (breakpoints?.sm ? "flex" : "none")};
    width: 11em;
    display: flex;
    flex-direction: column;
    flex: 1;
    & > div:first-child {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-start;
      align-items: flex-start;
      & > div.title {
        position: relative;
        flex: ${({ breakpoints }) => (breakpoints?.sm ? "1" : "null")};
        display: flex;
        align-items: center;
        padding: ${({ breakpoints }) =>
    breakpoints?.sm ? "0 1rem" : "1rem .5rem"};
        & > div.name {
          position: relative;
          font-size: ${({ breakpoints }) =>
    breakpoints.sm ? "1.8em" : "1.5em"};
          font-weight: bold;
          & + div {
            width: 0.4em;
            height: 2em;
            background-color: black;
            margin: 0 0.6em;
          }
        }
        & > div:last-child {
          position: relative;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          height: 2em;
          font-size: 1.2em;
          gap: 0.3em;
        }
      }
    }
    & > div.contact {
      z-index: 1;
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 1.1em;
mask-image: linear-gradient(to right, black 40%,  transparent);
      mask-mode:alpha;
      background-image: radial-gradient(#ace7 13%, transparent 20%),
        radial-gradient(#ace7 13%, transparent 20%);
      background-color: transparent;
      background-position: 0 0, 3px 3px;
      background-size: 6px 6px;
      width: 100%;
      height: 100%;
      & > div.background {
        position: relative;
        background-image: url(${Logo});
        background-repeat: no-repeat;
        background-position: ${({ unmaskContact }) =>
    unmaskContact ? "right" : "left"};
        background-size: contain;
        opacity: 0.05;
        height: 100%;
      }
      & > div:nth-child(2) {
        position: absolute;
        bottom: 1em;
        left: 1em;
      }
    }
    & > div.history {
      flex: 1;
      overflow-y: none;
font-weight: 200;
      color: ${({ theme, breakpoints }) =>
    breakpoints.sm
      ? theme?.colors?.foreground?.inverted
      : theme?.colors?.foreground?.normal};
      font-size: 0.8em;
      & > ul {
        /* padding: 0; */
        margin: 0;
      }
    }
  }
`;

const OrgDiv = styled.div`
  position: relative;
  font-size: 0.8em;
`;
const PositionDiv = styled.div`
  position: relative;
  font-size: 0.8em;
`;
const Image = styled.img`
  position: absolute;
  height: 15rem;
  right: -0.5rem;
  bottom: 0rem;
`;
