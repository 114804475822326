import { Link } from "react-router-dom";
import styled from "styled-components";
import UseBreakpoints from "../../../../../components/hooks/ui/UseBreakpoints";
import COIssueType from "../../../../ui/specialty/consulting/fta/COIssueType";
const FTAPrefTable = () => {
  const breakpoints = UseBreakpoints();
  return (
    <>
      ※ 국가명을 클릭하면 협정의 상세 내용을 확인하실 수 있습니다.
      <Table breakpoints={breakpoints}>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>협정</th>
            <th>발급방식</th>
            <th>발급주체</th>
            <th>증명서양식</th>
            <th>CO유효기간</th>
            <th>언어</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Link to="/consulting/fta/chile">
                <div>칠레</div>
              </Link>
            </td>
            <td>
              <COIssueType type="자율" themeColor="safe" inverted />
            </td>
            <td>수출자</td>
            <td>동일증명서식</td>
            <td>2년</td>
            <td>영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/singapore">
                <div>싱가포르</div>
              </Link>
            </td>
            <td>
              <COIssueType type="기관" themeColor="caution" inverted />
            </td>
            <td>
              싱가포르: 세관
              <br />
              한국: 세관, 상공회의소
            </td>
            <td>양국간 각자 증명서식</td>
            <td>1년</td>
            <td>영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/efta">
                <div>EFTA</div>
              </Link>
            </td>
            <td>
              <COIssueType type="자율" themeColor="safe" inverted />
            </td>
            <td>수출자(송품장)</td>
            <td>송품장 신고방식</td>
            <td>1년</td>
            <td>영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/asean">
                <div>ASEAN</div>
              </Link>
            </td>
            <td>
              <COIssueType type="기관" themeColor="caution" inverted />
            </td>
            <td>
              아세안: 지정기관
              <br />
              한국: 세관, 상공회의소
            </td>
            <td>통일증명서식(AK서식)</td>
            <td>6개월</td>
            <td>영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/cepa">
                <div>인도</div>
              </Link>
            </td>
            <td>
              <COIssueType type="기관" themeColor="caution" inverted />
            </td>
            <td>
              인도: 인도수출검사위원회
              <br />
              한국: 세관, 상공회의소
            </td>
            <td>통일증명서식(KIN서식)</td>
            <td>1년</td>
            <td>영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/eu">
                <div>EU</div>
              </Link>
            </td>
            <td>
              <COIssueType type="자율" themeColor="safe" inverted />
            </td>
            <td>인증수출자(6천유로 이하 물품은 인증 없이도 자율 발급 가능)</td>
            <td>송품장 신고방식</td>
            <td>1년</td>
            <td>영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/peru">
                <div>페루</div>
              </Link>
            </td>
            <td className="inline">
              <div>
                <COIssueType type="자율" themeColor="safe" inverted />
              </div>
            </td>
            <td>수출자 및 생산자(인증수출자, 2,000$이하)</td>
            <td>동일 증명서식</td>
            <td>1년</td>
            <td>영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/us">
                <div>미국</div>
              </Link>
            </td>
            <td>
              <COIssueType type="자율" themeColor="safe" inverted />
            </td>
            <td>수출자, 생산자, 수입자</td>
            <td>양국간 각자 증명서식</td>
            <td>4년</td>
            <td>영어, 한글</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/turkiye">
                <div>튀르키예</div>
              </Link>
            </td>
            <td>
              <COIssueType type="자율" themeColor="safe" inverted />
            </td>
            <td>수출자</td>
            <td>송품장</td>
            <td>1년</td>
            <td>영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/australia">
                <div>호주</div>
              </Link>
            </td>
            <td>
              <div>
                <COIssueType type="자율" themeColor="safe" inverted />
                <COIssueType
                  type="기관"
                  themeColor="caution"
                  inverted
                  annotation="*"
                />
              </div>
            </td>
            <td>
              자율(수출자/생산자)
              <br />
              호주(상공회의소, 산업협회)
            </td>
            <td>자율(권고서식)</td>
            <td>2년</td>
            <td>영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/canada">
                <div>캐나다</div>
              </Link>
            </td>
            <td>
              <COIssueType type="자율" themeColor="safe" inverted />
            </td>
            <td>수출자/생산자</td>
            <td>통일서식</td>
            <td>2년</td>
            <td>
              캐나다(영어 또는 불어)
              <br />
              한국(한글 또는 영어)
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/china">
                <div>중국</div>
              </Link>
            </td>
            <td>
              <COIssueType type="기관" themeColor="caution" inverted />
            </td>
            <td>
              중국(중국해관총서/국제무역촉진위원회)
              <br />
              한국(세관/상공회의소)
            </td>
            <td>통일서식</td>
            <td>1년</td>
            <td>영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/vietnam">
                <div>베트남</div>
              </Link>
            </td>
            <td>
              <div>
                <COIssueType type="기관" themeColor="caution" inverted />
              </div>
            </td>
            <td>
              베트남(산업무역부)
              <br />
              한국(세관, 상공회의소)
            </td>
            <td>통일서식</td>
            <td>1년</td>
            <td>영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/newzealand">
                <div>뉴질랜드</div>
              </Link>
            </td>
            <td>
              <COIssueType type="자율" themeColor="safe" inverted />
            </td>
            <td>수출자/생산자</td>
            <td>송품장/권고서식</td>
            <td>2년</td>
            <td>영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/colombia">
                <div>콜롬비아</div>
              </Link>
            </td>
            <td>
              <COIssueType type="자율" themeColor="safe" inverted />
            </td>
            <td>수출자/생산자</td>
            <td>통일서식</td>
            <td>1년</td>
            <td>영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/middleamerica">
                <div>중미</div>
              </Link>
            </td>
            <td>
              <COIssueType type="자율" themeColor="safe" inverted />
            </td>
            <td>수출자/생산자</td>
            <td>통일서식</td>
            <td>1년</td>
            <td>영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/uk">
                <div>영국</div>
              </Link>
            </td>
            <td>
              <COIssueType type="자율" themeColor="safe" inverted />
            </td>
            <td>6000유로 초과 인증수출자</td>
            <td>송품장</td>
            <td>1년</td>
            <td>한글, 영어</td>
          </tr>
          <tr>
            <td>
              <Link to="/consulting/fta/rcep">
                <div>RCEP</div>
              </Link>
            </td>
            <td>
              <div>
                <COIssueType type="기관" themeColor="caution" inverted />
                <COIssueType
                  type="자율"
                  themeColor="safe"
                  annotation="**"
                  inverted
                />
              </div>
            </td>
            <td>수출자/생산자/수출당사자의 발급기관</td>
            <td>당사국 합의양식</td>
            <td>1년</td>
            <td>영어</td>
          </tr>
        </tbody>
      </Table>
      * 호주 기관발급 - 호주만 가능
      <br />
      ** RCEP 자율발급 - 순차도입
      <br />! CO 당 1회 사용원칙(분할가능). 단, 한-미 FTA의 경우 1년의 범위
      안에서 포괄사용 가능
    </>
  );
};
export default FTAPrefTable;
const Table = styled.table`
  & td {
    padding: 0 1rem;
  }
  & > thead {
    & > tr {
      & > th {
        font-weight: normal;
        &:first-child {
          border-radius: 1rem 0 0 0;
        }
        &:last-child {
          border-radius: 0 1rem 0 0;
        }
      }
      & th:nth-child(3) {
        display: ${({ breakpoints }) =>
          breakpoints?.sm ? "table-cell" : "none"};
      }
      & th:nth-child(6),
      & th:nth-child(7) {
        display: ${({ breakpoints }) =>
          breakpoints?.md ? "table-cell" : "none"};
      }
      font-size: ${(props) =>
        props.breakpoints.xl
          ? "1.3em"
          : props.breakpoints.lg
          ? "1.15em"
          : "1em"};
      background-color: ${({ theme }) => theme.colors?.dark};
      line-height: 2em;
      color: white;
    }
  }
  & > tbody {
    & > tr {
      &:last-child {
        & > td:first-child {
          border-radius: 0 0 0 1rem;
        }
        & > td:last-child {
          border-radius: 0 0 1rem 0;
        }
      }
      &:nth-child(even) {
        background-color: ${({ theme }) => theme.colors?.monotone?.extralight};
      }
      & td:nth-child(3) {
        display: ${({ breakpoints }) =>
          breakpoints?.sm ? "table-cell" : "none"};
      }
      & td:nth-child(6),
      & td:nth-child(7) {
        display: ${({ breakpoints }) =>
          breakpoints?.md ? "table-cell" : "none"};
      }
      & > td {
        text-align: center;
        /* padding-top: 1rem;
        padding-bottom: 1rem; */
        & > a {
          text-decoration: none;
          color: inherit;
          & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
          }
        }
        & div {
          display: flex;
          justify-content: center;
        }
      }

      & > tr:first-child > td:last-child {
        /* padding: 1em; */
      }
    }
  }
`;
