import styled from "styled-components";
import { device } from "../../../style/styled-components/helpers/device";
import CompareTableRow from "./CompareTableRow";
const CompareTable = ({ contents }) => {
  return (
    <Div>
      {contents?.map((x, idx) => {
        return <CompareTableRow key={idx} contents={x} />;
      })}
    </Div>
  );
};
export default CompareTable;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  @media ${device.laptop} {
  gap: 2.5rem;
  }
  max-width: 90vw;
`;
