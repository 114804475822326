import styled from "styled-components";
const DropdownNav = ({ menuKey, children, title, setCurrentHoverKey }) => {
  return (
    <Dropdown onMouseEnter={(prevState) => setCurrentHoverKey(menuKey)} onMouseLeave={(prevState) => setCurrentHoverKey("")}>
      <ThemeDiv>{title}</ThemeDiv>
      <MenuDiv>{children}</MenuDiv>
    </Dropdown>
  );
};
export default DropdownNav;

const Dropdown = styled.div`
  display: flex;
  min-height: 20rem;
  z-index: 10;
  border-top: 1px ${({theme}) => theme.colors.light} solid;
  /* & > ul {
    padding: 0;
    display: flex;
    align-items: flex-start;
  } */
`;
const ThemeDiv = styled.div`
  z-index: 1;
  flex: 1;
  display: flex;
  padding-left: 16vw;
  padding-top: 8vh;
  font-size: 2em;
  background: linear-gradient(
    to right,
    #fff,
    ${({ theme }) => theme.colors.extralight}
  );
`;
const MenuDiv = styled.div`
  z-index: 1;
  flex: 5;
  display: flex;
  font-size: 1.1em;

  & > ul {
    display: flex;
    align-items: flex-start;
    padding-top: 2vh;
    padding-left: 1.5vw;
    position: relative;
    & > a > li {
      padding: 1vh 2.5vw;
      position: relative;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: ${({ theme }) => theme.colors.medium};
        transform: translate3d(-110%, 0, 0);
        transition: transform 300ms;
      }
    }
    & > a:hover > li {
    color: ${({theme}) => theme.colors.foreground.highlight};

      &:after {
        transform: translate3d(0, 0, 0);
      }
    }
  }
`;
