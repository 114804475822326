import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import DiamondScope from "../../../components/ui/specialty/DiamondScope";
import WorkScopes from "../../../components/ui/specialty/WorkScopes";
import FTAOriginDeterTable from "../../../components/contents/specialty/consulting/fta/FTAOriginDeterTable";
import CenterDiv from "../../../style/styled-components/components/CenterDiv";
import FTAPrefTable from "../../../components/contents/specialty/consulting/fta/FTAPrefTable";
// import FTAChartImage from "../../../resources/images/ftachart.png";
import UseBreakpoints from "../../../components/hooks/ui/UseBreakpoints";
import ContactPoints from "../../../components/ui/members/ContactPoints";
import FTACountriesImage from "../../../resources/images/fta_countries_rev.png";
import FTAImage from "../../../../src/resources/images/fta.jpg";
import img1 from "../../../resources/images/icon/7. FTA/1. FTA 컨설팅.png";
import img2 from "../../../resources/images/icon/7. FTA/2.1 인증수출자.png";
import img4 from "../../../resources/images/icon/7. FTA/3. 원산지증명서 발급대행.png";
import img5 from "../../../resources/images/icon/7. FTA/4. 원산지요건 확인.png";
import img6 from "../../../resources/images/icon/7. FTA/5. 형식적정성 확인.png";
import img7 from "../../../resources/images/icon/7. FTA/6. 원산지검증 조력서비스.png";
import img8 from "../../../resources/images/icon/7. FTA/7. FTA 사후관리.png";
import img9 from "../../../resources/images/icon/7. FTA/8. 각 협정별 법률 자문_rev.png";
import styled from "styled-components";
const FTAPage = () => {
  const breakpoints = UseBreakpoints();
  return (
    <PageTemplate
      large
      image={FTAImage}
      title="FTA"
      phrase="적법한 FTA 적용 및 사후검증 대리를 통하여 기업의 FTA 활용을 극대화 합니다."
      description="우리나라와 자유무역협정을 체결하는 국가가 늘어남에 따라 수출입시에 그
      혜택을 적법하게 적용 받는 것이 중요하게 되었습니다. 각 FTA협정별 원산지
      요건 충족여부, 관련 서류 형식적정성 등에 대한 진단 서비스를 제공하며,
      원산지 사후검증시 풍부한 검증 경험을 보유하고있는 FTA 전문가가 기업
      RISK를 최소화합니다."
    >
      <TitleTemplate
        title="FTA 체약국"
        noImage
        subHeader="협정별로 상이한 기준에 맞춰 정확한 FTA의 적용을 실현합니다."
      />
      <ImageDiv>
        <Image
          src={FTACountriesImage}
          alt="FTA 체약국 리스트"
          breakpoints={breakpoints}
        />
      </ImageDiv>
      <CenterDiv>
        <FTAPrefTable />
      </CenterDiv>
      {/* <TitleTemplate title="FTA 적용" noImage />
      <ImageDiv>
        <Image src={FTAChartImage} alt="FTA 적용 순서" breakpoints={breakpoints} />
      </ImageDiv> */}
      <TitleTemplate title="원산지 결정기준" noImage />
      <CenterDiv>
        <FTAOriginDeterTable />
      </CenterDiv>
      <TitleTemplate
        title="업무범위"
        noImage
        subHeader="복잡하고 어려운 FTA의 내용을 고객사 거래 상황에 맞추어 정확하게 수행합니다."
      />
      <WorkScopes
        contents={[
          { title: "FTA컨설팅", icon: img1 },
          { title: "인증수출자", icon: img2 },
          { title: "원산지증명서 발급대행", icon: img4 },
          { title: "원산지요건 확인", icon: img5 },
          { title: "형식적정성 확인", icon: img6 },
          { title: "원산지 검증 조력서비스", icon: img7 },
          { title: "FTA 사후관리", icon: img8 },
          { title: "각 협정별 법률자문", icon: img9 },
        ]}
      />
      <TitleTemplate
        title="Strength"
        noImage
        subHeader="A-One 만의 장점으로 고객을 힘껏 서포트 해 나가겠습니다."
      />
      <DiamondScope
        contents={[
          {
            title: "Cooperation",
            text: "유관기관과의 협력을 통한 원활한 FTA 이슈 해결",
          },
          {
            title: "Professional Expert",
            text: "전문지식을 보유한 FTA전문가들의 법률 자문 제공",
          },
          {
            title: "Experience",
            text: "풍부한 경험을 통한 전문 FTA 서비스 제공",
          },
          {
            title: "Consulting",
            text: "산업별, 체결국가별 발생 가능한 FTA Risk 진단",
          },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["jskim"]} />
    </PageTemplate>
  );
};
export default FTAPage;
const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: 3em;
`;
const Image = styled.img`
  width: ${({ breakpoints }) => (breakpoints?.md ? "70%" : "90vw")};
`;
