import styled from "styled-components";
import UseBreakpoints from "../../../hooks/ui/UseBreakpoints";
import PhraseWithImage from "./title-template/PhraseWithImage";
import Title from "./title-template/Title";
const TitleTemplate = ({ title, image, phrase, subHeader, noImage, large, aone }) => {
  const breakpoints = UseBreakpoints();
  return (
    <Div breakpoints={breakpoints}>
      <Title title={title} subHeader={subHeader} />
      {!noImage && (
        <PhraseWithImage image={image} phrase={phrase} large={large} aone={aone}/>
      )}
    </Div>
  );
};

export default TitleTemplate;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding: ${({breakpoints}) => breakpoints?.xl ? "8.5rem" : "5rem"} 0 5rem 0;
`;
