import styled from "styled-components";
const SelectOne = ({ options, vertical, selected, setSelected }) => {
  return (
    <Div vertical={vertical} selected={selected} count={options.length}>
      {options?.map((x, idx) => {
        return (
          <div key={idx} onClick={() => setSelected(idx)}>
            {x}
          </div>
        );
      })}
    </Div>
  );
};
export default SelectOne;
const Div = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
  border: 1px solid ${({ theme }) => theme.colors.monotone.medium};
  border-radius: 0.5rem;
  font-size: 0.8em;
  & > div {
    padding: 0.1rem 0.5rem;
    &:not(:first-child) {
      border-left: 1px solid ${({ theme }) => theme.colors.monotone.medium};
    }
    &:nth-child(${({ selected }) => selected + 1}) {
      background-color: ${({ theme }) => theme.colors.monotone.dark};
      color: ${({ theme }) => theme.colors.foreground.inverted};
      border-radius: ${({ selected, count }) =>
        selected === 0
          ? "0.5rem 0 0 0.5rem"
          : selected === count - 1
          ? "0 0.5rem 0.5rem 0"
          : "0"};
    }
  }
`;
