import styled from "styled-components";
import IntroBackground from "../../resources/images/background/intro1.jpg";
import UseBreakpoints from "../../components/hooks/ui/UseBreakpoints";
import PageTemplate from "../../components/ui/common/template/PageTemplate";
const Intro = () => {
  const breakpoints = UseBreakpoints();
  return (
    <PageTemplate
      title="인사말"
      image={IntroBackground}
      phrase={"최고의 전문가들과 함께합니다."}
      large
      aone
    >
      <Div>
        <StyledArticle breakpoints={breakpoints}>
          <SubText>안녕하십니까?</SubText>
          <FirstParagraph>
            관세법인 에이원은 수출입 통관, 관세환급, 요건∙검역, FTA, AEO컨설팅,
            심사 및 조사, 행정쟁송 등 기업의 관세무역 전반에 걸쳐 체계화되고
            질높은 서비스를 제공합니다.
            <br />
            에이원은 자체 개발한 통관시스템 TCMS(Total Compliance Management
            System)을 통해 신속하고 정확한 통관 서비스를 제공하고 있으며,
            Client의 품목마스터, 단가, FTA원산지 이력 정보 등을 시스템으로
            관리함으로써 통관에서 발생 가능한 수출입 오류, 관세 Risk
            Management를 통해 고객만족을 높이고 있습니다.
          </FirstParagraph>
          <SecondParagraph>
            에이원은 동종업계 최초로 AEO인증을 취득하였고 수년간의 AEO인증
            컨설팅 노하우를 바탕으로 국내 최고의 AEO컨설팅 기관으로 인정받고
            있습니다.
            <br />
            또한 기업의 FTA 활용 및 검증을 지원하기 위하여 FTA 활용 지원센터를
            설치하고 FTA 전문 컨설팅을 수행하고 있으며, 미국, 중국, EU 등 한국과
            FTA를 체결한 나라의 관세분야 전문가들과 네트워크를 구축하고 있어
            세계 관세행정 이슈에 즉시 대응이 가능합니다.
          </SecondParagraph>
          <ThirdParagraph>
            그 외 법인심사, 종합심사, 관세 및 외환 조사 등에 대해 세관에서
            경력을 쌓은 최고 전문가, 업계 최고의 전문관세사들로 구성된 전문
            컨설팅그룹이 축적된 노하우를 바탕으로 책임감 있는 심사 대리 서비스를
            제공합니다.
            <br />
            에이원은 고객 만족을 최우선 과제로 고객관리 전담 조직을 구성하여
            고객사별 CM(Client Management)관세사를 운영하고 있으며, 이를 통해
            통관 전·후에 발생할 수 있는 관세 리스크를 최소화함으로써 많은
            고객사들로부터 신뢰를 받고 있습니다.
          </ThirdParagraph>
          <FourthParagraph>
            인재 중심의 경영과 기본정신을 바탕으로 한 내실 위에 서비스 영역별
            전문성을 강화하여 고객과 함께 성장하는 진정한 파트너가 되기 위해
            모든 임직원이 최선을 다하겠습니다.
            <br />
            에이원은 분야별 전문인력과 고객 맞춤형 Solution을 바탕으로
            관세업무의 시작과 끝을 책임지는 “Customs Solution Partner” 가
            되겠습니다.
          </FourthParagraph>
          <LastText>관세법인 에이원 일동</LastText>
        </StyledArticle>
      </Div>
    </PageTemplate>
  );
};
export default Intro;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  align-items: center;
  justify-content: center;
`;
const StyledArticle = styled.article`
  & > div {
    padding: 0 15vw;
  }
  & p {
    text-align: left;
    font-size: 1.1em;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 300;
    line-height: 1.7em;
    text-indent: 1.5em;
    padding: 0
      ${({ breakpoints }) =>
        breakpoints.xl ? "15vw" : breakpoints.lg ? "10vw" : "2vw"};
  }
`;

const SubText = styled.div`
  font-size: 2em;
  font-weight: bold;
`;

const LastText = styled.div`
  margin-top: 1rem;
  font-size: 1.3em;
  font-weight: bolder;
  text-align: right;
`;

const FirstParagraph = styled.p``;
const SecondParagraph = styled.p``;
const ThirdParagraph = styled.p``;
const FourthParagraph = styled.p``;
