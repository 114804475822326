import styled from "styled-components";
import ArticleList from "../../components/contents/board/ArticleList";
import PageTemplate from "../../components/ui/common/template/PageTemplate";
import CenterDiv from "../../style/styled-components/components/CenterDiv";
import ContentDiv from "../../style/styled-components/components/ContentDiv";
import articlelist from '../../context/boards/customsnews/articlelist.json';
const Board2Page = () => {
  return (
    <PageTemplate title="관세무역뉴스" noImage>
      <CenterDiv>
        <ContentDiv>
          <Div>
            <ArticleList
              category="customsnews"
              contents={articlelist}
            />
          </Div>
        </ContentDiv>
      </CenterDiv>

    </PageTemplate>
  );
};
export default Board2Page;
const Div = styled.div``;
