export const chapter3 = {
  title: "비전2030과 일터혁신",
  range: "2019~현재",
  years: [
    {
      year: 2019,
      actions: [
        "근무혁신 우수기업 선정(고용노동부)",
        "중소기업 경영대상(통관 서비스부문)",
        "블록체인의 통관 분야 적용과 미래 세미나 개최",
      ],
    },
    {
      year: 2020,
      actions: [
        "유연 근무, 고용안정, 일터혁신, 인적자원 개발 추진",
        "일터혁신 CEO 클럽회원 위촉",
        "AEO 컨설팅 특별 공로상 수상(AEO 진흥협회)",
        "가족친화기업인증 취득(여성가족부)",
      ],
    },
    {
      year: 2021,
      actions: [
        "인적자원개발 우수기관 인증(산자부, 노동부 등 4개 부처)",
        "일생활균형캠페인참여(서울지방고용노동청)",
      ],
    },
    {
      year: 2022,
      actions: ["VISION 2030 수립 및 선포", "NCS리더스클럽 가입"],
    },
    {
      year: 2023,
      actions: [
        "근무혁신 우수기업 A등급 선정(고용노동부)",
        "가족친화기업인증 갱신",
        "AEO AA등급 재인증",
      ],
    },
    {
      year: 2024,
      actions: ["스마트 통관 시스템 통합 추진"],
    },
  ],
};
