import ACVAImage from "../../../../src/resources/images/acva.jpg";
import CenterDiv from "../../../style/styled-components/components/CenterDiv";
import CompareTable from "../../../components/ui/specialty/CompareTable";
import ContactPoints from "../../../components/ui/members/ContactPoints";
import DiamondScope from "../../../components/ui/specialty/DiamondScope";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import TitlePill from "../../../components/ui/common/template/TitlePill";
import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import TripleBox from "../../../components/ui/common/template/TripleBox";
import UseBreakpoints from "../../../components/hooks/ui/UseBreakpoints";
import WorkScopes from "../../../components/ui/specialty/WorkScopes";
import img1 from "../../../resources/images/icon/9. ACVA/1. ACVA 효과/1. 관세조사면제.png";
import img10 from "../../../resources/images/icon/9. ACVA/2. 업무범위/7. 연례보고서 검토.png";
import img11 from "../../../resources/images/icon/9. ACVA/2. 업무범위/8. ACVA 이행관련사후 관리.png";
import img2 from "../../../resources/images/icon/9. ACVA/1. ACVA 효과/2. 기업신뢰성 제고.png";
import img3 from "../../../resources/images/icon/9. ACVA/1. ACVA 효과/3. 잠정가격신고 및 가산세 면제.png";
import img4 from "../../../resources/images/icon/9. ACVA/2. 업무범위/1. 거래구조 검토.png";
import img5 from "../../../resources/images/icon/9. ACVA/2. 업무범위/2. TP보고서 검토.png";
import img6 from "../../../resources/images/icon/9. ACVA/2. 업무범위/3. 사전상담 진행.png";
import img7 from "../../../resources/images/icon/9. ACVA/2. 업무범위/4. ACVA 신청서 작성대리.png";
import img8 from "../../../resources/images/icon/9. ACVA/2. 업무범위/5. 세관방문 및 이슈대응.png";
import img9 from "../../../resources/images/icon/9. ACVA/2. 업무범위/6. 관세평가자문위원회 참석 및 의견진술.png";
import styled from "styled-components";

const ACVAPage = () => {
  const breakpoints = UseBreakpoints();
  return (
    <PageTemplate
      large
      image={ACVAImage}
      title="ACVA"
      phrase="ACVA 컨설팅은 특수관계자간 과세가격의 정당성 및 안정성을 제공합니다."
      description="ACVA(특수관계자간 과세가격 결정방법 사전심사)는 특수관계자 간에 거래되는
      수입물품의 과세가격을 납세의무자의 신청에 따라 세관 당국과 납세의무자의
      상호 합의를 통해 사전에 결정하는 제도입니다. 세관에서 경력을 쌓은
      최고 전문가, 업계 최고의 전문관세사들로 구성된 심사 전문 그룹이 전문성을
      바탕으로 특수관계자간 과세가격의 정당성 및 안정성을 제공합니다."
    >
      <TitleTemplate
        title="ACVA"
        noImage
        subHeader="ACVA를 통하여 특수관계자간의 과세가격 안정성을 보장합니다."
      />
      <WhatIsACVADiv breakpoints={breakpoints}>
        <div>
          <div>ACVA란?</div>
          <div>
            특수관계자간에 거래되는 수입물품의 과세가격 결정방법을 <br />
            납세자의 신청에 따라 과세당국과 납세자의 상호 합의를 통해 사전에
            결정해 주는 제도
          </div>
        </div>
        <div></div>
      </WhatIsACVADiv>
      <CenterDiv>
        <TitlePill title="ACVA 효과" />
      </CenterDiv>
      <TripleBox
        contents={[
          {
            title: "관세조사 유예",
            list: [
              "신청시점부터 승인시점까지 관세조사 유예",
              "승인시점으로부터 3년간 과세가격으로 인증(연장 2년이내 가능",
              "승인시점 이후라도 높은 과세가격 인정 가능성 보유",
            ],
            icon: img1,
          },
          {
            title: "기업 신뢰성 제고",
            list: [
              "물품의 수입가격에 대하여 소비자와 관세관청이 신뢰",
              "과세관청과 조세마찰 최소화",
            ],
            icon: img2,
          },
          {
            title: "잠정가격 신고 및 가산세 면제",
            list: [
              "ACVA신청부터 잠정가격 신고제도 이용이 가능하며, 향후 ACVA 승인 가격으로 수정신고 하는 경우 가산세 면제 혜택",
            ],
            icon: img3,
          },
        ]}
      />
      <TitleTemplate
        title="ACVA"
        noImage
        subHeader="ACVA를 통하여 특수관계자간의 과세가격 안정성을 보장합니다."
      />
      <CenterDiv>
        <ACVAProcessDiv breakpoints={breakpoints}>
          <div>ACVA 절차</div>
          <CompareTable
            contents={[
              { div: "사전상담", content2: "필요시 사전상담 가능" },
              {
                div: "사전심사 신청",
                content2:
                  "이전가격정책 또는 보고서 등 제출",
              },
              { div: "사전심사", content2: "심사기간 1년" },
              {
                div: "심사결과 통보",
                content2: "신청인 심사결과 동의여부 의사표시",
              },
              { div: "사전심사서 교부", content2: "유효기간 3년" },
              { div: "연례보고서 제출", content2: "연례보고서 제출" },
            ]}
          />
        </ACVAProcessDiv>
      </CenterDiv>

      <TitleTemplate
        title="업무범위"
        noImage
        subHeader="ACVA에 특화된 전문 관세사가 사전 진단부터 사후 관리까지 보장합니다."
      />
      <WorkScopes
        contents={[
          { title: "거래구조 검토", icon: img4 },
          { title: "TP보고서 검토", icon: img5 },
          { title: "사전상담 진행", icon: img6 },
          { title: "ACVA 신청서 작성 대리", icon: img7 },
          { title: "세관 방문 및 이슈 대응", icon: img8 },
          { title: "관세평가자문위원회 참석 및 의견진술", icon: img9 },
          { title: "연례보고서 검토", icon: img10 },
          { title: "ACVA 이행 관련 사후관리", icon: img11 },
        ]}
      />
      <TitleTemplate
        title="Strength"
        noImage
        subHeader="ACVA 사전진단부터 관리까지 처음부터 끝까지 함께하는 서비스를 제공합니다."
      />
      <DiamondScope
        contents={[
          {
            title: "Preview service",
            text: "과세가격 적정성 관련 사전 진단 서비스 제공",
          },
          {
            title: "MAN-POWER",
            text: "세관에서 경력을 쌓은 전문가, 업계 최고 관세사로 구성된 심사 전문 컨설팅 그룹",
          },
          {
            title: "Risk Management",
            text: "특수관계자간 이전가격의 정당성 및 안정성 제공",
          },
          {
            title: "Integrated management",
            text: "ACVA신청부터 수정신고, 향후 수입통관까지 통합관리서비스 제공",
          },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["ACVAMain"]} />
    </PageTemplate>
  );
};
export default ACVAPage;
const WhatIsACVADiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  & > div:first-child {
    display: flex;
    flex-direction: ${({ breakpoints }) => (breakpoints.md ? "row" : "column")};
    & > div:first-child {
      font-size: 2.5em;
      font-weight: bold;
      color: #125;
      min-width: 7em;
    }
    & > div:nth-child(2) {
      flex: 1;
      font-size: 1.5em;
      line-height: 2em;

      & > ol {
        padding: 0;
        margin: 0;
        & > li {
          font-size: 1em;
          font-weight: bold;
          line-height: 3em;
        }
        & > li:not(:first-child) {
        }
        & > ul > li {
        }
      }
    }
  }
`;
const ACVAProcessDiv = styled.div`
  display: flex;
  flex-direction: ${({ breakpoints }) => (breakpoints.md ? "row" : "column")};
  & > div:first-child {
    font-size: 2.5em;
    font-weight: bold;
    color: #125;
    min-width: 7em;
    padding: 1em 0;
  }
  & > *:last-child {
    flex: 1;
  }
`;
