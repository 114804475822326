import CenterDiv from "../../../style/styled-components/components/CenterDiv";
import ContactPoints from "../../../components/ui/members/ContactPoints";
import DiamondScope from "../../../components/ui/specialty/DiamondScope";
import ForexImage from "../../../resources/images/forex.jpg";
import ForexPunishmentTable from "../../../components/contents/specialty/legal/forex/ForexPunishmentTable";
import HeadedBoxCol from "../../../components/ui/common/template/HeadedBoxCol";
import PageTemplate from "../../../components/ui/common/template/PageTemplate";
import TitleTemplate from "../../../components/ui/common/template/TitleTemplate";
import TripleBox from "../../../components/ui/common/template/TripleBox";
import UseBreakpoints from "../../../components/hooks/ui/UseBreakpoints";
import WorkScopes from "../../../components/ui/specialty/WorkScopes";
import img2 from "../../../resources/images/icon/11. 외환조사/2. 외환거래 컨설팅의 필요성/1. 외환거래 조사강화.png";
import img3 from "../../../resources/images/icon/11. 외환조사/2. 외환거래 컨설팅의 필요성/2. 관련규정의 복잡함.png";
import img4 from "../../../resources/images/icon/11. 외환조사/2. 외환거래 컨설팅의 필요성/3. 담당자 규정 미인지.png";
import img5 from "../../../resources/images/icon/11. 외환조사/3. 업무범위/1. 외환 RISK 사전진단_rev.png";
import img6 from "../../../resources/images/icon/11. 외환조사/3. 업무범위/2. 외환심사 조사 대리.png";
import img7 from "../../../resources/images/icon/11. 외환조사/3. 업무범위/3. 외국환거래법 관련 법률자문_rev.png";
import img8 from "../../../resources/images/icon/11. 외환조사/3. 업무범위/4. 외국환거래법 교육진행_rev.png";
import styled from "styled-components";
const ForexPage = () => {
  const breakpoints = UseBreakpoints();
  // 퐁당퐁당 extralight
  return (
    <PageTemplate
      large
      image={ForexImage}
      title="외환조사 · 검사"
      phrase="다양한 조사, 검사 대리 경험을 보유한 외환전문가를 통하여 수준 높은 서비스를 제공합니다."
    >
      <p>
        외환 조사 및 검사는 관세청에 위임되어 있는 외국환거래의 관리권 행사를
        위하여 세관에서 기업의 외국환거래 분야의 적정성을 점검 및 조사하는
        제도입니다. 외환 규정 위반 시 관세법과 달리 수정신고 등과 같은 자진신고
        규정을 통하여 치유할 수 있는 근거 규정이 없어 과태료, 벌금등으로 바로
        이어질 수 있으므로 전문가의 컨설팅이 필요한 영역입니다.
      </p>
      <TitleTemplate title="수출입관련 외환신고 의무사항" noImage />
      <ForexDutiesDiv breakpoints={breakpoints}>
        <HeadedBoxCol
          div="지급등의 신고"
          subdiv="(외국환거래법 제16조)"
          text="거주자 간, 거주자와 비거주자 간 또는 비거주자 상호 간의 거래나 행위에 따른 채권·채무를 결제할 때 거주자가 다음 각 호의 어느 하나에 해당하면 그 지급 또는 수령의 방법을 기획재정부장관에게 미리 신고"
          numberedContents={[
            "상계 등의 방법으로 채권·채무를 소멸시키거나 상쇄시키는 방법으로 결제하는 경우",
            "기획재정부장관이 정하는 기간을 넘겨 결제하는 경우",
            "거주자가 해당 거래의 당사자가 아닌 자와 지급 또는 수령을 하거나 해당 거래의 당사자가 아닌 거주자가 그 거래 당사자인 비거주자와 지급 또는 수령을 하는 경우 (제3자지급)",
            "외국환업무취급기관을 통하지 아니하고 지급 또는 수령을 하는 경우",
          ]}
        />
        <HeadedBoxCol
          div="자본거래의 신고"
          subdiv="(외국환거래법 제18조)"
          text="자본거래를 하려는 자는 대통령령으로 정하는 바에 따라 기획재정부장관에게 신고"
          numberedContents={[
            "예금 및 신탁거래",
            "금전대차 및 채무의 보증거래",
            "대외지급수단, 채권, 기타의 매매 및 용역계약에 따른 자본거래",
            "증권의 발행, 취득",
            "파생상품거래",
            "기타 자본거래 (임대차계약, 담보/보증/보험/사용대차/채무의 인수 등에 따른 채권 거래)",
            "현지금융",
            "해외 직접투자 (증권의 취득 등, 영업소의 설치 및 확장, 운영 등)",
            "부동산 취득",
            "외국 기업 등의 국내지사 설치 및 변경, 폐쇄",
          ]}
        />
      </ForexDutiesDiv>
      <TitleTemplate title="외국환거래법령 관련 제재" noImage />
      <CenterDiv>
        <QuotedText>
          <div>"</div>
          <div>"</div>
          <div>
            위반금액에 따라 과태료나 징역 또는 벌금에 해당하는 벌칙 적용
          </div>
          <div>
            과태료 및 벌금 구분 기준: 지급 등의 방법 신고 기준 25억 / 자본거래
            신고 기준 10억
          </div>
        </QuotedText>
        <ForexPenalty>
          <ol>
            <li>
              1억원 이하 과태료
              <div>
                - 대상: 지급 또는 수령 방법 신고 위반, 자본거래 신고 위반
              </div>
              <CenterDiv>
                <ForexPunishmentTable />
              </CenterDiv>
            </li>
            <li>
              1년 이하의 징역 또는 1억원 이하의 벌금
              <div>
                다만, 위반행위의 목적물 가액의 3배가 1억원을 초과하는 경우에는
                그 벌금을 목적물 가액의 3배 이하로 함
              </div>
            </li>
            <li>
              AEO 인증 취소 가능성
              <div>
                - 수출입 안전관리 우수업체 공인 및 운영에 관한 고시 25조의2 1항
                1호 다목에 따라 AEO공인 취소될 수 있음
              </div>
            </li>
          </ol>
        </ForexPenalty>
      </CenterDiv>
      <TitleTemplate
        title="외환거래 컨설팅의 필요성"
        noImage
        subHeader="지급 등의 신고 및 자본거래 등 기업과 관련된 외환 RISK를 최소화 해드립니다."
      />
      <TripleBox
        contents={[
          {
            title: "외환거래 조사 강화",
            text: "관세청이 지급수단 등의 불법수출입사범과  수출입거래 및 이와 직접 관련되는 용역거래·자본거래 등과 관련한 위반사범을 조사하며 외환심사를 강화해나가고 있습니다.",
            icon: img2,
          },
          {
            title: "관련 규정의 복잡함",
            text: "외국환거래는 물품의 수출입 및 해외투자에 필수적으로 수반되나 관련 규정이 많고 복잡하며 업무와 관련된 법령을 모두 파악하는 것은 현실적으로 어려움이 있습니다.",
            icon: img3,
          },
          {
            title: "담당자 규정 미인지",
            text: "외환조사의 결과로 적발되는 기업은 대체적으로 관련법령에 관한 불인지로 인한 의도치 않은 위반을 범한 경우입니다. 컨설팅을 통하여 이러한 위반을 미연에 방지할 수 있습니다.",
            icon: img4,
          },
        ]}
      />
      <TitleTemplate
        title="업무범위"
        noImage
        subHeader="각 기업별로 발생할 수 있는 법률적 Risk를 진단하고, 관리방안을 제시합니다."
      />
      <WorkScopes
        contents={[
          { title: "외환 RISK 사전진단", icon: img5 },
          { title: "외환검사 대리", icon: img6 },
          { title: "외국환거래법 관련 법률자문", icon: img7 },
          { title: "외국환거래법 교육 진행", icon: img8 },
        ]}
      />
      <TitleTemplate
        title="Strength"
        noImage
        subHeader="고객의 관세업무 리스크 통제를 위하여 관세 분야별 전문가 그룹을 통한 컨설팅을 수행합니다."
      />
      <DiamondScope
        contents={[
          {
            title: "컨설팅 업무 수행 경험",
            text: "외환조사 및 심사 관련 다양한 컨설팅 업무 수행경험 보유",
          },
          {
            title: "MAN-POWER",
            text: "세관에서 경력을 쌓은 전문가, 업계 최고 관세사로 구성된 심사 전문 컨설팅 그룹",
          },
          {
            title: "외환 전문 관세사 법률자문",
            text: "외국환거래법 및 신고등에 대한 법률 및 실무자문 제공",
          },
          {
            title: "기업 전용 외환 교육 진행",
            text: "대면, 비대면(화상), 온라인 교육 등 다양한 방식으로 기업 교육 진행",
          },
        ]}
      />
      <TitleTemplate
        title="Contact Point"
        noImage
        subHeader="언제나 믿고 맡길 수 있는 최고의 멤버와 함께합니다."
      />
      <ContactPoints members={["forexMain"]} />
    </PageTemplate>
  );
};
export default ForexPage;
const ForexDutiesDiv = styled.div`
  display: flex;
  flex-direction: ${({ breakpoints }) => (breakpoints.lg ? "row" : "column")};
  gap: 1rem;
  & > div {
    flex: 1;
  }
`;
const QuotedText = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors?.foreground?.highlight};
  font-weight: bold;
  font-size: 1.4em;
  text-align: center;
  margin: 2em 0;
  & > div {
    &:first-child {
      position: absolute;
      font-size: 3em;
      left: -1em;
      top: -0.3em;
      color: ${({ theme }) => theme.colors?.monotone?.light};
    }
    &:nth-child(2) {
      position: absolute;
      font-size: 3em;
      right: -1em;
      top: -0.3em;
      color: ${({ theme }) => theme.colors?.monotone?.light};
    }
  }
`;
const ForexPenalty = styled.div`
  font-size: 1.1em;
  & > ol {
    list-style: none;
    padding: 0;
    counter-reset: item;
    & > li {
      counter-increment: item;
      margin-bottom: 5px;
      &:before {
        margin-right: 10px;
        padding: 0 0.1em;
        content: counter(item);
        background: ${({ theme }) => theme.colors?.dark};
        border-radius: 100%;
        color: white;
        width: 1.2em;
        text-align: center;
        display: inline-block;
      }
      & > div {
        margin: 1em 2em;
        color: ${({ theme }) => theme.colors?.foreground?.dimmed};
        font-size: 0.9em;
      }
    }
  }
`;
