// export const depthOf = (object) => {
//   var level = 1;
//   for (var key in object) {
//     console.log(key, !object.hasOwnProperty(key));
//     if (!object.hasOwnProperty(key)) continue;

//     if (typeof object[key] == "object") {
//       var depth = depthOf(object[key]) + 1;
//       level = Math.max(depth, level);
//     }
//   }
//   return level;
// };
export const depthOf = (object) => {
  if (Array.isArray(object)) {
    return Math.max(depthOf(...object));
  }
  if (object["children"]) {
    return depthOf(object["children"]) + 1;
  } else {
    return 1;
  }
};
export const getSiblings = (object, depth, breadcrumbs, level = 0) => {
  if (depth === 0) {
    return object.children;
  } else if (object["children"]) {
    const map = object.children.find(
      (x) => x.key === breadcrumbs[breadcrumbs.length - 1 - level].key
    );
    const result = getSiblings(map, depth - 1, breadcrumbs, level + 1);
    return Array.isArray(result) && result.length === 0 ? null : result;
  } else {
    return null;
  }
};
