import styled from "styled-components";
import { Link } from "react-scroll";
import MapMarker from "./MapMarker";
const PinOnMap = ({ title, x, y, direction = "left", setSelectedBranch }) => {
  const directions = {
    left: "row",
    right: "row-reverse",
    top: "column-reverse",
    bottom: "column",
  };
  return (
    <Link to="branchSection" offset={-100}>
      <Div
        x={x}
        y={y}
        direction={direction}
        directions={directions}
        onClick={() => {
          setSelectedBranch(title);
        }}
      >
        {/* <div>📍</div> */}
        <MapMarker />
        <div>{title}</div>
      </Div>
    </Link>
  );
};
export default PinOnMap;
const Div = styled.div`
  position: absolute;
  display: flex;
  cursor: pointer;
  flex-direction: ${({ direction, directions }) => directions[direction]};
  align-items: center;
  gap: 0.1rem;
  left: ${({ x, direction }) =>
    direction === "right" ? null : `calc(${x}% - .3em)`};
  right: ${({ x, direction }) =>
    direction === "right" ? `calc(${x}% - .3em)` : null};
  top: ${({ y, direction }) =>
    direction === "bottom" ? null : `calc(${y}% - .8em)`};
  bottom: ${({ y, direction }) =>
    direction === "bottom" ? `calc(${y}% + .2em)` : null};

  & > div {
    &:first-child {
      font-size: 1.5em;
    }
    &:nth-child(2) {
      font-size: 1.1em;
      font-weight: bold;
      padding: 0.5em;
      border-radius: 0.5em;
      color: ${({ theme }) => theme?.colors?.foreground?.highlight};
      background-color: #f6f6f6dd;
    }
  }
  &:before {
  }
`;
