export const getBreadcrumbs = (value, key, element) => {
  if (element[key] === value) {
    return element;
  } else if (element.children) {
    const map = element["children"]?.map((x) => {
      const { children, ...other } = x;
      return [getBreadcrumbs(value, key, x), other];
    });
    const find = map.find((x) => x[0]);
    return find
      ? Array.isArray(find[0])
        ? [...find[0], find[1]]
        : find[0].key === find[1].key
        ? find[0]
        : find
      : null;
  } else {
    return null;
  }
};
// export const getBreadcrumbs = (value, key, element) => {
//   if (element[key] === value) {
//     console.log("가챠");
//     return element;
//   } else if (element["children"]) {
//     return (
//       element["children"]
//         ?.map((x) => getBreadcrumbs(value, key, x))
//         .filter((x) => x) ?? null
//     );
//   } else {
//     console.log("눌");
//     return null;
//   }
// };
