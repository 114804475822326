import styled from 'styled-components';
import UseBreakpoints from '../../hooks/ui/UseBreakpoints';
import AoneOrganizationMobile from './AoneOrganizationMobile';
import AoneOrganizationPC from './AoneOrganizationPC';
import AoneOrganizationTablet from './AoneOrganizationTablet';
const AoneOrganization = () => {
  const breakpoints = UseBreakpoints();
  return (<Div>
    {breakpoints.lg ?
      <AoneOrganizationPC /> :
      breakpoints.md ?
        <AoneOrganizationTablet /> :
        <AoneOrganizationMobile />
    }
  </Div>)
}
export default AoneOrganization;
const Div = styled.div`
position: relative;
    --level-1: ${({ theme }) => theme.colors.background.medium};
  --level-2: ${({ theme }) => theme.colors.background.light};
  --level-3: ${({ theme }) => theme.colors.background.extralight};
  --level-4: #f27c8d;
  --line: #888;
  --height: 240px;
  --height-small: 30px;
  --line-width: 2px;
  --box-width: 6rem;
  --direct-line-width: 6rem;
  --direct-line-width-small: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > div {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  .org {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: .5rem 2rem;
    min-width: var(--box-width);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: .5rem;
    &:hover {
      font-weight: bolder;
    }
  }
  .org-1 {
    font-size: 1.5em;
    margin-bottom: var(--height);
background-color: var(--level-1);
color: ${({ theme }) => theme.colors.foreground.inverted};
&:after {
  content: "";
  position: absolute;
  display: block;
  transform: translateY(2.55rem);
  /* transform: translateY(calc(var(--height) - .5rem)); */
  width: var(--line-width);
  height: var(--height);
  background-color:var(--line);
}
  }
  .level-2:not(:first-child):not(:last-child):before  {
  // 가로선
content: "";
  position: absolute;
  display: block;
  width: calc(var(--box-width) + 4rem + 4.4rem);
  height: var(--line-width);
  background-color:var(--line);
  }
  .level-2:first-child:before  {
  // 가로선
content: "";
  position: absolute;
  display: block;
  width: calc((var(--box-width) / 2 + 4rem + 2.3rem));
  transform: translateX(calc(var(--box-width) / 2 + 2rem));
  height: var(--line-width);
  background-color:var(--line);
  }
  .level-2:last-child:before  {
  // 가로선
content: "";
  position: absolute;
  display: block;
  width: calc((var(--box-width) + 4rem)* 1/2);
  height: var(--line-width);
  background-color:var(--line);
  }
  .org-2 {
color: ${({ theme }) => theme.colors.foreground.inverted};
background-color: var(--level-2);
margin-top: var(--height-small);
&:before {
  content: "";
  position: absolute;
  display: block;
  transform: translateY(calc(-100% - .5em));
  width: var(--line-width);
  height: var(--height-small);
  background-color:var(--line);
}
&:not(:first-child) {
margin-top: var(--height-small);
&:before{
  height: var(--height-small);
}
}
  }
  .org-3 {
background-color: var(--level-3);
  }
  .org-4 {
background-color: var(--level-4);
  }
  .direct-container {
    position: relative;
  }
  .direct {
    position: absolute;
    transform: translateY(calc(-5 / 6 * var(--height)));
  }
  .direct-l-container {
    position: relative;
  }
  .direct-l {
    position: absolute;
    transform: translateX(calc(-1 * var(--direct-line-width) - 100%));
    display: flex;
    flex-direction: column;
    gap: 1rem;
    & > div {
      position: relative;
      &:first-child > div:before {
        content: "";
        position: absolute;
        display: block;
        right: calc(-1 * var(--direct-line-width-small));
        top: 50%;
        transform: translate(-.1rem, 0%);
        /* transform: translateY(calc(var(--height) - .5rem)); */
        height: 40%;
        width: var(--line-width);
        background-color:var(--line);
      }
      &:last-child > div:before {
        content: "";
        position: absolute;
        display: block;
        right: calc(-1 * var(--direct-line-width-small));
        top: -50%;
        transform: translate(-.1rem, 0%);
        /* transform: translateY(calc(var(--height) - .5rem)); */
        height: 97%;
        width: var(--line-width);
        background-color:var(--line);
      }
      &:not(:first-child):not(:last-child) > div:before {
        content: "";
        position: absolute;
        display: block;
        right: calc(-1 * var(--direct-line-width-small));
        top: 0;
        transform: translate(-.1rem, 0%);
        /* transform: translateY(calc(var(--height) - .5rem)); */
        height: 150%;
        width: var(--line-width);
        background-color:var(--line);
      }
    }
    
    &:after {
      content: "";
      position: absolute;
      display: block;
      left: 100%;
      top: 50%;
      transform: translateX(calc(1.75 / 6 * var(--box-width)));
      height: var(--line-width);
      width: calc(var(--direct-line-width) * .7);
      background-color:var(--line);
    }
  }
  .direct-r-container {
    position: relative;
  }
  .direct-r {
    position: absolute;
    transform: translateX(var(--direct-line-width));
    display: flex;
    flex-direction: column;
    gap: 1rem;
    & > div {
      position: relative;
    &:first-child > div:before {
    content: "";
      position: absolute;
      display: block;
      left: calc(-1 * var(--direct-line-width-small));
      top: 50%;
      transform: translate(-.1rem, 0%);
      /* transform: translateY(calc(var(--height) - .5rem)); */
      height: 92%;
      width: var(--line-width);
      background-color:var(--line);
      }
    &:last-child > div:before {
    content: "";
      position: absolute;
      display: block;
      left: calc(-1 * var(--direct-line-width-small));
      top: 0%;
      transform: translate(-.1rem, 0%);
      /* transform: translateY(calc(var(--height) - .5rem)); */
      height: 32%;
      width: var(--line-width);
      background-color:var(--line);
      }
    &:not(:first-child):not(:last-child) > div:before {
    content: "";
      position: absolute;
      display: block;
      left: calc(-1 * var(--direct-line-width-small));
      top: 0;
      transform: translate(-.1rem, 0%);
      /* transform: translateY(calc(var(--height) - .5rem)); */
      height: 150%;
      width: var(--line-width);
      background-color:var(--line);
      }
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      right: 100%;
      top: 50%;
      transform: translateX(-2rem);
      height: var(--line-width);
      width: calc(var(--direct-line-width) * .65);
      background-color:var(--line);
    }
  }
  .direct-org-2 {
    color: ${({ theme }) => theme.colors.foreground.inverted};
    background-color: var(--level-2);
  }
  .org-l:after {
    content: "";
      position: absolute;
      display: block;
      transform: translate(6.6rem, 0.55em);
      /* transform: translateY(calc(var(--height) - .5rem)); */
      width: var(--direct-line-width-small);
      height: var(--line-width);
      background-color:var(--line);
  }
    .org-r:after {
      content: "";
        position: absolute;
        display: block;
        transform: translate(-6rem, 50%);
        top: 1.1rem;
        /* transform: translateY(calc(var(--height) - .5rem)); */
        width: var(--direct-line-width-small);
        height: var(--line-width);
        background-color:var(--line);
    }
      
`;